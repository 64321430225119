<template>
    <div>
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 관련대회 -->
            <v-sheet
                height="40"
                color="#F0F3F5"
                class="pt-1 d-flex rounded-t-15"
            >
                <!-- # 정렬 -->
                <!-- 날짜 -->
                <div
                    :class="sequence == '날짜'? 'primary--text':''"
                    class="text-center mt-1 pl-2"
                    style="width:100px; font-size:14px; position:relative; cursor:pointer;"
                    @click="sequence == '날짜'? (sequence = '날짜', align_date = !align_date) : sequence = '날짜'"
                >
                    날짜
                    <v-icon
                        size="20"
                        class="mb-2px"
                        :color="sequence == '날짜'? 'primary':''"
                    >
                        {{align_date? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '날짜'"
                        class="primary mx-auto"
                        style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>

                <!-- 국가 -->
                <div
                    style="width:100px; font-size:14px;"
                    class="text-center mt-1"
                >
                    국가
                </div>

                <!-- 대회 이름 -->
                <div
                    :class="sequence == '대회 이름'? 'primary--text':''"
                    class="text-center mt-1 pl-2"
                    style="width:500px; font-size:14px; position:relative; cursor:pointer;"
                    @click="sequence == '대회 이름'? (sequence = '대회 이름', align_title = !align_title) : sequence = '대회 이름'"
                >
                    대회 이름
                    <v-icon
                        size="20"
                        class="mb-2px"
                        :color="sequence == '대회 이름'? 'primary':''"
                    >
                        {{align_title? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '대회 이름'"
                        class="primary mx-auto"
                        style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>

                <!-- 참가자 -->
                <div
                    :class="sequence == '참가자'? 'primary--text':''"
                    class="text-center mt-1 pl-2"
                    style="width:160px; font-size:14px; position:relative; cursor:pointer;"
                    @click="sequence == '참가자'? (sequence = '참가자', align_total = !align_total) : sequence = '참가자'"
                >
                    참가자
                    <v-icon
                        size="20"
                        class="mb-2px"
                        :color="sequence == '참가자'? 'primary':''"
                    >
                        {{align_total? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '참가자'"
                        class="primary mx-auto"
                        style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>

                <!-- 우승자 -->
                <div
                    :class="sequence == '우승자'? 'primary--text':''"
                    class="text-center mt-1 pl-2"
                    style="width:240px; font-size:14px; position:relative; cursor:pointer;"
                    @click="sequence == '우승자'? (sequence = '우승자', align_winner = !align_winner) : sequence = '우승자'"
                >
                    우승자
                    <v-icon
                        size="20"
                        class="mb-2px"
                        :color="sequence == '우승자'? 'primary':''"
                    >
                        {{align_winner? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '우승자'"
                        class="primary mx-auto"
                        style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>
            </v-sheet>

            <v-sheet
                class="pt-1 rounded-15 pb-6"
                :key="componentKey+'sameTypeList'"
            >
                <div
                    v-if="list.length && !loading"
                >
                    <v-sheet
                        v-for="item in list" :key="item.id"
                        height="56"
                        class="d-flex align-center ma-0 pa-0"
                        style="position:relative; cursor:pointer;"
                        @click="$router.push('/official_league/read?id='+item.id)"
                    >
                        <!-- 날짜 -->
                        <div
                            style="width:100px; font-size:14px; height:56px; border-bottom:1px solid #eee;"
                            class="text-center d-flex align-center justify-center"
                        >
                            {{dayjs(item.start_date).format('YY-MM-DD')}}
                        </div>

                        <!-- 국가 -->
                        <div
                            style="width:100px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                            class="text-center d-flex align-center justify-center font-weight-medium primary--text"
                        >
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-sheet
                                        height="52"
                                        width="33.333%"
                                        class="rounded-bl-15"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-img
                                            v-if="item.league_nation"
                                            width="36"
                                            height="26"
                                            class="rounded-5 mx-auto mt-3"
                                            style="box-shadow:0px 2px 4px #ddd;"
                                            :src="'/upload/flags/'+item.league_nation.toLowerCase()+'.svg'"
                                        ></v-img>
                                    </v-sheet>
                                </template>
                                <div class="text-center pa-1">
                                    {{$country_name(item.league_nation, country_list)}}
                                </div>
                            </v-tooltip>
                        </div>

                        <!-- 대회 이름 -->
                        <div
                            style="width:500px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                            class="text-start d-flex align-center font-weight-medium flex-grow-1"
                        >
                            <div
                                class="d-flex align-center"
                            >
                                <div class="d-flex mr-1">
                                    <div>
                                        <!-- 이미지 -->
                                        <v-img
                                            contain
                                            height="40"
                                            max-width="60"
                                            class="mx-auto mb-1"
                                            :src="'/upload/official_league/'+item.image"
                                        ></v-img>
                                    </div>
                                </div>
                                <div>
                                    <p
                                        style="font-size:14px; font-weight:600; margin-bottom:-2px;"
                                    >
                                        {{item.title_kr}}
                                        <span
                                            v-if="item.age_group"
                                            :style="$age_group_color(item.age_group)"
                                            class="ml-1 font-weight-bold text-caption"
                                            style="vertical-align:10%;"
                                        >
                                            {{item.age_group}}
                                        </span>
                                    </p>
                                    <p
                                        style="font-size:12px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                                    >
                                        {{item.title_en}}
                                    </p>
                                </div>
                            </div>

                            <v-spacer></v-spacer>
                            <!-- 레귤레이션 -->
                            <div
                                style="width:72px;"
                            >
                                <v-img
                                    v-if="item.regulation"
                                    class="mx-auto"
                                    height="54"
                                    width="54"
                                    contain
                                    :src="require('@/assets/official_league/'+item.regulation+'.png')"
                                ></v-img>
                            </div>
                        </div>

                        <!-- 참가자 -->
                        <div
                            style="width:160px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                            class="text-center d-flex align-center justify-center font-weight-medium"
                        >
                            {{item.total}}명
                        </div>

                        <!-- 우승 -->
                        <div
                            style="width:240px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                            class="d-flex align-center font-weight-medium pl-2"
                        >
                            <div>
                                <div
                                    class="d-flex align-center"
                                >
                                    <div
                                        v-if="item.winner"
                                    >
                                        <v-img
                                            v-if="item.winner_country"
                                            width="18"
                                            height="13"
                                            class="rounded-4 mr-1"
                                            style="box-shadow:0px 1px 2px #ddd;"
                                            :src="'/upload/flags/'+item.winner_country.toLowerCase()+'.svg'"
                                        ></v-img>
                                    </div>
                                    {{computed_player_kr(item)}}
                                </div>
                                <p
                                    v-if="!item.winner_local"
                                    class="mb-0 grey--text text-caption text-start"
                                >
                                    {{computed_player_en(item)}}
                                </p>
                            </div>
                        </div>
                    </v-sheet>
                </div>
                <div
                    v-if="!list.length && !loading"
                    class="text-center py-16"
                >
                    <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                    <p style="color:#ccc">관련대회가 없습니다</p>
                </div>
            </v-sheet>

            <!-- 로딩 -->
            <v-sheet
                v-if="loading"
                class="pt-8 pb-16 rounded-15 d-flex justify-center"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="120"
                ></v-progress-circular>
            </v-sheet>
        </div>

        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 목록 상단 -->
            <v-sheet
                height="40"
                color="#F0F3F5"
                class="pt-1 d-flex rounded-t-15"
            >
                <!-- 날짜 -->
                <div
                    :class="sequence == '날짜'? 'primary--text':''"
                    class="text-center mt-1 pl-1"
                    style="width:15%; font-size:12px; position:relative; cursor:pointer;"
                    @click="sequence == '날짜'? (sequence = '날짜', align_date = !align_date) : sequence = '날짜'"
                >
                    날짜
                    <v-icon
                        size="20"
                        class="mb-2px"
                        :color="sequence == '날짜'? 'primary':''"
                    >
                        {{align_date? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '날짜'"
                        class="primary mx-auto"
                        style="height:2px; width:50px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>

                <!-- 국가 -->
                <div
                    style="width:15%; font-size:12px;"
                    class="text-center mt-1"
                >
                    국가
                </div>

                <!-- 대회 이름 -->
                <div
                    :class="sequence == '대회 이름'? 'primary--text':''"
                    class="text-center mt-1 pl-1"
                    style="width:70%; font-size:12px; position:relative; cursor:pointer;"
                    @click="sequence == '대회 이름'? (sequence = '대회 이름', align_title = !align_title) : sequence = '대회 이름'"
                >
                    대회 이름
                    <v-icon
                        size="20"
                        class="mb-2px"
                        :color="sequence == '대회 이름'? 'primary':''"
                    >
                        {{align_title? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '대회 이름'"
                        class="primary mx-auto"
                        style="height:2px; width:200px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>
            </v-sheet>

            <v-sheet
                class="pt-1 rounded-15 pb-6"
                :key="componentKey+'sameTypeList'"
            >
                <!-- 목록 값 -->
                <div>
                    <v-sheet
                        v-for="item in list" :key="item.id"
                        height="48"
                        class="d-flex align-center ma-0 pa-0"
                        style="position:relative; cursor:pointer;"
                        @click="$router.push('/official_league/read?id='+item.id)"
                    >
                        <!-- 날짜 -->
                        <div
                            style="width:15%; font-size:10px; height:48px; border-bottom:1px solid #eee;"
                            class="text-center d-flex align-center justify-center"
                        >
                            {{dayjs(item.start_date).format('YY-MM-DD')}}
                        </div>

                        <!-- 국가 -->
                        <div
                            style="width:15%; font-size:12px; height:48px; border-bottom:1px solid #eee; cursor:pointer;"
                            class="text-center d-flex align-center justify-center font-weight-medium primary--text"
                        >
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-sheet
                                        height="24"
                                        width="32"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-img
                                            v-if="item.league_nation"
                                            height="24"
                                            width="32"
                                            class="rounded-5 mx-auto"
                                            style="box-shadow:0px 2px 4px #ddd;"
                                            :src="'/upload/flags/'+item.league_nation.toLowerCase()+'.svg'"
                                        ></v-img>
                                    </v-sheet>
                                </template>
                                <div class="text-center pa-1">
                                    {{$country_name(item.league_nation, country_list)}}
                                </div>
                            </v-tooltip>
                        </div>

                        <!-- 대회 이름 -->
                        <div
                            style="width:70%; font-size:12px; height:48px; border-bottom:1px solid #eee; cursor:pointer;"
                            class="text-start d-flex align-center font-weight-medium flex-grow-1"
                        >
                            <div
                                class="d-flex align-center"
                            >
                                <div class="d-flex mr-1">
                                    <div>
                                        <!-- 이미지 -->
                                        <v-img
                                            contain
                                            height="20"
                                            max-width="30"
                                            class="mx-auto mb-1"
                                            :src="'/upload/official_league/'+item.image"
                                        ></v-img>
                                    </div>
                                </div>
                                <div>
                                    <p
                                        class="text-truncate"
                                        style="font-size:9px; font-weight:600; width:182px; margin-bottom:-1px;"
                                    >
                                        <span
                                            v-if="item.age_group"
                                            :style="$age_group_color(item.age_group)"
                                            class="mr-2px font-weight-bold"
                                            style="font-size:9px;"
                                        >
                                            {{item.age_group}}
                                        </span>
                                        {{item.title_kr}}
                                    </p>
                                    <p
                                        style="font-size:8px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                                    >
                                        {{item.title_en}}
                                    </p>
                                </div>
                            </div>

                            <v-spacer></v-spacer>
                            <!-- 레귤레이션 -->
                            <div
                                style="width:60px;"
                            >
                                <v-img
                                    v-if="item.regulation"
                                    class="mx-auto"
                                    height="40"
                                    width="40"
                                    contain
                                    :src="require('@/assets/official_league/'+item.regulation+'.png')"
                                ></v-img>
                            </div>
                        </div>
                    </v-sheet>
                </div>
                <div
                    v-if="!list.length && !loading"
                    class="text-center py-16"
                >
                    <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                    <p style="color:#ccc">관련대회가 없습니다</p>
                </div>
            </v-sheet>

            <!-- 로딩 -->
            <v-sheet
                v-if="loading"
                class="pt-8 pb-16 rounded-15 d-flex justify-center"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="120"
                ></v-progress-circular>
            </v-sheet>
        </div>
    </div>
</template>
<script>
// ! 불필요한 data, method 정리 필요
export default {
    data: () => ({
        title_kr: "",
        title_en: "",
        title_local: "",
        regulation: "",
        league_type: "",
        image: "",
        organizer: "",
        reference_link: "",
        start_date: "",
        end_date: "",
        total: 0,
        total2: 0,
        content: "",
        menu: "결과",
        sub_menu: "플레이어",
        list: [],
        season_nation: "",
        season_name: "",
        age_group: "",
        league_nation: "",

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        dialog: {
            player: false,
            provide: false,
            cardshop: false
        },

        selected_item: {},
        componentKey: 0,

        standing_list: [],
        country_list: [],
        loading: false,

        componentKey: 0,
        sequence: "날짜",
        align_date: false,
        align_title: false,
        align_total: false,
        align_winner: false,
    }),

    watch: {
        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_date(){
            this.sortList()
        },

        // 순서
        align_title(){
            this.sortList()
        },

        // 순서
        align_total(){
            this.sortList()
        },

        // 순서
        align_winner(){
            this.sortList()
        }
    },

    async mounted(){
        await this.fetchData()
    },

    methods: {
        computed_player_kr(item) {
            if(!item.winner) return "-"

            if(item.winner_local){
                return item.winner_local
            }
            else{
                if(item.winner_country != "KR"){
                    return item.winner
                }else{
                    if(item.is_masking != null){
                        if(item.is_masking){
                            return item.winner[0] + '✱' + item.winner.slice(2)
                        }else{
                            return item.winner
                        }
                    }
                    else{
                        if(item.winner_age_group != '시니어' && item.winner_age_group != '주니어'){
                            return item.winner
                        }else{
                            return item.winner[0] + '✱' + item.winner.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(item.winner_country != "KR"){
                return item.winner_en
            }else{
                if(item.is_masking != null){
                    if(item.is_masking){
                        return item.winner_en.split(' ')[0] + ' *'
                    }else{
                        return item.winner_en
                    }
                }
                else{
                    if(item.winner_age_group != '시니어' && item.winner_age_group != '주니어'){
                        return item.winner_en
                    }else{
                        return item.winner_en.split(' ')[0] + ' *'
                    }
                }
            }
        },

        async fetchData() {
            // 국가 목록 불러오기
            this.country_list = await this.$load_country_list()

            // 공식대회 정보 불러오기
            await this.load()

            // 검색
            this.search()
        },

        // 순서
        sortList(){
            if(this.sequence == "날짜")
            {
                this.list = this.list.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
                if(this.align_date){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "대회 이름")
            {
                this.list = this.list.sort((a, b) => a.title_kr.localeCompare(b.title_kr))
                if(this.align_title){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "참가자")
            {
                this.list = this.list.sort((a, b) => b.total - a.total)
                if(this.align_total){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "우승자")
            {
                // this.list = this.list.sort((a, b) => a.winner.localeCompare(b.winner))
                this.list = this.list.sort((a, b) => {
                    const winnerA = a.winner || a.winner_local || "-";
                    const winnerB = b.winner || b.winner_local || "-";
                    if (winnerA === "-" || winnerA === "" || winnerA === null) return 1;
                    if (winnerB === "-" || winnerB === "" || winnerB === null) return -1;
                    return winnerA.localeCompare(winnerB);
                });

                if(this.align_winner){
                    this.list = this.list.reverse()
                }
            }

            console.log(this.list)
            this.componentKey++
        },

        // 검색
        search(){
            this.loading = true
            this.$http.post("/api/official_league/select/sameType", {
                params: {
                    organizer: this.organizer,
                    league_type: this.league_type
                }
            }).then((res) => {
                // console.log(res)
                this.list = res.data.filter(e => e.id != this.$route.query.id)

                // 최신순 정렬
                this.list = this.list.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))

                this.loading = false
            })
        },

        // 공식대회 정보 불러오기
        async load(){
            await this.$http.post("/api/official_league/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then(async(res) => {
                this.title_kr = res.data[0].title_kr
                this.title_en = res.data[0].title_en
                this.title_local = res.data[0].title_local
                this.regulation = res.data[0].regulation
                this.league_type = res.data[0].league_type
                this.image = res.data[0].image
                this.organizer = res.data[0].organizer
                this.reference_link = res.data[0].reference_link
                this.start_date = res.data[0].start_date
                this.end_date = res.data[0].end_date
                this.age_group = res.data[0].age_group
                this.options.content = res.data[0].content
                this.total = res.data[0].total
                this.total2 = res.data[0].total2
                this.league_nation = res.data[0].league_nation

                this.season_nation = res.data[0].season_nation
                this.season_name = res.data[0].season_name
            })
        },
    }
}
</script>