<template>
    <div>
        <!-- # 제목 & 로딩 & 메뉴 -->
        <div
            class="d-flex align-center pl-5 pt-3 pb-3"
        >
            <!-- 제목 -->
            <div>
                <p
                    style="font-size:26px; font-weight:bold; margin-bottom:4px; cursor:pointer;"
                    @click="$router.push('/official_league/deck_rank/list')"
                >
                    덱 순위
                    <span style="margin-left:4px; color:#F8468D; font-size:12px; vertical-align:super; font-weight:500;">BETA</span>
                </p>
                <p style="font-size:11px;" class="mb-0">메타별로 분류된 덱 순위 정보를 제공합니다</p>
            </div>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                class="ml-2"
                indeterminate
                color="primary"
                size="18"
                width="2"
            ></v-progress-circular>

            <v-spacer></v-spacer>

            <!-- 메뉴 -->
            <v-sheet class="mt-1 pt-1 px-9 rounded-15 d-flex justify-space-between">
                <v-btn
                    height="56"
                    class="px-0 mr-8"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="$route.path.startsWith('/league/list')? 'primary':''"
                    :style="$route.path.startsWith('/league/list')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                    :dark="$route.path.startsWith('/league/list')"
                >
                    대회
                </v-btn>
                <v-btn
                    height="56"
                    class="px-0"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="$route.path.startsWith('/official_league/deck_rank')? 'primary':''"
                    :style="$route.path.startsWith('/official_league/deck_rank')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                    :dark="$route.path.startsWith('/official_league/deck_rank')"
                >
                    공식대회
                </v-btn>
            </v-sheet>
        </div>

        <!-- # 검색 조건 -->
        <div
            class="d-flex px-4 py-3 rounded-10 white"
        >
            <!-- 기간 -->
            <v-select
                style="width:108px;"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="기간"
                placeholder="기간"
                persistent-placeholder
                :items="select_list.date"
                v-model="keyword.date"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="text-truncate mt-1" style="display:inline-block;">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 메인포켓몬 -->
            <v-autocomplete
                style="width:108px;"
                class="rounded-10 font-weight-bold mx-1"
                dense
                outlined
                hide-details
                label="메인포켓몬"
                placeholder="메인포켓몬"
                persistent-placeholder
                item-text="kr"
                item-value="name"
                :items="select_list.main_pokemon"
                v-model="keyword.main_pokemon"
                @change="search()"
                multiple
                chips
                deletable-chips
                :key="main_pokemon_key"
            >
                <template v-slot:selection="{ item, index }">
                    <v-chip
                        class="mt-1"
                        color="#eeeeee"
                        close
                        @click:close="removeMainPokemon(index)"
                    >
                        <span style="width:88px;" class="text-truncate">{{ item.kr }}</span>
                    </v-chip>
                </template>
                <template v-slot:append>
                    <v-icon small class="mr-1 mt-1" color="#424361" style="margin-bottom:-22px;">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>

            <!-- translateToKr(item.main_pokemon) -->

            <!-- # 제품명 시즌 -->
            <v-autocomplete
                style="width:148px;"
                class="rounded-10 font-weight-bold mx-1"
                dense
                outlined
                hide-details
                label="제품명 시즌"
                placeholder="제품명 시즌"
                persistent-placeholder
                item-text="text"
                item-value="value"
                :items="select_list.season_list"
                v-model="keyword.season"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="text-truncate mt-1" style="display:inline-block;">{{item.text}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="mr-1 mt-1" color="#424361" style="margin-bottom:-22px;">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>

            <!-- 레귤레이션 -->
            <v-select
                style="width:148px;"
                class="rounded-10 font-weight-bold mx-1"
                dense
                outlined
                hide-details
                label="레귤레이션"
                placeholder="레귤레이션"
                persistent-placeholder
                item-text="text"
                item-value="value"
                :items="select_list.regulation"
                v-model="keyword.regulation"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="text-truncate mt-1" style="display:inline-block;">{{item.text}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="mr-1 mt-1" color="#424361" style="margin-bottom:-22px;">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 지역 -->
            <v-select
                style="width:80px;"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="지역"
                placeholder="지역"
                persistent-placeholder
                :items="select_list.continent"
                v-model="keyword.continent"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- # 메인포켓몬 묶어보기 & 언어 변경 -->
        <div
            class="d-flex justify-end align-center"
        >
            <!-- 메인포켓몬 묶어보기 -->
            <v-checkbox
                label="메인포켓몬 묶어보기"
                v-model="keyword.is_group"
                @change="search()"
            >
                <template v-slot:label>
                    <span style="font-size: 14px; margin-left:-4px; letter-spacing:-0.4px;">메인포켓몬 묶어보기</span>
                </template>
            </v-checkbox>

            <!-- 언어 변경 -->
            <v-select
                :key="componentKey"
                class="ml-6 pb-2px rounded-15 shrink"
                style="width:152px; background:white;"
                dense
                outlined
                hide-details
                item-value="value"
                item-text="name"
                :items="select_list.deck_name_nation"
                v-model="keyword.deck_name_nation"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-3 text-body-2">{{item.name}}</span>
                </template>
                <template v-slot:append>
                    <v-icon class="mr-1 mt-2px" size="20" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- # 목록 상단 -->
        <v-sheet
            height="40"
            color="#F0F3F5"
            class="pt-10px d-flex rounded-t-15"
        >
            <div
                style="width:10%; font-size:14px;"
                class="text-center"
            >
                순위
            </div>
            <div
                style="width:40%; font-size:14px;"
                class="text-center"
            >
                덱 이름
            </div>
            <!-- <div
                style="width:90px; font-size:14px;"
                class="text-center"
            >
                티어
            </div> -->

            <!-- 티어 -->
            <div
                :class="sequence == '티어'? 'primary--text':''"
                class="text-center"
                style="width:90px; font-size:14px; position:relative; cursor:pointer;"
                @click="sequence = '티어'"
            >
                티어

                <div
                    v-if="sequence == '티어'"
                    class="primary mx-auto"
                    style="height:2px; width:70px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                ></div>
            </div>

            <!-- 점유율 -->
            <div
                :class="sequence == '점유율'? 'primary--text':''"
                class="text-center pl-2"
                style="width:22%; font-size:14px; position:relative; cursor:pointer;"
                @click="sequence == '점유율'? (sequence = '점유율', align_usage = !align_usage) : sequence = '점유율'"
            >
                점유율
                <v-icon
                    size="20"
                    class="mb-2px"
                    :color="sequence == '점유율'? 'primary':''"
                >
                    {{align_usage? 'mdi-chevron-up':'mdi-chevron-down'}}
                </v-icon>
                <div
                    v-if="sequence == '점유율'"
                    class="primary mx-auto"
                    style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                ></div>
            </div>

            <!-- 승점 -->
            <div
                :class="sequence == '승점'? 'primary--text':''"
                class="text-center pl-2"
                style="width:21%; font-size:14px; position:relative; cursor:pointer;"
                @click="sequence == '승점'? (sequence = '승점', align_point = !align_point) : sequence = '승점'"
            >
                승점
                <v-icon
                    size="20"
                    class="mb-2px"
                    :color="sequence == '승점'? 'primary':''"
                >
                    {{align_point? 'mdi-chevron-up':'mdi-chevron-down'}}
                </v-icon>
                <div
                    v-if="sequence == '승점'"
                    class="primary mx-auto"
                    style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                ></div>
            </div>
        </v-sheet>

        <!-- # 목록 -->
        <v-sheet
            v-for="(item, index) in currentPageList" :key="item.id"
            height="56"
            class="d-flex align-center ma-0 pa-0"
            style="position:relative; cursor:pointer;"
            @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_name)"
        >
            <!-- # 순위 -->
            <div
                style="width:10%; font-size:14px; height:56px; border-bottom:1px solid #dedede;"
                class="text-center d-flex align-center justify-center"
            >
                {{index + 1 + (page-1) * 20}}
            </div>

            <!-- # 덱 이름 -->
            <div
                style="width:40%; font-size:14px; height:56px; border-bottom:1px solid #dedede;"
                class="d-flex align-center"
            >
                <!-- # 아이콘 -->
                <div
                    v-if="item.deck_icons?.length"
                    class="d-flex mr-3"
                >
                    <v-img
                        v-for="icon in item.deck_icons.split(',')" :key="icon"
                        :width="$vuetify.breakpoint.mobile? 28 : 34"
                        :height="$vuetify.breakpoint.mobile? 28 : 34"
                        contain
                        class="mb-1 mx-1"
                        style="opacity:1"
                        :src="'/upload/pokesprite/'+icon+'.png'"
                    ></v-img>
                </div>

                <!-- # 덱 이름 -->
                <div>
                    <p
                        class="mb-0 font-weight-medium"
                    >
                        {{deck_name_translate(item)}}
                    </p>
                    <p
                        class="mb-0 text-caption grey--text"
                    >
                        {{deck_name_translate2(item)}}
                    </p>
                </div>
            </div>

            <!-- # 티어 -->
            <div
                style="width:90px; font-size:12px; background:#FAFAFA; height:56px; border-bottom:1px solid #dedede;"
                class="d-flex align-center justify-center"
            >
                <div
                    class="diamond-box"
                    :style="calculateTierColor(item.tier)"
                >
                    <div
                        class="diamond-content d-flex justify-center align-center"
                        style="color:white; font-size:12px;"
                    >
                        {{item.tier}}
                    </div>
                </div>
            </div>

            <!-- # 점유율 -->
            <div
                style="width:22%; font-size:14px; height:56px; border-bottom:1px solid #dedede; position:relative;"
                class="text-center d-flex align-center justify-center"
            >
                {{calculateProbability(item.deck_count, total_count)}}%

                <!-- 수집률 Linear -->
                <v-progress-linear
                    style="position:absolute; bottom:-1px;"
                    color="#F8468D"
                    background-color="grey lighten-3"
                    :value="calculateProbability(item.deck_count, total_count)"
                ></v-progress-linear>
            </div>

            <!-- # 승점 -->
            <div
                style="width:21%; font-size:14px; height:56px; border-bottom:1px solid #dedede;"
                class="text-center d-flex align-center justify-center"
            >
                {{item.total_point}}
            </div>
        </v-sheet>

        <!-- # 목록 하단 -->
        <v-sheet
            height="40"
            class="pt-10px d-flex rounded-b-15"
        ></v-sheet>

        <!-- 버튼 & 페이지네이션 -->
        <v-pagination
            class="mt-2 mb-4"
            :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
            color="primary"
            v-model="page"
            :length="pageLength"
            :total-visible="7"
        ></v-pagination>

        <BannerView
            v-if="$store.state.ads_banner.official_league"
            class="my-4 mx-auto"
            type="official_league"
            width="360"
            height="46"
            :round="'rounded-10'"
        />
    </div>
</template>
<script>
import BannerView from '@/components/BannerView'

export default {
    components: {
        BannerView
    },

    props: [
        "from"
    ],

    data: () => ({
        // 선택 목록
        select_list: {
            // 기간
            date: [
                "전체",
                "최근 1개월",
                "최근 3개월",
                "최근 6개월",
                "최근 9개월",
                "최근 12개월",
            ],

            // 메인 포켓몬
            main_pokemon: [
                "전체",
            ],

            // 제품명 시즌
            season_list: [],

            // 레귤레이션
            regulation: [
                "전체",
            ],

            regulation: [
                { text: "전체", value: "전체" },
                { text: "스탠다드 대한민국", value: "스탠다드_대한민국" },
                { text: "스탠다드 일본", value: "스탠다드_일본" },
                { text: "스탠다드 인터내셔널", value: "스탠다드_인터내셔널" },
                { text: "스탠다드 아시아", value: "스탠다드_아시아" },
                { text: "익스팬디드", value: "익스팬디드" },
            ],

            // 지역
            continent: [
                "전체",
                "대한민국",
                "일본",
                "아시아",
                "아프리카",
                "유럽",
                "오세아니아",
                "북아메리카",
                "남아메리카",
                "남극",
            ],

            deck_name_nation: [
                { value: "kr", name: "한글 덱 이름"},
                { value: "en", name: "영어 덱 이름"},
                { value: "jp", name: "일어 덱 이름"}
            ]
        },

        // 키워드
        keyword: {
            date: "최근 3개월",
            main_pokemon: [],
            season: "전체",
            regulation: "전체",
            continent: "전체",
            deck_name_nation: "kr",
            is_group: false
        },

        // 목록
        list: [],
        total_count: 0,
        currentPageList: [],
        page: 1,
        pageLength: 1,
        loading: false,

        // 기타
        deck_auto_translate_list: [],
        country_list: [],
        season: {
            kr: [],
            en: [],
            jp: [],
        },
        componentKey: 0,

        sequence: "티어",
        align_tier: false,
        align_usage: false,
        align_point: false,

        nameList: [],

        deck_keyword_list: [],

        main_pokemon_key:0
    }),

    watch: {
        // 페이징
        page(){
            this.$router.replace({ query: { ...this.$route.query, page: this.page }})
            this.currentPageList = this.list.slice((this.page-1)*20,(this.page)*20)
        },

        // 키워드 저장
        keyword: {
            deep: true,
            handler(newValue) {
                for (let key in newValue) {
                    this.deck_keyword_list = this.deck_keyword_list.filter(item => item.type !== key)
                    this.deck_keyword_list.push({
                        type: key,
                        value: newValue[key]
                    })
                }

                // 컴포넌트에서 deck_keyword_list 저장
                this.$store.commit('setDeckKeywordList', this.deck_keyword_list)
            }
        },

        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_tier(){
            this.sortList()
        },

        // 순서
        align_usage(){
            this.sortList()
        },

        // 순서
        align_point(){
            this.sortList()
        },
    },

    async mounted(){
        // 국가 목록 불러오기
        this.country_list = await this.$load_country_list()

        this.loadNameList()

        if(this.from && this.from.path == "/official_league/deck_rank/detail"){
             // 컴포넌트에서 deck_keyword_list 가져오기
            this.deck_keyword_list = this.$store.state.deck_keyword_list

            await this.deck_keyword_list.forEach(item => {
                switch (item.type) {
                    case 'date':
                    this.keyword.date = item.value;
                    break;

                    case 'main_pokemon':
                    this.keyword.main_pokemon = item.value;
                    break;

                    case 'season':
                    this.keyword.season = item.value;
                    break;

                    case 'regulation':
                    this.keyword.regulation = item.value;
                    break;
                    
                    case 'continent':
                    this.keyword.continent = item.value;
                    break;

                    case 'deck_name_nation':
                    this.keyword.deck_name_nation = item.value;
                    break;

                    case 'is_group':
                    this.keyword.is_group = item.value;
                    break;
                }
            })
        }
        
        // 검색
        this.search()

        // 제품명 시즌 목록 불러오기
        await this.loadSeasonList()

        // 메인포켓몬 목록 불러오기
        await this.load_main_pokemon_list()

        // 덱분류값 번역 목록 불러오기
        this.load_deck_auto_translate_list()
    },

    methods: {
        removeMainPokemon(index) {
            this.keyword.main_pokemon.splice(index, 1)
            this.search()
        },

        // 자동번역 (Name) 목록 가져오기
        loadNameList(){
            this.$http.post('/api/admin/card/translate/list')
            .then((res) => {
                // console.log(res)
                this.nameList = res.data
            })
        },

        // 카드명 한글로 번역
        translateToKr(name) {
            for(let obj of this.nameList)
            {
                if(obj.en.toLowerCase() == name.toLowerCase())
                {
                    return obj.kr
                }
            }
            return name
        },

        // getUniqueIcon(icon, mainPokemon) {
        //     if (!icon || !icon.includes(',')) {
        //         return icon
        //     }

        //     const icons = icon.split(',')
        //     const uniqueIcon = icons.reduce((prev, curr) => {
        //         const distance1 = this.getLevenshteinDistance(prev, mainPokemon)
        //         const distance2 = this.getLevenshteinDistance(curr, mainPokemon)
        //         return distance1 < distance2 ? prev : curr
        //     })

        //     return uniqueIcon
        // },

        // getLevenshteinDistance(a, b) {
        //     if (a.length === 0) return b.length
        //     if (b.length === 0) return a.length

        //     const matrix = []

        //     for (let i = 0; i <= b.length; i++) {
        //         matrix[i] = [i]
        //     }

        //     for (let j = 0; j <= a.length; j++) {
        //         matrix[0][j] = j
        //     }

        //     for (let i = 1; i <= b.length; i++) {
        //         for (let j = 1; j <= a.length; j++) {
        //             if (b.charAt(i - 1) === a.charAt(j - 1)) {
        //                 matrix[i][j] = matrix[i - 1][j - 1]
        //             } else {
        //                 matrix[i][j] = Math.min(
        //                     matrix[i - 1][j - 1] + 1,
        //                     matrix[i][j - 1] + 1,
        //                     matrix[i - 1][j] + 1
        //                 )
        //             }
        //         }
        //     }

        //     return matrix[b.length][a.length]
        // },

        // 검색
        search(){
            this.$http.post("/api/official_league/deck_rank/select/search", {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                console.log(res.data)

                if(res.data.length){
                    // this.list = res.data

                    this.list = res.data.filter(item => 
                        this.keyword.main_pokemon.every(keyword => 
                            item.main_pokemon.includes(keyword)
                        )
                    )

                    this.list = this.list.map(item => {
                        return {
                            ...item,
                            tier: this.calculateTier(item)
                        }
                    }).sort((a, b) => a.tier - b.tier)

                    // deck_count의 총 합을 계산하여 total_count에 대입
                    this.total_count = this.list.reduce((sum, item) => sum + item.deck_count, 0)

                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }else{
                        this.page = 1
                    }
                    

                    this.currentPageList = this.list.slice((this.page-1)*20,(this.page)*20)
                    this.pageLength = Math.ceil(this.list.length/20)
                }
                else{
                    this.list = []
                    this.currentPageList = []
                    this.pageLength = 1
                    this.page = 1
                }
            })
        },

        // # 제품명 시즌 목록 불러오기
        async loadSeasonList(){
            this.select_list.season_list = [
                { text: "전체", value: "전체"}
            ]

            // 한글
            await this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.season.kr = res.data.map(a => a.name)

                this.select_list.season_list.push(
                    { header: '아시아' }
                )

                this.select_list.season_list = [
                    ...this.select_list.season_list,
                    ...this.season.kr.map(name => ({ text: name, value: name }))
                ]
            })

            // 일어
            await this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "jp"
                }
            }).then(res => {
                this.season.jp = res.data.map(a => a.name)

                this.select_list.season_list = [
                    ...this.select_list.season_list,
                    ...this.season.jp.map(name => ({ text: name, value: name }))
                ]
            })

            // 영어
            await this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "en"
                }
            }).then(res => {
                this.select_list.season_list.push({ header: '인터내셔널' })
            
                this.season.en = res.data.map(a => a.name)

                this.select_list.season_list = [
                    ...this.select_list.season_list,
                    ...this.season.en.map(name => ({ text: name, value: name }))
                ]
            })
        },

        // 점유율 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        },

        // 메인포켓몬 목록 불러오기
        async load_main_pokemon_list(){
            await this.$http.post("/api/official_league/select/main_pokemon")
            .then(async(res) => {
                this.select_list.main_pokemon = await res.data.map(obj => ({ 
                    ...obj, 
                    kr: this.translateToKr(obj.name)
                }))

                this.main_pokemon_key++

                // this.select_list.main_pokemon = res.data.map(e => e.name)
            })
        },

        // 덱분류값 번역 목록 불러오기
        load_deck_auto_translate_list(){
            this.$http.get('/api/admin/league/deck_auto_translate_list/list')
            .then((res) => {
                this.deck_auto_translate_list = res.data
            })
        },

        // 덱분류값 한글로 번역
        deck_name_translate(item) {
            if(this.deck_auto_translate_list.length == 0){
                return ''
            }

            if (item.deck_name === null) {
                return '덱분류값 없음'
            }

            let translation = this.deck_auto_translate_list.find(e => e.en === item.deck_name)

            if(this.keyword.deck_name_nation == "kr"){
                return translation ? (translation.kr || translation.en) : item.en_name
            }
            else if(this.keyword.deck_name_nation == "jp"){
                return translation ? (translation.jp || translation.en) : item.en_name
            }
            else{
                return item.en_name
            }
        },

        // 덱분류값 한글로 번역
        deck_name_translate2(item) {
            if(this.deck_auto_translate_list.length == 0){
                return ''
            }

            if (item.deck_name === null) {
                return '덱분류값 없음'
            }

            let translation = this.deck_auto_translate_list.find(e => e.en === item.deck_name)

            if(this.keyword.deck_name_nation == "kr"){
                return item.en_name
            }
            else if(this.keyword.deck_name_nation == "jp"){
                return item.en_name
            }
            else{
                return translation ? (translation.kr || translation.en) : item.en_name
            }
        },

        // 카드샵대항전 승점 계산
        caculateTotal(item){
            return item.round_win * 2 + item.round_lose * -1
        },

        // 티어 TO 백분위 계산
        calculatePercentile(list, item) {
            const sortedList = [...list].sort((a, b) => b.total_point - a.total_point)
            const index = sortedList.findIndex(i => i.deck_name === item.deck_name)
            return (index / sortedList.length) * 100
        },

        // 티어 계산
        calculateTier(item){
            let rank_tier
            let point_tier = 5
            let usage_tier = 5

            // 백분위 계산
            const percentile = this.calculatePercentile(this.list, item)

            // 백분위에 따른 티어 계산
            if (percentile <= 6) {
                rank_tier = 1
            } else if (percentile <= 22) { 
                rank_tier = 2
            } else if (percentile <= 42) {
                rank_tier = 3
            } else if (percentile <= 69) {
                rank_tier = 4
            } else {
                rank_tier = 5
            }

            // 승점 티어
            if(this.keyword.date == "전체" || this.keyword.date == "최근 12개월"){
                if(item.total_point >= 2000){
                    point_tier = 1
                }
                else if(item.total_point >= 1000 && item.total_point < 2000){
                    point_tier = 2
                }
                else if(item.total_point >= 500 && item.total_point < 1000){
                    point_tier = 3
                }
                else if(item.total_point >= 300 && item.total_point < 500){
                    point_tier = 4
                }
                else if(item.total_point < 300){
                    point_tier = 5
                }
            }
            else if(this.keyword.date == "최근 6개월"){
                if(item.total_point >= 1000){
                    point_tier = 1
                }
                else if(item.total_point >= 500 && item.total_point < 1000){
                    point_tier = 2
                }
                else if(item.total_point >= 250 && item.total_point < 500){
                    point_tier = 3
                }
                else if(item.total_point >= 150 && item.total_point < 250){
                    point_tier = 4
                }
                else if(item.total_point < 150){
                    point_tier = 5
                }
            }
            else if(this.keyword.date == "최근 3개월"){
                if(item.total_point >= 500){
                    point_tier = 1
                }
                else if(item.total_point >= 250 && item.total_point < 500){
                    point_tier = 2
                }
                else if(item.total_point >= 125 && item.total_point < 250){
                    point_tier = 3
                }
                else if(item.total_point >= 75 && item.total_point < 125){
                    point_tier = 4
                }
                else if(item.total_point < 75){
                    point_tier = 5
                }
            }
            else if(this.keyword.date == "최근 1개월"){
                if(item.total_point >= 167){
                    point_tier = 1
                }
                else if(item.total_point >= 84 && item.total_point < 167){
                    point_tier = 2
                }
                else if(item.total_point >= 42 && item.total_point < 84){
                    point_tier = 3
                }
                else if(item.total_point >= 25 && item.total_point < 42){
                    point_tier = 4
                }
                else if(item.total_point < 25){
                    point_tier = 5
                }
            }

            // 점유율 티어
            if(this.calculateProbability(item.deck_count, this.total_count) >= 5){
                usage_tier = 1
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) >= 1 && this.calculateProbability(item.deck_count, this.total_count) < 5){
                usage_tier = 2
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) >= 0.1 && this.calculateProbability(item.deck_count, this.total_count) < 1){
                usage_tier = 3
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) >= 0.05 && this.calculateProbability(item.deck_count, this.total_count) < 0.1){
                usage_tier = 4
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) < 0.05){
                usage_tier = 5
            }

            // return usage_tier

            // console.log(rank_tier)

            if(Math.max(point_tier, usage_tier) == 1){
                if(rank_tier != 1){
                    return rank_tier
                }
                else{
                    return 1
                }
            }
            else if(Math.max(point_tier, usage_tier) == 2){
                if(rank_tier != 2){
                    return rank_tier
                }
                else{
                    return 2
                }
            }
            else if(Math.max(point_tier, usage_tier) == 3){
                if(rank_tier != 3){
                    return rank_tier
                }
                else{
                    return 3
                }
            }
            else if(Math.max(point_tier, usage_tier) == 4){
                if(rank_tier != 4){
                    return rank_tier
                }
                else{
                    return 4
                }
            }
            else if(Math.max(point_tier, usage_tier) == 5){
                if(rank_tier != 5){
                    return rank_tier
                }
                else{
                    return 5
                }
            }
            else{
                return 5
            }

            // if (rank_tier < Math.min(point_tier, usage_tier)){
            //     return Math.min(point_tier, usage_tier)
            // } else {
            //     return rank_tier
            // }

            // console.log(rank_tier)

            // return Math.min(rank_tier, point_tier, usage_tier)
        },

        // 티어 색상 계산
        calculateTierColor(tier){
            if(tier == 1) return "background:#843CFF;"
            else if(tier == 2) return "background:#0093FF;"
            else if(tier == 3) return "background:#00BBA3;"
            else if(tier == 4) return "background:#FFB900;"
            else if(tier == 5) return "background:#B3B3C2;"
        },

        // 순서
        sortList(){
            if(this.sequence == "티어")
            {
                this.search()
            }
            else if(this.sequence == "점유율")
            {
                this.list = this.list.sort((a, b) => b.deck_count - a.deck_count)
                if(this.align_usage){
                    this.list = this.list.reverse()
                }

                this.currentPageList = this.list.slice(0,20)
                this.pageLength = Math.ceil(this.list.length/20)
                this.page = 1
            }
            else if(this.sequence == "승점")
            {
                this.list = this.list.sort((a, b) => b.total_point - a.total_point)
                if(this.align_point){
                    this.list = this.list.reverse()
                }

                this.currentPageList = this.list.slice(0,20)
                this.pageLength = Math.ceil(this.list.length/20)
                this.page = 1
            }

            this.componentKey++
        },
    }
}
</script>
<style scoped>
/* # 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* # V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

/* # 티어 */
.diamond-box {
    width: 20px;
    height: 20px;
    background-color: blue; /* Change the color as needed */
    transform: rotate(45deg);
    border-radius: 5px;
}

.diamond-content {
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    display: block;
}

/* # 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>