<script>
import { Line } from 'vue-chartjs'
 
export default {
    extends: Line,

    props: [
        "list",
        "interval"
    ],

    async mounted(){
        this.createGraph()
    },

    methods: {
        tierToString(tier){
            if(tier == 1) return '챔피언'
            else if(tier == 2) return '레전드'
            else if(tier == 3) return '스페셜'
            else if(tier == 4) return '어메이징'
            else if(tier == 5) return '레어'
            else if(tier == 6) return '언커먼'
            else if(tier == 7) return '커먼'
            else if(tier == 8) return '비기너'
        },

        // Chart 생성
        async createGraph(){
            let label_list

            // 라벨 목록
            if(this.interval == "일간 추이"){
                label_list = ['11일 전', '10일 전', '9일 전', '8일 전', '7일 전', '6일 전', '5일 전', '4일 전', '3일 전', '2일 전', '1일 전', '오늘']
            }
            else if(this.interval == "주간 추이"){
                label_list = ['11주 전', '10주 전', '9주 전', '8주 전', '7주 전', '6주 전', '5주 전', '4주 전', '3주 전', '2주 전', '1주 전', '이번주']
            }
            else if(this.interval == "월간 추이"){
                label_list = ['11달 전', '10달 전', '9달 전', '8달 전', '7달 전', '6달 전', '5달 전', '4달 전', '3달 전', '2달 전', '1달 전', '이번달']
            }

            this.renderChart({
                labels: label_list,
                datasets: [
                    {
                        label: '티어 추이',
                        data: this.list,
                        // borderColor: "rgba(35, 210, 226, 0.44)",
                        borderColor: "rgb(35, 210, 226)",
                        backgroundColor: "transparent",
                        pointRadius: 2,
                        lineTension: 0,

                        borderWidth: this.$vuetify.breakpoint.mobile? 4:6,  // 선 두께를 4로 설정 (기본값은 3)
                        clip: false, // 최하단, 최상단에 두께 절반되는 문제 수정

                        pointRadius: this.$vuetify.breakpoint.mobile? 6:8,
                        pointStyle: 'circle',  // 원형 스타일 지정
                        pointBackgroundColor: 'white',  // 점 내부 색상
                        pointBorderColor: "rgb(35, 210, 226)",  // 점 테두리 색상
                        pointBorderWidth: this.$vuetify.breakpoint.mobile? 3:4,  // 점 테두리 두께
                    },
                ]
            },
            {
                tooltips: {
                    // 점 상세
                    callbacks: {
                        label: (tooltipItem, data) => {
                            return this.tierToString(tooltipItem.value)
                        }
                    }
                },

                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false // 라벨(범례) 숨기기
                },
                scales: {
                    // 가로선
                    yAxes: [{
                        display: true,
                        ticks: {
                            min: 1, // 최소값을 1로 설정
                            max: 8, // 최대값을 5로 설정
                            stepSize: 1, // 눈금 간격을 1로 설정
                            reverse: true, // 눈금을 역순으로 표시
                            userCallback: (value, index, values) => {
                                // value = value.toString()
                                // value = value.split(/(?=(?:...)*$)/)
                                // value = value.join(',')
                                return this.tierToString(value)
                            }
                        },
                        gridLines : {
                            display : true // 가로선 표시 여부
                        },

                        // 새로운 설정 추가
                        suggestedMin: Math.min(...this.list) - 0.5,
                        suggestedMax: Math.max(...this.list) + 0.5
                    }],

                    // 세로선
                    xAxes: [{
                        display: true,

                        gridLines : {
                            display : false // 세로선 표시 여부
                        },

                        ticks: {
                            padding: 3 // x축 레이블과 차트 사이의 패딩 (기본: 2)
                        }
                    }]
                },

                layout: {
                    padding: {
                        top: 10,
                    }
                },
            })
        }
    }
}
</script>