<template>
    <div>
        <v-sheet
            class="mt-2 px-4 py-2 pb-3 rounded-10"
        >
            <!-- # 상단 -->
            <div
                class="d-flex align-center justify-space-between mb-1"
                :class="$vuetify.breakpoint.mobile? '':'mt-2'"
            >
                <!-- # 제목 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # PC -->
                    <p
                        v-if="!$vuetify.breakpoint.mobile"
                        style="font-size:18px; font-weight:bold;"
                        class="my-0"
                    >
                        파생 덱 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                    </p>

                    <!-- # Mobile -->
                    <p
                        v-if="$vuetify.breakpoint.mobile"
                        style="font-size:10px; font-weight:bold;"
                        class="mb-2 mt-2"
                    >
                        파생 덱 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                    </p>
                </div>

                <!-- # 선택 상단 -->
                <SelectHeader
                    class="mr-2"
                    :global_term="term"
                    @term_changed="term_changed_handler"
                    :key="componentKey + 'raceHeader'"
                />
            </div>

            <!-- # 목록 (Mobile) -->
            <div
                v-if="$vuetify.breakpoint.mobile"
                class="mt-2"
            >
                <!-- # 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    height="32"
                    color="#F0F3F5"
                    width="100%"
                >
                    <div
                        style="width:46%; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        덱
                    </div>
                    <div
                        style="width:18%; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        게임수
                    </div>
                    <div
                        style="width:18%; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        승점
                    </div>
                    <div
                        style="width:18%; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        메타
                    </div>
                </v-sheet>

                <!-- # 목록 값 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index"
                    height="38"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                    @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier1)"
                >
                    <!-- # 덱 (아이콘 & 덱분류값) -->
                    <div
                        style="width:46%;"
                    >
                        <!-- # 덱 분류값이 있는 경우 (아이콘 & 덱분류값) -->
                        <div
                            v-if="item.icons"
                            class="pl-1 d-flex align-center"
                        >
                            <!-- # 덱 아이콘 -->
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        style="font-size:10px;"
                                        class="d-flex align-center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <!-- # 메인 덱 분류 아이콘 (DAY1) -->
                                        <div
                                            v-if="item.icons"
                                        >
                                            <div
                                                v-if="item.icons"
                                                class="d-flex align-center mt-1"
                                            >
                                                <v-img
                                                    v-for="icon in item.icons.split(',')" :key="icon"
                                                    :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                    :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                    contain
                                                    class="mb-1 mx-3px"
                                                    style="opacity:1"
                                                    :src="'/upload/pokesprite/'+icon+'.png'"
                                                ></v-img>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <!-- # 아이콘 툴팁 (덱분류값) -->
                                <div class="text-center white--text pa-1">
                                    {{deck_name_translate(item.deck_identifier1)}}
                                </div>
                            </v-tooltip>

                            <!-- # 덱분류값 -->
                            <div
                                class="pl-1 text-truncate"
                                style="font-size:11px;" 
                            >
                                {{deck_name_translate(item.deck_identifier1)}}
                            </div>
                        </div>

                        <!-- # 덱 분류값이 없는 경우 -->
                        <div
                            v-else
                            class="pl-1"
                        >
                            <!-- # 덱분류값 -->
                            <div style="font-size:11px;">
                                덱 정보없음
                            </div>
                        </div>
                    </div>

                    <!-- 게임 수 -->
                    <div
                        style="width:18%; font-size:11px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.count}}
                    </div>

                    <!-- 승점 -->
                    <div
                        style="width:18%; font-size:11px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.total_point}}
                    </div>

                    <div
                        style="width:18%; font-size:11px; height:38px;"
                        class="text-center d-flex align-center justify-center pl-2"
                    >
                        메타
                        <v-icon small style="margin-left:-2px;">mdi-chevron-right</v-icon>
                    </div>
                </v-sheet>
            </div>

            <!-- # 목록 (PC) -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
                class="mt-2"
            >
                <!-- # 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    height="40"
                    color="#F0F3F5"
                    width="100%"
                >
                    <div
                        style="width:40%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        덱
                    </div>
                    <div
                        style="width:20%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        게임수
                    </div>
                    <div
                        style="width:20%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        승점
                    </div>
                    <div
                        style="width:20%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        메타
                    </div>
                </v-sheet>

                <!-- # 목록 값 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index"
                    height="56"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                    @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier1)"
                >
                    <!-- # 덱 (아이콘 & 덱분류값) -->
                    <div
                        style="width:40%;"
                    >
                        <!-- # 덱 분류값이 있는 경우 (아이콘 & 덱분류값) -->
                        <div
                            v-if="item.icons"
                            class="pl-4 d-flex align-center"
                        >
                            <!-- # 덱 아이콘 -->
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        style="font-size:10px;"
                                        class="d-flex align-center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <!-- # 메인 덱 분류 아이콘 (DAY1) -->
                                        <div
                                            v-if="item.icons"
                                        >
                                            <div
                                                v-if="item.icons"
                                                class="d-flex align-center mt-1"
                                            >
                                                <v-img
                                                    v-for="icon in item.icons.split(',')" :key="icon"
                                                    :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                    :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                    contain
                                                    class="mb-1 mx-1"
                                                    style="opacity:1"
                                                    :src="'/upload/pokesprite/'+icon+'.png'"
                                                ></v-img>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <!-- # 아이콘 툴팁 (덱분류값) -->
                                <div class="text-center white--text pa-1">
                                    {{deck_name_translate(item.deck_identifier1)}}
                                </div>
                            </v-tooltip>

                            <!-- # 덱분류값 -->
                            <div
                                class="pl-2"
                            >
                                <!-- # 덱분류값 -->
                                <div style="font-size:14px;">
                                    {{deck_name_translate(item.deck_identifier1)}}
                                </div>
                            </div>
                        </div>

                        <!-- # 덱 분류값이 없는 경우 -->
                        <div
                            v-else
                            class="pl-3"
                        >
                            <!-- # 덱분류값 -->
                            <div style="font-size:14px;">
                                덱 정보없음
                            </div>
                        </div>
                    </div>

                    <!-- 게임 수 -->
                    <div
                        style="width:20%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.count}} 게임
                    </div>

                    <!-- 승점 -->
                    <div
                        style="width:20%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        승점 {{item.total_point}}
                    </div>

                    <div
                        style="width:20%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center pl-3"
                    >
                        메타
                        <v-icon small>mdi-chevron-right</v-icon>
                    </div>
                </v-sheet>
            </div>

            <!-- # 버튼 & 페이지네이션 -->
            <v-pagination
                class="mt-2"
                :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>
        </v-sheet>
    </div>
</template>
<script>
import SelectHeader from './components/SelectHeader'

export default {
    props: ["global_term"],

    components: {
        SelectHeader
    },

    data: () => ({
        term: "최근 3개월",

        list: [],
        currentPageList: [],
        page: 1,
        pageLength: 1,

        selected_item: {},
        deck_auto_translate_list: [],
        country_list: [],
        componentKey: 0,

        upper_deck: "",
        is_variant: false
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*6,(this.page)*6)
        },

        global_term(newVal){
            this.term = newVal
        }
    },

    async mounted(){
        this.term = this.global_term

        // # 덱분류값 번역 목록 불러오기
        await this.load_deck_auto_translate_list()

        // 국가 목록 불러오기
        this.country_list = await this.$load_country_list()

        // # Variant 덱인지 확인
        await this.isVariantDeck()

        // # 파생 덱 불러오기
        this.loadVariantDeck()
    },

    methods: {
        async term_changed_handler(term){
            this.term = term

            // # Variant 덱인지 확인
            await this.isVariantDeck()

            this.loadVariantDeck()
        },

        // # Variant 덱인지 확인
        async isVariantDeck(){
            await this.$http.post("/api/official_league/deck_rank/select/is_variant", {
                params: {
                    identifier: this.$route.query.id,
                }
            }).then((res) => {
                console.log("is_variant", res.data)
                if(res.data.length){
                    this.is_variant = true
                    this.upper_deck = res.data[0].identifier
                    console.log("파생덱임")
                }else{
                    this.is_variant = false
                    console.log("파생덱 아님")
                }
            })
        },

        // # 파생 덱 불러오기
        loadVariantDeck(){
            // # 현재 덱이 파생 덱이 아닌 경우
            if(!this.is_variant){
                this.$http.post("/api/official_league/deck_rank/select/variant_deck", {
                    params: {
                        identifier: this.$route.query.id,
                        term: this.term
                    }
                }).then((res) => {
                    // console.log("파생 덱1", res.data)
                    if(res.data.length){
                        this.list = res.data
                        this.currentPageList = this.list.slice(0,6)
                        this.pageLength = Math.ceil(this.list.length/6)
                        this.page = 1
                    }
                })
            }

            // # 현재 덱이 파생 덱인 경우
            else if(this.is_variant){
                this.$http.post("/api/official_league/deck_rank/select/variant_deck", {
                    params: {
                        identifier: this.upper_deck,
                        term: this.term
                    }
                }).then((res) => {
                    // console.log("파생 덱2", res.data)
                    if(res.data.length){
                        this.list = res.data.filter(e => e.deck_identifier1 != this.$route.query.id)

                        // # upper 덱 앞에 추가
                        this.$http.post("/api/official_league/deck_rank/select/upper_deck", {
                            params: {
                                identifier: this.upper_deck,
                                term: this.term
                            }
                        }).then((res2) => {
                            // console.log("upper_deck", res.data)
                            if(res2.data.length){
                                this.list.unshift(res2.data[0])
                            }
                            this.currentPageList = this.list.slice(0,6)
                            this.pageLength = Math.ceil(this.list.length/6)
                            this.page = 1
                        })
                    }
                })
            }
        },

        // # 덱분류값 번역 목록 불러오기
        load_deck_auto_translate_list(){
            this.$http.get('/api/admin/league/deck_auto_translate_list/list')
            .then((res) => {
                this.deck_auto_translate_list = res.data
            })
        },

        // # 덱분류값 한글로 번역
        deck_name_translate(en) {
            if(!en) return 
            let translation = this.deck_auto_translate_list.find(item => item.en === en)
            return translation ? (translation.kr || en) : en
        },

        // 카드샵대항전 승점 계산
        caculateTotal(item){
            return item.round_win * 2 + item.round_lose * -1
        },
    }
}
</script>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>