<template>
    <div>
        <v-sheet
            class="mt-2 px-4 py-2 pb-3 rounded-10"
        >
            <!-- # 상단 -->
            <div
                class="d-flex align-center justify-space-between mb-1"
                :class="$vuetify.breakpoint.mobile? '':'mt-2'"
            >
                <!-- # 제목 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # PC -->
                    <p
                        v-if="!$vuetify.breakpoint.mobile"
                        style="font-size:18px; font-weight:bold;"
                        class="my-0"
                    >
                        플레이어 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                    </p>

                    <!-- # Mobile -->
                    <p
                        v-if="$vuetify.breakpoint.mobile"
                        style="font-size:10px; font-weight:bold;"
                        class="mb-2 mt-2"
                    >
                        플레이어 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                    </p>
                </div>

                <!-- # 선택 상단 -->
                <SelectHeader
                    class="mr-2"
                    :global_term="term"
                    @term_changed="term_changed_handler"
                    :key="componentKey + 'raceHeader'"
                />
            </div>

            <!-- # 목록 (Mobile) -->
            <div
                v-if="$vuetify.breakpoint.mobile"
            >
                <!-- # 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    height="32"
                    color="#F0F3F5"
                    width="100%"
                >
                    <div
                        style="width:10%; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        #
                    </div>
                    <div
                        style="width:46%; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        플레이어
                    </div>
                    <div
                        style="width:22%; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        게임수
                    </div>
                    <div
                        style="width:22%; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        승점
                    </div>
                </v-sheet>

                <!-- # 목록 값 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index+'list'"
                    height="38"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                    @click="$router.push('/official_league/player/detail?id='+item.uid)"
                >
                    <div
                        style="width:10%; font-size:12px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{index+6*(page-1)+1}}
                    </div>

                    <!-- 국가 -->
                    <div
                        style="width:46%; font-size:12px; height:38px;"
                        class="pl-1 d-flex align-center font-weight-medium primary--text"
                    >
                        <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                            <template v-slot:activator="{ on, attrs }">
                                <v-sheet
                                    height="32"
                                    width="26"
                                    class="rounded-bl-10"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-img
                                        v-if="item.player_country"
                                        width="28"
                                        height="20"
                                        class="rounded-5 mx-auto mt-5px"
                                        style="box-shadow:0px 2px 4px #ddd;"
                                        :src="'/upload/flags/'+item.player_country.toLowerCase()+'.svg'"
                                    ></v-img>
                                </v-sheet>
                            </template>
                            <div class="text-center pa-1">
                                {{$country_name(item.player_country, country_list)}}
                            </div>
                        </v-tooltip>

                        <div
                            v-if="!item.player_local"
                            class="ml-2"
                        >
                            <p
                                style="font-size:11px; font-weight:600; margin-bottom:-2px;"
                            >
                                {{item.player_kr}}
                            </p>
                            <p
                                style="font-size:9px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                            >
                                {{item.player_en}}
                            </p>
                        </div>
                        <div
                            v-else
                            class="ml-4"
                        >
                            <p
                                style="font-size:12px; font-weight:600; margin-bottom:0px;"
                            >
                                {{item.player_local}}
                            </p>
                        </div>
                    </div>

                    <div
                        style="width:22%; font-size:12px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.count}} 게임
                    </div>
                    <div
                        style="width:22%; font-size:12px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        승점 {{item.total_point}}
                    </div>
                </v-sheet>
            </div>

            <!-- # 목록 (PC) -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
                class="mt-2"
            >
                <!-- # 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    height="40"
                    color="#F0F3F5"
                    width="100%"
                >
                    <div
                        style="width:10%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        #
                    </div>
                    <div
                        style="width:40%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        플레이어
                    </div>
                    <div
                        style="width:25%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        게임수
                    </div>
                    <div
                        style="width:25%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        승점
                    </div>
                </v-sheet>

                <!-- # 목록 값 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index+'list'"
                    height="56"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                    @click="$router.push('/official_league/player/detail?id='+item.uid)"
                >
                    <div
                        style="width:10%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{index+6*(page-1)+1}}
                    </div>

                    <!-- 국가 -->
                    <div
                        style="width:40%; font-size:14px; height:56px;"
                        class="d-flex align-center font-weight-medium primary--text"
                    >
                        <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                            <template v-slot:activator="{ on, attrs }">
                                <v-sheet
                                    height="52"
                                    width="38"
                                    class="rounded-bl-15"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-img
                                        v-if="item.player_country"
                                        width="36"
                                        height="26"
                                        class="rounded-5 mx-auto mt-3"
                                        style="box-shadow:0px 2px 4px #ddd;"
                                        :src="'/upload/flags/'+item.player_country.toLowerCase()+'.svg'"
                                    ></v-img>
                                </v-sheet>
                            </template>
                            <div class="text-center pa-1">
                                {{$country_name(item.player_country, country_list)}}
                            </div>
                        </v-tooltip>

                        <div
                            v-if="!item.player_local"
                            class="ml-4"
                        >
                            <p
                                style="font-size:14px; font-weight:600; margin-bottom:-2px;"
                            >
                                {{computed_player_kr(item)}}
                            </p>
                            <p
                                style="font-size:12px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                            >
                                {{computed_player_en(item)}}
                            </p>
                        </div>
                        <div
                            v-else
                            class="ml-4"
                        >
                            <p
                                style="font-size:14px; font-weight:600; margin-bottom:0px;"
                            >
                                {{item.player_local}}
                            </p>
                        </div>
                    </div>

                    <div
                        style="width:25%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.count}} 게임
                    </div>
                    <div
                        style="width:25%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        승점 {{item.total_point}}
                    </div>
                </v-sheet>
            </div>

            <!-- # 버튼 & 페이지네이션 -->
            <v-pagination
                class="mt-2"
                :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>
        </v-sheet>
    </div>
</template>
<script>
import SelectHeader from './components/SelectHeader'

export default {
    props: ["global_term"],

    components: {
        SelectHeader
    },

    data: () => ({
        term: "최근 3개월",

        list: [],
        currentPageList: [],
        page: 1,
        pageLength: 1,

        selected_item: {},
        deck_auto_translate_list: [],
        country_list: [],
        componentKey: 0,
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*6,(this.page)*6)
        },

        global_term(newVal){
            this.term = newVal
        }
    },

    async mounted(){
        this.term = this.global_term

        // # 덱분류값 번역 목록 불러오기
        await this.load_deck_auto_translate_list()

        // 국가 목록 불러오기
        this.country_list = await this.$load_country_list()

        // 대회 성적 불러오기
        this.loadPlayerRecord()
    },

    methods: {
        term_changed_handler(term){
            this.term = term
            this.loadPlayerRecord()
        },

        // 대회 성적 불러오기
        loadPlayerRecord(){
            this.$http.post("/api/official_league/deck_rank/select/player_record", {
                params: {
                    identifier: this.$route.query.id,
                    term: this.term
                }
            }).then((res) => {
                if(res.data.length){
                    this.list = res.data
                    this.currentPageList = this.list.slice(0,6)
                    this.pageLength = Math.ceil(this.list.length/6)
                    if(this.pageLength > 99) this.pageLength = 99
                    this.page = 1
                }
            })
        },

        // # 덱분류값 번역 목록 불러오기
        load_deck_auto_translate_list(){
            this.$http.get('/api/admin/league/deck_auto_translate_list/list')
            .then((res) => {
                this.deck_auto_translate_list = res.data
            })
        },

        // # 덱분류값 한글로 번역
        deck_name_translate(en) {
            if(!en) return 
            let translation = this.deck_auto_translate_list.find(item => item.en === en)
            return translation ? (translation.kr || en) : en
        },

        // 카드샵대항전 승점 계산
        caculateTotal(item){
            return item.round_win * 2 + item.round_lose * -1
        },

        computed_player_kr(item) {
            if(item.player_local){
                return item.player_local
            }
            else{
                if(item.player_country != "KR"){
                    return item.player_kr
                }else{
                    if(item.is_masking != null){
                        if(item.is_masking){
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }else{
                            return item.player_kr
                        }
                    }
                    else{
                        if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                            return item.player_kr
                        }else{
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(item.player_country != "KR"){
                return item.player_en
            }else{
                if(item.is_masking != null){
                    if(item.is_masking){
                        return item.player_en.split(' ')[0] + ' *'
                    }else{
                        return item.player_en
                    }
                }
                else{
                    if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                        return item.player_en
                    }else{
                        return item.player_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>