<template>
    <div>
        <!-- 제목 & 기간(Global) -->
        <div
            class="d-flex align-center px-5 pt-3 pb-3 mobile"
        >
            <!-- 제목 -->
            <p
                style="font-size:16px; font-weight:bold;"
                class="mb-0"
            >
                플레이어 정보
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <!-- 로딩 -->
            <!-- <v-progress-circular
                v-if="loading"
                class="ml-2"
                indeterminate
                color="primary"
                size="18"
                width="2"
            ></v-progress-circular> -->

            <v-spacer></v-spacer>

            <div
                class="d-flex"
            >
                <v-btn
                    depressed
                    dark
                    class="rounded-10 px-3 mr-1"
                    style="font-size:12px;"
                    color="icu_purple"
                    height="28"
                    @click="$router.push('/official_league/list')"
                >
                    공식
                </v-btn>

                <v-btn
                    depressed
                    dark
                    class="rounded-10 px-3"
                    style="font-size:12px;"
                    color="#adafca"
                    height="28"
                >
                    대회
                </v-btn>

                <v-btn
                    depressed
                    dark
                    class="rounded-10 ml-1"
                    style="font-size:12px;"
                    color="primary"
                    width="46"
                    height="28"
                    @click="from && from.path == '/official_league/player/list'? $router.go(-1) : $router.push('/official_league/player/list')"
                >
                    목록
                </v-btn>
            </div>
        </div>

        <!-- 플레이어 정보 상단 -->
        <v-sheet
            class="py-3 px-6 d-flex align-center"
            style="position:relative;"
        >
            <!-- 프로필 아이콘 -->
            <v-sheet
                v-if="!user_league_info.image"
                width="60"
                height="60"
                class="rounded-circle d-flex justify-center align-center"
                outlined
            >
                <v-img
                    class="ml-2px"
                    height="36"
                    width="36"
                    contain
                    src="@/assets/logos/icon_grey.svg"
                ></v-img>
            </v-sheet>

            <!-- 프로필 아이콘 -->
            <v-sheet
                v-if="user_league_info.image"
                width="60"
                height="60"
                class="rounded-circle"
            >
                <v-img
                    v-if="user_league_info.image"
                    class="rounded-circle"
                    height="60"
                    width="60"
                    :src="'/upload/user_league_img/'+user_league_info.image"
                ></v-img>
            </v-sheet>

            <!-- 플레이어 & 이번주 순위 -->
            <div
                class="ml-3"
            >
                <div
                    class="d-flex align-center mb-2px mt-1"
                >
                    <p
                        class="mb-0 mr-2"
                        style="font-size:12px; font-weight:bold;"
                    >
                        {{computed_player_kr(player)}}
                    </p>
                    <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                        <template v-slot:activator="{ on, attrs }">
                            <v-sheet
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-img
                                    v-if="player.player_country"
                                    class="rounded-5"
                                    width="15"
                                    height="13"
                                    style="box-shadow:0px 2px 4px #ddd;"
                                    :src="'/upload/flags/'+player.player_country.toLowerCase()+'.svg'"
                                ></v-img>
                            </v-sheet>
                        </template>
                        <div class="text-center pa-1">
                            {{$country_name(player.player_country, country_list)}}
                        </div>
                    </v-tooltip>
                </div>
                <p
                    v-if="week_rank != 0"
                    class="grey--text mb-0"
                    style="font-size:9px;"
                >
                    이번주 순위 {{week_rank}}위 ({{week_total}}명 중 상위 {{week_percent}}%)
                </p>
                <p
                    v-else
                    class="grey--text mb-0"
                    style="font-size:9px;"
                >
                    이번주 대회 정보 없음
                </p>

                <p
                    class="mb-0 font-weight-medium mt-2px"
                    style="font-size:10px;"
                    :style="$age_group_color(player.player_age_group)"
                >
                    {{player.player_age_group}}
                </p>

                <!-- 즐겨찾기 -->
                <v-sheet
                    class="rounded-10 text-center ml-1"
                    height="28"
                    width="28"
                    style="cursor:pointer; position:absolute; top:12px; right:78px;"
                    @click="updateFavorite()"
                >
                    <v-icon
                        size="22"
                        :color="is_favorite? 'primary':'#b9b9c7'"
                    >
                        mdi-heart
                    </v-icon>
                </v-sheet>

                <v-sheet
                    v-if="user_league_info.youtube"
                    class="rounded-circle text-center d-flex justify-center align-center ml-1"
                    style="cursor:pointer; position:absolute; top:12px; right:45px; cursor:pointer;"
                    outlined
                    height="28"
                    width="28"
                    @click="user_league_info.youtube? open_youtube():''"
                >
                    <v-img
                        height="12"
                        width="12"
                        contain
                        src="@/assets/official_league/youtube.png"
                    ></v-img>
                </v-sheet>

                <v-sheet
                    v-if="user_league_info.twitter"
                    class="rounded-circle text-center d-flex justify-center align-center ml-1"
                    style="cursor:pointer; position:absolute; top:12px; right:12px; cursor:pointer;"
                    color="black"
                    height="28"
                    width="28"
                    @click="user_league_info.twitter? open_twitter():''"
                >
                    <v-img
                        height="12"
                        width="12"
                        contain
                        src="@/assets/official_league/x.svg"
                    ></v-img>
                </v-sheet>
            </div>

            <v-spacer></v-spacer>

            <!-- SNS & 국기 -->
            <div
                class="d-flex align-center justify-end"
            >
                <!-- <v-img
                    class="ml-3 rounded-5"
                    width="32"
                    height="28"
                    :src="'/upload/flags/'+player.country?.toLowerCase()+'.svg'"
                ></v-img> -->
            </div>
        </v-sheet>

        <!-- 플레이어 등급 -->
        <v-sheet
            class="ma-2 px-4 pt-4 pb-5 rounded-10 d-flex align-center"
            outlined
        >
            <!-- 등급 -->
            <v-sheet
                width="60"
                height="44"
            >
                <v-img
                    v-if="computed_rank_name(same_country_rank)"
                    contain
                    :src="require(`@/assets/official_league/player/${computed_rank_name(same_country_rank)}.png`)"
                ></v-img>
            </v-sheet>

            <!-- 등급 & RP & 승률(승패무) -->
            <div
                class="ml-3"
            >
                <!-- 등급 & RP -->
                <p
                    class="mb-0"
                >
                    <!-- 등급 -->
                    <span
                        style="font-weight:bold; font-size:12px;"
                        :style="computed_rank_color(same_country_rank)"
                    >
                        {{computed_rank_name(same_country_rank)}}
                    </span>

                    <!-- RP -->
                    <span
                        class="ml-1"
                        style="color:#C8C9CF; font-weight:400; font-size:9px;"
                    >
                        총점 {{$toComma(total_point)}} / 게임 {{$toComma(total_game)}}
                    </span>
                </p>

                <!-- 승률(승패무) -->
                <p
                    class="mb-0"
                    style="font-size:9px;"
                >
                    {{player.player_age_group}} {{player.player_country}} {{same_country_rank}}위 / Global {{rank}}위
                </p>
            </div>

            <v-spacer></v-spacer>

            <!-- SNS & 국기 -->
            <div
                class="d-flex align-center justify-end mobile pt-6px"
            >
                <!-- 시즌 -->
                <v-select
                    height="28"
                    style="width:90px;"
                    class="rounded-10 font-weight-bold shrink ml-1"
                    dense
                    outlined
                    hide-details
                    :items="select_list.term"
                    v-model="term"
                    @change="componentKey+=1"
                >
                    <template v-slot:selection="{ item }">
                        <p class="text-truncate pt-2px" style="height:28px;">{{item}}</p>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="pt-1px" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </div>
        </v-sheet>

        <!-- 티어 추이 -->
        <TierChart
            :player_country="player.player_country"
            :player_age_group="player.player_age_group"
            :key="graphKey"
        />

        <!-- 대회 성적 -->
        <RaceRecord
            :global_term="term"
            :key="componentKey + 'Race'"
        />

        <!-- 덱 레시피 -->
        <DeckRecipe
            :global_term="term"
            :key="componentKey + 'Deck'"
        />

        <!-- 덱 레시피 -->
        <CareerSummary
            :global_term="term"
            :key="componentKey + 'CareerSummary'"
        />

        <!-- UID -->
        <div
            class="my-4"
        >
            <p
                class="icu_purple--text text-center mb-2 text-caption"
            >
                ICU_UID
                <span class="font-weight-bold icu_purple--text">{{$route.query.id}}</span>
            </p>

            <div
                class="d-flex justify-center"
            >
                <v-btn
                    width="110"
                    small
                    class="rounded-10"
                    color="icu_purple"
                    dark
                    depressed
                    @click="open_uid_apply()"
                >
                    UID 연결신청
                </v-btn>

                <v-btn
                    v-if="user_uid == $route.query.id"
                    width="110"
                    small
                    class="ml-2 rounded-10"
                    color="icu_purple"
                    dark
                    depressed
                    @click="$router.push('/mypage/official_league')"
                >
                    프로필 수정
                </v-btn>
            </div>
        </div>

        <BannerView
            v-if="$store.state.ads_banner.official_league"
            class="mt-8"
            type="official_league"
            width="100%"
            :height="46/360*100+'vw'"
            :round="'rounded-0'"
        />

        <!-- UID 연결신청 -->
        <v-dialog
            v-model="dialog.uid_apply"
            width="440"
            content-class="rounded-15"
        >
            <v-sheet
                class="pa-5 pt-6 rounded-15 uid_apply"
                style="position:relative; border:3px solid #1DA1F2;"
            >
                <div
                    style="position:absolute; top:22px; right:24px;"
                >
                    <p
                        class="text-right text-caption font-weight-bold mb-0"
                    >
                        {{$store.state.nickname}}
                    </p>
                    <p
                        class="text-right icu_purple--text text-caption"
                        style="margin-top:-6px;"
                    >
                        {{user_uid? user_uid : "UID 연결 전"}}
                    </p>
                </div>

                <p class="text-h6 font-weight-bold mb-0">UID 연결신청</p>

                <p
                    class="font-weight-regular grey--text text--lighten-1 mb-3"
                    style="font-size:10px;"
                >
                    비회원으로 입력되었던 대회정보를 통합할 수 있습니다
                </p>

                <v-btn
                    class="rounded-10 px-3"
                    small
                    color="primary"
                    dark
                    depressed
                >
                    공식대회
                </v-btn>

                <v-btn
                    class="rounded-10 px-3 ml-2"
                    small
                    color="primary"
                    outlined
                    depressed
                    @click="$buttonAlert('준비중입니다')"
                >
                    대회진행
                </v-btn>

                <v-text-field
                    class="rounded-15 mx-1 mt-6"
                    outlined
                    label="연결할 ICU_UID"
                    persistent-placeholder
                    v-model="uid"
                    readonly
                ></v-text-field>

                <p
                    class="text-caption icu_purple--text mx-3 font-weight-medium"
                    style="margin-top:-6px;"
                >
                    최근대회 성적
                </p>

                <p
                    class="text-body-2 mx-3 font-weight-medium"
                >
                    {{notice}}
                </p>

                <v-sheet class="mt-8 d-flex justify-center">
                    <v-btn
                        width="100"
                        class="rounded-10 mr-4"
                        color="blue"
                        dark
                        depressed
                        @click="uid_apply_submit()"
                    >
                        신청
                    </v-btn>
                    <v-btn
                        width="100"
                        class="rounded-10"
                        color="grey"
                        dark
                        depressed
                        @click="dialog.uid_apply = false"
                    >
                        취소
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import TierChart from "./components/TierChart"
import RaceRecord from "./components/RaceRecord"
import DeckRecipe from "./components/DeckRecipe"
import CareerSummary from "./components/CareerSummary"
import BannerView from '@/components/BannerView'

export default {
    props: [
        "from"
    ],

    components: {
        TierChart,
        RaceRecord,
        DeckRecipe,
        CareerSummary,
        BannerView
    },

    data: () => ({
        select_list: {
            term: [
                "전체",
                "최근 12개월",
                "최근 9개월",
                "최근 6개월",
                "최근 3개월",
                "최근 1개월",
            ],
        },

        term: "최근 3개월",

        player: {},

        country_list: [],

        loading: false,
        componentKey: 0,

        total_point: 0,
        total_game: 0,
        same_country_rank: 0,
        rank: 0,

        is_favorite: false,

        week_rank: 0,
        week_percent: 0,

        graphKey: 0,

        dialog: {
            uid_apply: false
        },

        uid: null,
        user_uid: null,
        is_league: false,
        notice: "",

        user_league_info: {}
    }),

    watch: {
        term(){
            // 플레이어 정보 불러오기
            this.loadPlayerRank()
        },
    },

    async mounted(){
        // 국가 목록 불러오기
        this.country_list = await this.$load_country_list()

        // 플레이어 정보 불러오기
        await this.loadPlayer()

        // 플레이어 정보 불러오기
        await this.loadPlayerRank()

        this.loadFavorite()

        this.uid = this.$route.query.id

        this.$http.post("/api/user_league_info/select/by_uid/connected", {
            params: {
                uid: this.$route.query.id
            }
        }).then((res) => {
            console.log(res)
            if(res.data.length){
                this.user_league_info = res.data[0]
            }
        })

        this.$http.post("/api/user_league_info/select/specific", {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then((res) => {
            if(res.data.length){
                if(res.data[0].uid != null){
                    this.user_uid = res.data[0].uid
                }

                this.is_league = true
            }
        })

        this.uid_search()
    },

    methods: {
        open_twitter() {
            if (this.user_league_info.twitter) {
                const url = this.user_league_info.twitter.startsWith('http') ? this.user_league_info.twitter : `https://${this.user_league_info.twitter}`
                window.open(url, '_blank')
            }
        },

        open_youtube() {
            if (this.user_league_info.youtube) {
                const url = this.user_league_info.youtube.startsWith('http') ? this.user_league_info.youtube : `https://${this.user_league_info.youtube}`
                window.open(url, '_blank')
            }
        },

        loadFavorite(){
            this.$http.post("/api/official_league/player_favorite/select", {
                params: {
                    uid: this.$route.query.id,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                if(res.data.length){
                    this.is_favorite = true
                }else{
                    this.is_favorite = false
                }
            })
        },

        // 즐겨찾기 상태 변경
        updateFavorite(){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }
            
            if(this.is_favorite){
                this.is_favorite = false
                this.$http.post("/api/official_league/player_favorite/delete", {
                    params: {
                        uid: this.$route.query.id,
                        user_id: this.$store.state.user_id
                    }
                })
            }
            else{
                this.is_favorite = true
                this.$http.post('/api/official_league/player_favorite/insert', {
                    params: {
                        uid: this.$route.query.id,
                        user_id: this.$store.state.user_id
                    }
                })
            }
        },

        computed_rank_name(same_country_rank){
            if(same_country_rank >= 1 && same_country_rank <= 3){
                return "챔피언"
            }
            else if(same_country_rank >= 4 && same_country_rank <= 10){
                return "레전드"
            }
            else if(same_country_rank >= 11 && same_country_rank <= 19){
                return "스페셜"
            }
            else if(same_country_rank >= 20 && same_country_rank <= 32){
                return "어메이징"
            }
            else if(same_country_rank >= 33 && same_country_rank <= 45){
                return "레어"
            }
            else if(same_country_rank >= 46 && same_country_rank <= 60){
                return "언커먼"
            }
            else if(same_country_rank >= 61 && same_country_rank <= 100){
                return "커먼"
            }
            else if(same_country_rank > 100 || same_country_rank == 0){
                return "비기너"
            }
        },

        computed_rank_color(same_country_rank){
            if(same_country_rank >= 1 && same_country_rank <= 3){
                return "color:#FF7A51"
            }
            else if(same_country_rank >= 4 && same_country_rank <= 10){
                return "color:#F8468D"
            }
            else if(same_country_rank >= 11 && same_country_rank <= 19){
                return "color:#615DFA"
            }
            else if(same_country_rank >= 20 && same_country_rank <= 32){
                return "color:#23D2E2"
            }
            else if(same_country_rank >= 33 && same_country_rank <= 45){
                return "color:#1DA1F2"
            }
            else if(same_country_rank >= 46 && same_country_rank <= 60){
                return "color:#424361"
            }
            else if(same_country_rank >= 61 && same_country_rank <= 100){
                return "color:#424361"
            }
            else if(same_country_rank > 100 || same_country_rank == 0){
                return "color:#424361"
            }
        },

        // 플레이어 정보 불러오기
        async loadPlayer(){

            await this.$http.post("/api/official_league/player/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then(async(res) => {
                // console.log(res.data[0])
                this.player = res.data[0]

                this.total_game = res.data[0].total_game

                this.componentKey++

                this.graphKey++

                this.loadPlayerWeekRank()
            })
        },

        // 플레이어 순위 찾기
        async loadPlayerRank() {
            await this.$http.post("/api/official_league/player/select/rank", {
                params: {
                    term: this.term
                }
            }).then(async(res) => {
                // console.log(res)

                // 같은 국가 순위 (같은 점수 처리 전)
                // let same_country_list = res.data.filter(e => e.player_country == this.player.player_country && e.player_age_group == this.player.player_age_group)
                // this.same_country_rank = same_country_list.findIndex(player => player.id == this.$route.query.id) + 1
                // this.rank = res.data.findIndex(player => player.id == this.$route.query.id) + 1

                if(res.data.length){
                    if(res.data.find(player => player.id == this.$route.query.id) != undefined){
                        this.total_point = res.data.find(player => player.id == this.$route.query.id).total_point
                    }

                    // 전체 순위 계산
                    let prevPoint = null
                    let rank = 1
                    for (let i = 0; i < res.data.length; i++) {
                        const player = res.data[i]
                        if (player.total_point !== prevPoint) {
                            rank = i + 1
                            prevPoint = player.total_point
                        }
                        if (player.id === parseInt(this.$route.query.id)) {
                            this.rank = rank
                            break
                        }
                    }

                    // 같은 국가 순위 계산 
                    let same_country_list = res.data.filter(
                        e =>
                        e.player_country == this.player.player_country
                        && e.player_age_group == this.player.player_age_group
                    )

                    let prevPoint2 = null
                    rank = 1
                    for (let i = 0; i < same_country_list.length; i++) {
                        const player = same_country_list[i]
                        if (player.total_point !== prevPoint2) {
                            rank = i + 1
                            prevPoint2 = player.total_point
                        }
                        if (player.id === parseInt(this.$route.query.id)) {
                            this.same_country_rank = rank
                            break
                        }
                    }
                }
            })
        },

        // 플레이어 이번주 순위 찾기
        async loadPlayerWeekRank() {
            await this.$http.post("/api/official_league/player/select/recent_week", {
                params: {
                    player_country: this.player.player_country,
                    player_age_group: this.player.player_age_group,
                }
            })
            .then(async(res) => {
                // console.log(res)

                if(res.data.length){
                    this.week_rank = res.data.findIndex(player => player.id == this.$route.query.id) + 1
                    this.week_percent = this.calculateWeekRank(this.week_rank, res.data.length)
                    this.week_total = res.data.length
                }
            })
        },

        calculateWeekRank(rank, total) {
            if (total === 0) {
                return 0
            }

            // 상위 퍼센트 계산
            return ((rank / total) * 100).toFixed(4)
        },

        calculateWinRate(winCount, total) {
            // 전체 게임 횟수가 0인 경우, 승률을 계산할 수 없으므로 0을 반환
            if (winCount + total === 0) {
                return 0
            }

            // 승률 계산
            return ((winCount / (total)) * 100).toFixed(2)
        },

        // UID 연결신청 > 열기
        open_uid_apply(){
            if(!this.$store.state.is_logined){
                alert("UID 연결신청은 로그인 후 이용가능합니다")
            }else {
                this.dialog.uid_apply = true
            }
        },

        // UID 검색하기
        uid_search(){
            this.$http.post("/api/user_league_info/select/by_uid", {
                params: {
                    uid: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length){
                    this.notice = "해당 UID는 이미 연결이 되어있습니다"
                }else{
                    this.$http.post("/api/official_league/select/uid_search", {
                        params: {
                            uid: this.$route.query.id
                        }
                    }).then((res) => {
                        console.log(res)
                        if(res.data.length){
                            this.notice = res.data[0].title_kr + " (" + res.data[0].top_cut + ")"
                        }else{
                            this.notice = "정보가 없습니다"
                        }
                    })
                }
            })
        },

        // UID 연결신청 > SUBMIT
        uid_apply_submit(){
            if(!this.is_league){
                alert("UID 연결신청은 대회 정보 입력후 신청가능합니다")
                this.$router.push("/mypage/official_league")
                return
            }

            if(this.notice && this.notice.endsWith(')')){
                this.$http.post("/api/uid_apply/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        uid: this.$route.query.id
                    }
                }).then((res) => {
                    console.log(res)
                    if(res.data.affectedRows){
                        alert("UID 연결신청이 정상적으로 접수되었습니다")
                        this.dialog.uid_apply = false
                    }
                })
            } else {
                alert("해당 UID는 신청할 수 없습니다")
            }
        },

        computed_player_kr(item) {
            if(item.player_local){
                return item.player_local
            }
            else{
                if(item.player_country != "KR"){
                    return item.player_kr
                }else{
                    if(item.is_masking != null){
                        if(item.is_masking){
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }else{
                            return item.player_kr
                        }
                    }
                    else{
                        if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                            return item.player_kr
                        }else{
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(item.player_country != "KR"){
                return item.player_en
            }else{
                if(item.is_masking != null){
                    if(item.is_masking){
                        return item.player_en.split(' ')[0] + ' *'
                    }else{
                        return item.player_en
                    }
                }
                else{
                    if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                        return item.player_en
                    }else{
                        return item.player_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* 화살표 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

/* LABEL */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 2px 0 0 2px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:0px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .mobile .v-label{
    font-size:9px !important;
    padding-bottom:1px;
    letter-spacing: -1px;
}

::v-deep .mobile .v-input--selection-controls__input{
    margin: 0 !important;
    padding: 0 !important;
}

</style>
<style scoped>
.diamond-box {
    width: 28px;
    height: 28px;
    transform: rotate(45deg);
    border-radius: 5px;
}

.diamond-content {
    width: 28px;
    height: 28px;
    transform: rotate(-45deg);
    display: block;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>


<style scoped>
/* 테두리 색 변경 (성공) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:8px;
    height:33px !important;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
}

/* 날라가는 거 막는거 성공 (선택지 전체 높이) */
::v-deep .v-select__slot {
    height: 28px;
}

/* 날라가는 거 막는거 성공 */
::v-deep .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 9px;
}

/* 날라가는 거 막는거 성공 (PC) */
::v-deep .pc .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 14px;
    margin-left:4px;
}
</style>
<style scoped>
/* v-text-field, v-autocomplete > 값 > 가운데 정렬 */
::v-deep .uid_apply .v-text-field .v-input__slot input {
    text-align: center;
    margin-right:8px;
    margin-top:1px;
    font-weight:600;
}
</style>