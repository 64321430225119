<template>
    <div>
        <!-- 제목 & 기간(Global) -->
        <div
            class="d-flex align-center px-5 pt-3 pb-3 mobile"
        >
            <!-- 제목 -->
            <div>
                <p
                    style="font-size:26px; font-weight:bold; margin-bottom:4px; cursor:pointer;"
                    @click="$router.push('/official_league/deck_rank/list')"
                >
                    덱 순위
                    <span style="margin-left:4px; color:#F8468D; font-size:12px; vertical-align:super; font-weight:500;">BETA</span>
                </p>
                <p style="font-size:11px;" class="mb-0">메타별로 분류된 덱 순위 정보를 제공합니다</p>
            </div>
            <v-spacer></v-spacer>

            <v-btn
                depressed
                dark
                class="rounded-10"
                color="primary"
                width="80"
                height="40"
                @click="from && from.path == '/official_league/deck_rank/list'? $router.go(-1) : $router.push('/official_league/deck_rank/list')"
            >
                목록
            </v-btn>
        </div>

        <!-- 덱 정보 상단 -->
        <v-sheet
            class="pt-5 pb-4 px-4 d-flex align-center rounded-15"
            style="position:relative;"
        >
            <v-sheet
                style="position:relative;"
            >
                <v-sheet
                    width="260"
                    height="140"
                >
                    <v-img
                        v-if="main_pokemon"
                        class="rounded-t-20"
                        width="260"
                        height="140"
                        position="center 14%"
                        :src="view_image(main_pokemon)"
                    ></v-img>
                </v-sheet>

                <!-- 티어 -->
                <div
                    style="position:absolute; bottom:0px; right:-20px; z-index:10; width:40px; height:56px;"
                    class="d-flex align-center justify-center"
                >
                    <div
                        v-if="deck"
                        class="diamond-box"
                        :style="calculateTierColor(calculateTier(deck))"
                    >
                        <div
                            class="diamond-content d-flex justify-center align-center"
                            style="color:white; font-size:16px; font-weight:bold;"
                        >
                            {{calculateTier(deck)}}
                        </div>
                    </div>
                </div>
            </v-sheet>

            <div
                v-if="deck"
                class="ml-6"
            >
                <p class="mb-0 text-h6 font-weight-bold">{{deck_name_translate($route.query.id)}}</p>
                <p class="mb-0 grey--text" style="margin-top:-2px !important; margin-left:1px;">{{deck.en_name}}</p>
                <p class="mb-0">
                    <span class="grey--text text-body-2">{{calculateTier(deck)}}티어</span>
                    <span class="ml-4 grey--text text-body-2">{{deck.top_cut}}</span>
                </p>

                <!-- # 승점 & 점유율 & 최고성적 -->
                <div
                    class="d-flex ml-4 mt-3"
                >
                    <div>
                        <!-- # 승점 -->
                        <p
                            class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                        >
                            승점
                        </p>
                        <p
                            class="font-weight-bold text-subtitle-1 mb-0 mt-0"
                        >
                            {{deck.total_point}}점
                        </p>
                    </div>

                    <div
                        style="background:#eeeeee; width:1px; height:40px; margin:4px 24px;"
                    ></div>

                    <div>
                        <!-- # 점유율 -->
                        <p
                            class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                        >
                            점유율
                        </p>
                        <p
                            class="font-weight-bold text-subtitle-1 mb-0 mt-0"
                        >
                            {{calculateProbability(deck.deck_count, total_count)}}%
                        </p>
                    </div>

                    <div
                        style="background:#eeeeee; width:1px; height:40px; margin:4px 24px;"
                    ></div>

                    <div>
                        <!-- # 최고성적 -->
                        <p
                            class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                        >
                            최고성적
                        </p>
                        <p
                            class="font-weight-bold text-subtitle-1 mb-0 mt-0"
                        >
                            {{deck.top_cut}}/{{deck.total}}명
                        </p>
                    </div>
                </div>
            </div>

            <!-- 기간 -->
            <div
                style="position:absolute; top:20px; right:24px;"
            >
                <!-- SNS & 국기 -->
                <div
                    class="d-flex align-center justify-end"
                >
                    <!-- 기간 -->
                    <v-select
                        style="width:160px; font-size:15px !important;"
                        class="rounded-10 font-weight-bold shrink ml-2"
                        dense
                        outlined
                        hide-details
                        :items="select_list.term"
                        v-model="term"
                        @change="componentKey+=1"
                    ></v-select>

                    <!-- 파생 덱 넘나들기 -->
                    <v-autocomplete
                        style="width:160px; font-size:15px !important;"
                        class="rounded-10 font-weight-bold shrink ml-2"
                        dense
                        outlined
                        hide-details
                        item-text="kr"
                        item-value="name"
                        :items="select_list.variant_deck"
                        v-model="variant_deck"
                        @change="$router.push('/official_league/deck_rank/detail?id='+variant_deck)"
                        :key="variantKey"
                    ></v-autocomplete>
                </div>
            </div>

            <div
                style="position:absolute; bottom:20px; right:12px;"
            >
                <div
                    v-if="deck && deck.deck_icons"
                    class="d-flex mr-3 align-end"
                >
                    <v-img
                        v-for="icon in deck.deck_icons.split(',')" :key="icon"
                        :width="$vuetify.breakpoint.mobile? 28 : 34"
                        :height="$vuetify.breakpoint.mobile? 28 : 34"
                        contain
                        class="mb-1 mx-1"
                        style="opacity:1"
                        :src="'/upload/pokesprite/'+icon+'.png'"
                    ></v-img>
                </div>
            </div>
        </v-sheet>

        <!-- 티어 추이 -->
        <TierChart
            :key="graphKey"
        />

        <!-- 대회 기록 -->
        <RaceRecord
            :global_term="term"
            :key="componentKey + 'Race'"
        />

        <!-- 플레이어 -->
        <PlayerRecord
            :global_term="term"
            :key="componentKey + 'Player'"
        />

        <!-- 파생 덱 -->
        <VariantDeck
            :global_term="term"
            :key="componentKey + 'Variant'"
        />
    </div>
</template>
<script>
import TierChart from "./components/TierChart"
import RaceRecord from "./components/RaceRecord"
import PlayerRecord from "./components/PlayerRecord"
import VariantDeck from "./components/VariantDeck"

export default {
    props: [
        "from"
    ],

    components: {
        TierChart,
        RaceRecord,
        PlayerRecord,
        VariantDeck
    },

    data: () => ({
        select_list: {
            term: [
                "전체",
                "최근 12개월",
                "최근 9개월",
                "최근 6개월",
                "최근 3개월",
                "최근 1개월",
            ],
            
            variant_deck: []
        },

        term: "최근 3개월",

        deck: {},
        list: [],

        deck_auto_translate_list: [],

        total_count: 0,

        loading: false,
        componentKey: 0,
        graphKey: 0,
        main_pokemon: {},

        variant_deck: "",
        variantKey: 0,
    }),

    // # 새로 못 불러오는 문제 해결
    watch: {
        '$route': {
            handler: 'fetchData',
            immediate: true
        },

        term(){
            this.fetchData()
        }
    },

    methods: {
        async fetchData() {
            this.graphKey++
            
            // 덱 정보 불러오기
            await this.loadDeckTier()
            
            // 덱분류값 번역 목록 불러오기
            await this.load_deck_auto_translate_list()

            // # Variant 덱인지 확인
            await this.isVariantDeck()

            // # 파생 덱 불러오기
            await this.loadVariantDeck()

            this.$emit('route_changed')
        },

        // # Variant 덱인지 확인
        async isVariantDeck(){
            await this.$http.post("/api/official_league/deck_rank/select/is_variant", {
                params: {
                    identifier: this.$route.query.id,
                }
            }).then(async(res) => {
                console.log("is_variant", res.data)
                if(res.data.length){
                    this.is_variant = true
                    this.upper_deck = res.data[0].identifier
                    console.log("파생덱임")
                }else{
                    this.is_variant = false
                    console.log("파생덱 아님")
                }
            })
        },

        // # 파생 덱 불러오기
        async loadVariantDeck(){
            // # 현재 덱이 파생 덱이 아닌 경우
            if(!this.is_variant){
                await this.$http.post("/api/official_league/deck_rank/select/variant_deck", {
                    params: {
                        identifier: this.$route.query.id,
                        term: "전체"
                    }
                }).then(async(res) => {
                    console.log("파생 덱1", res.data)
                    if(res.data.length){
                        this.select_list.variant_deck = res.data.map(e => ({
                            name: e.deck_identifier1,
                            kr: this.deck_name_translate(e.deck_identifier1)
                        }))
                    }

                    this.select_list.variant_deck.unshift({
                        name: this.$route.query.id,
                        kr: this.deck_name_translate(this.$route.query.id)
                    })
                    this.variant_deck = this.$route.query.id
                })
            }

            // # 현재 덱이 파생 덱인 경우
            else if(this.is_variant){
                await this.$http.post("/api/official_league/deck_rank/select/variant_deck", {
                    params: {
                        identifier: this.upper_deck,
                        term: "전체"
                    }
                }).then(async(res) => {
                    console.log("파생 덱2", res.data)
                    if(res.data.length){
                        // this.select_list.variant_deck = await res.data.map(e => e.deck_identifier1)

                        this.select_list.variant_deck = res.data.map(e => ({
                            name: e.deck_identifier1,
                            kr: this.deck_name_translate(e.deck_identifier1)
                        }))

                        this.select_list.variant_deck.unshift({
                            name: this.upper_deck,
                            kr: this.deck_name_translate(this.upper_deck)
                        })

                        // console.log(this.select_list.variant_deck)
                        // console.log(this.upper_deck)

                        // # upper 덱 앞에 추가
                        // await this.$http.post("/api/official_league/deck_rank/select/upper_deck", {
                        //     params: {
                        //         identifier: this.upper_deck,
                        //         term: "전체"
                        //     }
                        // }).then(async(res2) => {
                        //     console.log("upper_deck", res2.data)
                        //     if(res2.data.length){
                        //         this.select_list.variant_deck.unshift(res2.data[0].deck_identifier1)
                        //     }
                        // })
                    }

                    // this.select_list.variant_deck = this.select_list.variant_deck.map((name, index) => ({
                    //     name,
                    //     kr: this.deck_name_translate(name)
                    // }))

                    this.variant_deck = this.$route.query.id
                })
            }

            this.variantKey++
        },

        // ! 덱 정보 불러오기 (동기식 없애도 되는지 체크해서 추후 속도 최적화 필요)
        async loadDeckTier(){
            this.loading = true
            this.deck = {}
            await this.$http.post("/api/official_league/deck_rank/select/detail/header", {
                params: {
                    term: this.term
                }
            }).then(async(res) => {
                // console.log("덱순위", res.data)
                this.deck = res.data.filter(e => e.deck_name == this.$route.query.id)[0]
                this.componentKey++

                this.list = res.data

                // res.data에서 deck_count 값의 합산을 구해 this.total_count에 대입
                this.total_count = res.data.reduce((sum, item) => sum + item.deck_count, 0)

                // 메인포켓몬 찾기
                await this.$http.post("/api/official_league/select/main_pokemon/by_identifier", {
                    params: {
                        identifier: this.$route.query.id
                    }
                }).then(async (res) => {
                    // console.log(res)
                    if(res.data.length){
                        await this.loadDeckCardList(res.data[0].deck_card_list1, res.data[0].main_pokemon)
                    }
                })

                // 최고성적, 최대인원 가져오기
                await this.$http.post("/api/official_league/deck_rank/select/detail/best_record", {
                    params: {
                        term: this.term,
                        identifier: this.$route.query.id
                    }
                }).then((res) => {
                    if(res.data.length){
                        // console.log("min", res.data)

                        // standing 값이 가장 작은 row 찾기
                        const minStandingRow = res.data.reduce((prev, curr) => {
                            return prev.standing < curr.standing ? prev : curr
                        })

                        // top_cut과 total 값을 대입
                        this.deck.top_cut = minStandingRow.top_cut
                        this.deck.total = minStandingRow.total

                        this.componentKey++
                    }
                })

                this.loading = false
            })
        },

        // 덱 카드 목록 불러오기
        async loadDeckCardList(deck_card_list, main_pokemon){
            let card_list = []
            let imported_card_list = await JSON.parse(deck_card_list)

            for(let i=0; i<imported_card_list.length;i++){
                await this.$http.post("/api/new/deck/select/viewDeck", {
                    params: {
                        card_id: imported_card_list[i].id,
                        amount: imported_card_list[i].amount,
                    }
                }).then(async(res) => {
                    if(res.data.length){
                        // console.log("card_list", res)
                        await card_list.push(res.data[0])
                    }
                })
            }

            this.main_pokemon = card_list.filter(e => e.name_en == main_pokemon)[0]
        },

        // 덱분류값 번역 목록 불러오기
        async load_deck_auto_translate_list(){
            // await this.$http.get('/api/admin/league/deck_auto_translate_list/list')
            // .then(async (res) => {
            //     console.log(res)
            //     this.deck_auto_translate_list = res.data
            // })
            try {
                const res = await this.$http.get('/api/admin/league/deck_auto_translate_list/list')
                console.log(res)
                this.deck_auto_translate_list = res.data
            } catch (error) {
                console.error(error)
            }
        },

        // # 덱분류값 한글로 번역
        deck_name_translate(en) {
            if(!en) return 
            let translation = this.deck_auto_translate_list.find(item => item.en === en)
            return translation ? (translation.kr || en) : en
        },

        // 점유율 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        },

        // 티어 TO 백분위 계산
        calculatePercentile(list, item) {
            const sortedList = [...list].sort((a, b) => b.total_point - a.total_point)
            const index = sortedList.findIndex(i => i.deck_name === item.deck_name)
            return (index / sortedList.length) * 100
        },

        // 티어 계산
        calculateTier(item){
            let rank_tier
            let point_tier = 5
            let usage_tier = 5

            // 백분위 계산
            const percentile = this.calculatePercentile(this.list, item)

            // 백분위에 따른 티어 계산
            if (percentile <= 6) {
                rank_tier = 1
            } else if (percentile <= 22) { 
                rank_tier = 2
            } else if (percentile <= 42) {
                rank_tier = 3
            } else if (percentile <= 69) {
                rank_tier = 4
            } else {
                rank_tier = 5
            }

            // 승점 티어
            if(this.term == "전체" || this.term == "최근 12개월"){
                if(item.total_point >= 2000){
                    point_tier = 1
                }
                else if(item.total_point >= 1000 && item.total_point < 2000){
                    point_tier = 2
                }
                else if(item.total_point >= 500 && item.total_point < 1000){
                    point_tier = 3
                }
                else if(item.total_point >= 300 && item.total_point < 500){
                    point_tier = 4
                }
                else if(item.total_point < 300){
                    point_tier = 5
                }
            }
            else if(this.term == "최근 6개월"){
                if(item.total_point >= 1000){
                    point_tier = 1
                }
                else if(item.total_point >= 500 && item.total_point < 1000){
                    point_tier = 2
                }
                else if(item.total_point >= 250 && item.total_point < 500){
                    point_tier = 3
                }
                else if(item.total_point >= 150 && item.total_point < 250){
                    point_tier = 4
                }
                else if(item.total_point < 150){
                    point_tier = 5
                }
            }
            else if(this.term == "최근 3개월"){
                if(item.total_point >= 500){
                    point_tier = 1
                }
                else if(item.total_point >= 250 && item.total_point < 500){
                    point_tier = 2
                }
                else if(item.total_point >= 125 && item.total_point < 250){
                    point_tier = 3
                }
                else if(item.total_point >= 75 && item.total_point < 125){
                    point_tier = 4
                }
                else if(item.total_point < 75){
                    point_tier = 5
                }
            }
            else if(this.term == "최근 1개월"){
                if(item.total_point >= 167){
                    point_tier = 1
                }
                else if(item.total_point >= 84 && item.total_point < 167){
                    point_tier = 2
                }
                else if(item.total_point >= 42 && item.total_point < 84){
                    point_tier = 3
                }
                else if(item.total_point >= 25 && item.total_point < 42){
                    point_tier = 4
                }
                else if(item.total_point < 25){
                    point_tier = 5
                }
            }

            // 점유율 티어
            if(this.calculateProbability(item.deck_count, this.total_count) >= 5){
                usage_tier = 1
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) >= 1 && this.calculateProbability(item.deck_count, this.total_count) < 5){
                usage_tier = 2
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) >= 0.1 && this.calculateProbability(item.deck_count, this.total_count) < 1){
                usage_tier = 3
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) >= 0.05 && this.calculateProbability(item.deck_count, this.total_count) < 0.1){
                usage_tier = 4
            }
            else if(this.calculateProbability(item.deck_count, this.total_count) < 0.05){
                usage_tier = 5
            }

            // return usage_tier

            // console.log(rank_tier)

            if(Math.max(point_tier, usage_tier) == 1){
                if(rank_tier != 1){
                    return rank_tier
                }
                else{
                    return 1
                }
            }
            else if(Math.max(point_tier, usage_tier) == 2){
                if(rank_tier != 2){
                    return rank_tier
                }
                else{
                    return 2
                }
            }
            else if(Math.max(point_tier, usage_tier) == 3){
                if(rank_tier != 3){
                    return rank_tier
                }
                else{
                    return 3
                }
            }
            else if(Math.max(point_tier, usage_tier) == 4){
                if(rank_tier != 4){
                    return rank_tier
                }
                else{
                    return 4
                }
            }
            else if(Math.max(point_tier, usage_tier) == 5){
                if(rank_tier != 5){
                    return rank_tier
                }
                else{
                    return 5
                }
            }
            else{
                return 5
            }
        },

        // 티어 색상 계산
        calculateTierColor(tier){
            if(tier == 1) return "background:#843CFF;"
            else if(tier == 2) return "background:#0093FF;"
            else if(tier == 3) return "background:#00BBA3;"
            else if(tier == 4) return "background:#FFB900;"
            else if(tier == 5) return "background:#B3B3C2;"
        },

        // 메인포켓몬 이미지 찾기
        load_main_pokemon_image(){
            this.$http.post("/api/official_league/select/main_pokemon_image", {
                params: {
                    identifier: this.$route.query.id
                }
            }).then((res) => {
                this.main_pokemon_image = res.data[0]
            })
        },

        // 이미지 표시
        view_image(item){
            // if(!item.image_kr || item.image_kr=='default.png'){
            //     if(item.image_en!=null && item.image_en!='default.png'){
            //         return '/upload/card_en_image/'+encodeURI(item.image_en)
            //     }
            //     else if(item.image_jp!=null || item.image_jp!='default.png'){
            //         return '/upload/card_jp_image/'+encodeURI(item.image_jp)
            //     }
            //     else {
            //         return "@/assets/default.png"
            //     }
            // }
            // else if(item.image_kr && item.image_kr!='default.png'){
            //     return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            // }
            let nation = "kr"

            if(nation == "kr"){
                if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }

            else if(nation == "jp")
            {
                if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }

            else if(nation == "en")
            {
                if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

/* 파생 덱 넘나들기 */
::v-deep .v-select input {
    font-weight:bold;
    margin-left:4px;
    font-size: 14px;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* 화살표 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

/* LABEL */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 2px 0 0 2px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:0px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .mobile .v-label{
    font-size:9px !important;
    padding-bottom:1px;
    letter-spacing: -1px;
}

::v-deep .mobile .v-input--selection-controls__input{
    margin: 0 !important;
    padding: 0 !important;
}

</style>
<style scoped>
.diamond-box {
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    border-radius: 5px;
}

.diamond-content {
    width: 40px;
    height: 40px;
    transform: rotate(-45deg);
    display: block;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>