<template>
    <div>
        <div
            class="d-flex justify-center pa-4"
            style="position:relative;"
        >
            <Chart
                :width="$vuetify.breakpoint.mobile? 300 : 400"
                :height="$vuetify.breakpoint.mobile? 300 : 400"
                :list="list"
                :key="componentKey"
            />

            <!-- 이미지 -->
            <v-img
                class="rounded-10"
                height="100"
                width="100"
                :style="{ position: 'absolute', zIndex: 999, marginTop: $vuetify.breakpoint.mobile ? '97px' : '144px' }"
                :src="'/upload/official_league/' + image"
            ></v-img>

        </div>

        <!-- # PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 결과 -->
            <v-sheet
                height="40"
                color="#F0F3F5"
                class="pt-1 d-flex"
            >
                <div
                    style="width:400px; font-size:14px;"
                    class="text-center mt-1 flex-grow-1"
                >
                    덱
                </div>

                <div
                    style="width:200px; font-size:14px;"
                    class="text-center mt-1"
                >
                    참가수
                </div>
                <!-- <div
                    style="width:180px; font-size:14px;"
                    class="text-center mt-1"
                >
                    점유율
                </div>
                <div
                    style="width:200px; font-size:14px;"
                    class="text-center mt-1"
                >
                    승점
                </div> -->

                <!-- # 정렬 -->
                <div
                    :class="sequence == '점유율'? 'primary--text':''"
                    class="text-center mt-1 pl-2"
                    style="width:180px; font-size:14px; position:relative; cursor:pointer;"
                    @click="sequence == '점유율'? (sequence = '점유율', align_usage = !align_usage) : sequence = '점유율'"
                >
                    점유율
                    <v-icon
                        size="20"
                        class="mb-2px"
                        :color="sequence == '점유율'? 'primary':''"
                    >
                        {{align_usage? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '점유율'"
                        class="primary mx-auto"
                        style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>
                <div
                    :class="sequence == '승점'? 'primary--text':''"
                    class="text-center mt-1 pl-2"
                    style="width:200px; font-size:14px; position:relative; cursor:pointer;"
                    @click="sequence == '승점'? (sequence = '승점', align_point = !align_point) : sequence = '승점'"
                >
                    승점
                    <v-icon
                        size="20"
                        class="mb-2px"
                        :color="sequence == '승점'? 'primary':''"
                    >
                        {{align_point? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '승점'"
                        class="primary mx-auto"
                        style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>
            </v-sheet>
            <div
                class="pb-4"
                :key="componentKey+'list'"
            >
                <div
                    v-for="(item, index) in list" :key="index"
                    class="d-flex align-center py-1"
                    style="border-bottom:1px solid #eee; cursor:pointer;"
                    @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier)"
                >
                    <div
                        style="width:400px;"
                        class="d-flex align-center flex-grow-1"
                    >
                        <!-- 덱 아이콘 -->
                        <div
                            style="font-size:10px; padding:0 24px;"
                            class="text-center d-flex align-center"
                        >
                            <div
                                v-if="item.deck_icons"
                                class="d-flex justify-center align-center mt-1"
                                style="width:100px;"
                            >
                                <v-img
                                    v-for="icon in item.deck_icons.split(',')" :key="icon"
                                    :width="$vuetify.breakpoint.mobile? 28 : 34"
                                    :height="$vuetify.breakpoint.mobile? 28 : 34"
                                    contain
                                    class="mb-1 mx-1"
                                    style="opacity:1"
                                    :src="'/upload/pokesprite/'+icon+'.png'"
                                ></v-img>
                            </div>
                        </div>
                        <p
                            style="width:260px;"
                            class="mb-0 font-weight-medium"
                        >
                            {{item.deck_kr? item.deck_kr : item.deck_en}}
                        </p>
                    </div>

                    <!-- 참가수 -->
                    <div
                        style="width:200px;"
                        class="text-center"
                    >
                        {{item.deck_count}}
                    </div>

                    <!-- 점유율 -->
                    <div
                        style="width:180px; position:relative;"
                        class="text-center"
                    >
                        {{calculateProbability(item.deck_count, list_deck_sum)}}%

                        <v-progress-linear
                            style="position:absolute; width:100%; bottom:-13px; right:0px; z-index:5;"
                            height="2"
                            color="#F8468D"
                            background-color="grey lighten-3"
                            :value="calculateProbability(item.deck_count, list_deck_sum)"
                        ></v-progress-linear>
                    </div>

                    <!-- 승점 -->
                    <div
                        style="width:200px;"
                        class="text-center"
                    >
                        {{item.deck_point}}
                    </div>
                </div>
            </div>
        </div>

        <!-- # Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 결과 -->
            <v-sheet
                height="40"
                color="#F0F3F5"
                class="pt-1 d-flex"
            >
                <div
                    style="width:50%; font-size:12px;"
                    class="text-center mt-1 flex-grow-1"
                >
                    덱
                </div>

                <div
                    style="width:20%; font-size:12px;"
                    class="text-center mt-1"
                >
                    참가수
                </div>

                <!-- # 정렬 -->
                <div
                    :class="sequence == '점유율'? 'primary--text':''"
                    class="text-center mt-1"
                    style="width:20%; font-size:12px; position:relative; cursor:pointer;"
                    @click="sequence == '점유율'? (sequence = '점유율', align_usage = !align_usage) : sequence = '점유율'"
                >
                    점유율
                    <v-icon
                        size="20"
                        class="mb-2px"
                        style="margin-left:-4px;"
                        :color="sequence == '점유율'? 'primary':''"
                    >
                        {{align_usage? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '점유율'"
                        class="primary mx-auto"
                        style="height:2px; width:100%; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>
                <div
                    :class="sequence == '승점'? 'primary--text':''"
                    class="text-center mt-1"
                    style="width:20%; font-size:12px; position:relative; cursor:pointer;"
                    @click="sequence == '승점'? (sequence = '승점', align_point = !align_point) : sequence = '승점'"
                >
                    승점
                    <v-icon
                        size="20"
                        class="mb-2px"
                        style="margin-left:-4px;"
                        :color="sequence == '승점'? 'primary':''"
                    >
                        {{align_point? 'mdi-chevron-up':'mdi-chevron-down'}}
                    </v-icon>
                    <div
                        v-if="sequence == '승점'"
                        class="primary mx-auto"
                        style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                    ></div>
                </div>
            </v-sheet>
            <div
                class="pb-4"
                :key="componentKey+'list'"
            >
                <div
                    v-for="(item, index) in list" :key="index"
                    class="d-flex align-center py-1"
                    style="border-bottom:1px solid #eee; cursor:pointer;"
                    @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier)"
                >
                    <div
                        style="width:50%;"
                        class="d-flex align-center flex-grow-1 text-truncate"
                    >
                        <!-- 덱 아이콘 -->
                        <div
                            style="font-size:10px; padding:0 4px;"
                            class="d-flex align-center"
                        >
                            <div
                                v-if="item.deck_icons"
                                class="d-flex justify-center align-center mt-1"
                            >
                                <v-img
                                    v-for="icon in item.deck_icons.split(',')" :key="icon"
                                    :width="$vuetify.breakpoint.mobile? 20 : 34"
                                    :height="$vuetify.breakpoint.mobile? 20 : 34"
                                    contain
                                    class="mb-1 mx-2px"
                                    style="opacity:1"
                                    :src="'/upload/pokesprite/'+icon+'.png'"
                                ></v-img>
                            </div>
                        </div>
                        <p
                            class="mb-0 font-weight-medium text-caption"
                        >
                            {{item.deck_kr? item.deck_kr : item.deck_en}}
                        </p>
                    </div>

                    <!-- 참가수 -->
                    <div
                        style="width:20%; font-size:12px;"
                        class="text-center"
                    >
                        {{item.deck_count}}
                    </div>

                    <!-- 점유율 -->
                    <div
                        style="width:20%; position:relative; font-size:12px;"
                        class="text-center"
                    >
                        {{calculateProbability(item.deck_count, list_deck_sum)}}%

                        <v-progress-linear
                            style="position:absolute; width:100%; bottom:-10px; right:0px; z-index:5;"
                            height="2"
                            color="#F8468D"
                            background-color="grey lighten-3"
                            :value="calculateProbability(item.deck_count, list_deck_sum)"
                        ></v-progress-linear>
                    </div>

                    <!-- 승점 -->
                    <div
                        style="width:20%; font-size:12px;"
                        class="text-center"
                    >
                        {{item.deck_point}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Chart from './Decks/Chart'

export default {
    components: {
        Chart
    },

    props: [
        "image"
    ],

    data: () => ({
        menu: "덱 레시피",
        list: [],
        list_deck_sum: 0,
        deck_list_for_card: [],
        card_list: [],
        componentKey: 0,
        sequence: "점유율",
        align_usage: false,
        align_point: false,
    }),

    watch: {
        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_usage(){
            this.sortList()
        },

        // 순서
        align_point(){
            this.sortList()
        }
    },

    mounted(){
        // 덱 레시피 목록 불러오기
        this.load_deck_list()
    },

    methods: {
        // 순서
        sortList(){
            if(this.sequence == "점유율")
            {
                this.list = this.list.sort((a, b) => b.deck_count - a.deck_count)
                if(this.align_usage){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "승점")
            {
                this.list = this.list.sort((a, b) => b.deck_point - a.deck_point)
                if(this.align_point){
                    this.list = this.list.reverse()
                }
            }

            // 전체 합
            this.list_deck_sum = this.list.reduce((acc, item) => acc + item.deck_count, 0)
            console.log(this.list)

            this.componentKey++
        },

        // 덱 레시피 목록 불러오기
        load_deck_list(){
            this.$http.post("/api/official_league/select/statistics/deck_recipe", {
                params: {
                    league_id: this.$route.query.id
                }
            }).then((res) => {
                console.log(res.data)
                const mergedList = res.data.reduce((acc, item) => {
                    const existingItem = acc.find(e => e.deck_identifier === item.deck_identifier)
                    if (existingItem) {
                        existingItem.deck_count += item.deck_count
                    } else {
                        acc.push(item)
                    }
                    return acc
                }, [])
                this.list = mergedList.filter(e => e.deck_identifier != null)

                // 참가수 (점유율) 높은 순 정렬
                this.list = this.list.sort((a, b) => b.deck_count - a.deck_count)

                // 전체 합
                this.list_deck_sum = this.list.reduce((acc, item) => acc + item.deck_count, 0)
                console.log(this.list)

                this.componentKey++
            })
        },

        // % 계산
        calculateProbability(count, totalNumber) {
            if (count == null || totalNumber == null || isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0 // 확률을 계산할 수 없으므로 0%로 표시
            }

            const probability = (count / totalNumber) * 100 // 확률 계산 (백분율로 표시)
            return parseInt(probability) // 소수점 두 자리까지 표시
        }
    }
}
</script>
<style>
.deckTable {
    width:100%;
    border:1px solid #eee;
}

.deckTable tr td{
    height:20px;
    border:1px solid #eee;
    padding: 0;
    
}
</style>