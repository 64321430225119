<template>
    <div>
        <!-- 제목 & 기간(Global) -->
        <div
            class="d-flex align-center pl-5 pt-3 pb-3"
        >
            <!-- 제목 -->
            <p
                style="font-size:26px; font-weight:bold;"
                class="mb-0"
            >
                플레이어 목록
                <span style="color:#F8468D; font-size:10px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                class="ml-2"
                indeterminate
                color="primary"
                size="18"
                width="2"
            ></v-progress-circular>

            <v-spacer></v-spacer>

            <!-- 메뉴 -->
            <v-sheet class="mt-1 pt-1 px-9 rounded-15 d-flex justify-space-between">
                <v-btn
                    height="56"
                    class="px-0 mr-8"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="$route.path.startsWith('/league/list')? 'primary':''"
                    :style="$route.path.startsWith('/league/list')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                    :dark="$route.path.startsWith('/league/list')"
                >
                    대회
                </v-btn>
                <v-btn
                    height="56"
                    class="px-0"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="$route.path.startsWith('/official_league/player')? 'primary':''"
                    :style="$route.path.startsWith('/official_league/player')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                    :dark="$route.path.startsWith('/official_league/player')"
                >
                    공식대회
                </v-btn>
            </v-sheet>
        </div>

        <!-- 기간 & 지역 -->
        <div
            class="px-4 py-3 mb-2 rounded-10 white"
        >
            <div
                class="d-flex"
            >
                <!-- 기간 -->
                <v-select
                    style="flex: 1; width:108px;"
                    class="rounded-10 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    label="기간"
                    placeholder="기간"
                    persistent-placeholder
                    :items="select_list.date"
                    v-model="keyword.date"
                    @change="search()"
                >
                    <template v-slot:selection="{ item }">
                        <span class="text-truncate mt-1" style="display:inline-block;">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 지역 -->
                <v-select
                    style="flex: 1"
                    class="rounded-10 font-weight-bold ml-2"
                    dense
                    outlined
                    hide-details
                    label="지역"
                    placeholder="지역"
                    persistent-placeholder
                    :items="select_list.continent"
                    v-model="keyword.continent"
                    @change="search()"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2 pb-1px">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </div>

            <!-- 연령 구분 & 이름 -->
            <div
                class="d-flex mt-3"
            >
                <!-- 연령 구분 -->
                <v-select
                    style="flex: 1;"
                    class="rounded-10 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    label="연령 구분"
                    placeholder="연령 구분"
                    persistent-placeholder
                    item-text="name"
                    item-value="value"
                    :items="select_list.age_group"
                    v-model="keyword.age_group"
                    @change="search()"
                >
                    <template v-slot:selection="{ item }">
                        <span class="text-truncate mt-1" style="display:inline-block;">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="mr-1 mt-1" color="#424361" style="margin-bottom:-22px;">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 이름 -->
                <v-text-field
                    style="flex: 1;"
                    class="rounded-10 font-weight-bold ml-2"
                    dense
                    outlined
                    hide-details
                    label="이름"
                    placeholder="이름"
                    persistent-placeholder
                    v-model="keyword.name"
                    @change="search()"
                >
                    <template v-slot:selection="{ item }">
                        <span class="text-truncate mt-1" style="display:inline-block;">{{item}}</span>
                    </template>
                </v-text-field>
            </div>
        </div>

        <v-sheet
            class="rounded-10 my-2 py-1 mx-4"
            style="border:1px solid #23D2E2;"
        >
            <p
                class="text-caption text-center mb-0"
                style="letter-spacing: 0px !important;"
            >
                티어는 연령/국가 기준입니다 → 대한민국 주니어의 레전드 티어
            </p>
        </v-sheet>

        <!-- 목록 상단 -->
        <v-sheet
            height="40"
            color="#F0F3F5"
            class="pt-2 d-flex"
        >
            <div
                style="width:10%; font-size:14px;"
                class="text-center"
            >
                #
            </div>
            
            <div
                style="width:15%; font-size:14px;"
                class="text-center"
            >
                티어
            </div>
            <div
                style="width:45%; font-size:14px;"
                class="text-center"
            >
                플레이어
            </div>
            <div
                style="width:15%; font-size:14px;"
                class="text-center"
            >
                국가
            </div>
            <!-- <div
                style="width:15%; font-size:14px;"
                class="text-center"
            >
                승점
            </div> -->

            <!-- 승점 -->
            <div
                :class="sequence == '승점'? 'primary--text':''"
                class="text-center pl-2"
                style="width:15%; font-size:14px; position:relative; cursor:pointer;"
                @click="sequence == '승점'? (sequence = '승점', align_point = !align_point) : sequence = '승점'"
            >
                승점
                <v-icon
                    size="20"
                    class="mb-2px"
                    :color="sequence == '승점'? 'primary':''"
                >
                    {{align_point? 'mdi-chevron-up':'mdi-chevron-down'}}
                </v-icon>
                <div
                    v-if="sequence == '승점'"
                    class="primary mx-auto"
                    style="height:2px; width:90px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                ></div>
            </div>
        </v-sheet>

        <!-- 목록 -->
        <div
            v-if="!loading"
        >
            <v-sheet
                v-for="(item, index) in currentPageList" :key="item.id"
                height="56"
                class="d-flex align-center ma-0 pa-0"
                style="position:relative; cursor:pointer;"
                @click="$router.push('/official_league/player/detail?id='+item.id)"
            >
                <!-- # -->
                <div
                    style="width:10%; font-size:14px; height:56px; border-bottom:1px solid #C8C8C8;"
                    class="text-center d-flex align-center justify-center"
                >
                    {{align_point? (list.length - (index + 1 + (page-1) * 20) + 1) : (index + 1 + (page-1) * 20)}}
                </div>

                <!-- 티어 -->
                <div
                    style="width:15%; font-size:12px;height:56px; border-bottom:1px solid #C8C8C8;"
                    class="d-flex align-center justify-center"
                >
                    <!-- 등급 -->
                    <v-sheet
                        v-if="!is_name_search"
                        width="40"
                        height="30"
                    >
                        <v-img
                            v-if="item.tier"
                            contain
                            :src="require(`@/assets/official_league/player/${item.tier}.png`)"
                        ></v-img>
                    </v-sheet>

                    <!-- 등급 -->
                    <v-sheet
                        v-if="is_name_search"
                        width="40"
                        height="30"
                    >
                        <v-img
                            width="40"
                            height="30"
                            contain
                            src="@/assets/logos/icon_grey.svg"
                        ></v-img>
                    </v-sheet>
                </div>

                <!-- 플레이어 -->
                <div
                    style="width:45%; font-size:14px; height:56px; border-bottom:1px solid #C8C8C8; font-weight:500;"
                    class="d-flex align-center"
                >
                    <div
                        v-if="!item.player_local"
                        class="ml-4"
                    >
                        <p
                            style="font-size:14px; font-weight:600; margin-bottom:-2px;"
                        >
                            {{computed_player_kr(item)}}
                            <span class="ml-2" :style="$age_group_color(item.player_age_group)">{{item.player_age_group}}</span>
                        </p>
                        <p
                            style="font-size:12px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                        >
                            {{computed_player_en(item)}}
                        </p>
                    </div>
                    <div
                        v-else
                        class="ml-4"
                    >
                        <p
                            style="font-size:14px; font-weight:600; margin-bottom:0px;"
                        >
                            {{item.player_local}}
                            <span class="ml-2" :style="$age_group_color(item.player_age_group)">{{item.player_age_group}}</span>
                        </p>
                    </div>
                </div>

                <!-- 국가 -->
                <div
                    style="width:15%; font-size:14px; height:56px; border-bottom:1px solid #C8C8C8;"
                    class="text-center d-flex align-center justify-center"
                >
                    <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                        <template v-slot:activator="{ on, attrs }">
                            <v-sheet
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-img
                                    v-if="item.player_country"
                                    class="rounded-5"
                                    width="32"
                                    height="28"
                                    style="box-shadow:0px 2px 4px #ddd;"
                                    :src="'/upload/flags/'+item.player_country.toLowerCase()+'.svg'"
                                ></v-img>
                            </v-sheet>
                        </template>
                        <div class="text-center pa-1">
                            {{$country_name(item.player_country, country_list)}}
                        </div>
                    </v-tooltip>
                </div>

                <!-- RP -->
                <div
                    style="width:15%; font-size:14px; height:56px; border-bottom:1px solid #C8C8C8; background:#FAFAFA;"
                    class="text-center d-flex align-center justify-center font-weight-bold"
                >
                    {{$toComma(item.total_point)}}
                </div>
            </v-sheet>
        </div>

        <!-- 검색결과가 없을 시 -->
        <v-row no-gutters v-if="!loading && !currentPageList.length">
            <v-col class="py-16 text-center">
                <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                <p class="blue--text mb-1">검색 결과가 없습니다</p>
                <p class="text-caption">마스킹된 선수는 검색이 되지 않습니다</p>
            </v-col>
        </v-row>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            indeterminate
            class="d-flex mx-auto my-10"
            color="primary"
            size="80"
        ></v-progress-circular>

        <div
            class="d-flex align-center justify-space-between mt-2"
        >
            <div
                style="width:100px;"
            ></div>

            <!-- 버튼 & 페이지네이션 -->
            <v-pagination
                :class="$vuetify.breakpoint.mobile? 'mobile' : ''"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>

            <v-btn
                v-if="!is_favorite"
                width="100"
                height="40"
                dark
                color="primary"
                depressed
                class="rounded-10"
                @click="turnToFavorite()"
            >
                즐겨찾기
            </v-btn>
            <v-btn
                v-if="is_favorite"
                width="100"
                height="40"
                dark
                color="primary"
                depressed
                class="rounded-10"
                @click="turnToFavorite()"
            >
                전체보기
            </v-btn>
        </div>

        <BannerView
            v-if="$store.state.ads_banner.official_league"
            class="my-4 mx-auto"
            type="official_league"
            width="360"
            height="46"
            :round="'rounded-10'"
        />
    </div>
</template>
<script>
import BannerView from '@/components/BannerView'

export default {
    components: {
        BannerView
    },

    props: [
        "from"
    ],

    data: () => ({
        // 선택 목록
        select_list: {
            date: [
                "전체",
                "최근 1개월",
                "최근 3개월",
                "최근 6개월",
                "최근 9개월",
                "최근 12개월",
            ],

            age_group: [
                "전체연령",
                "마스터",
                "시니어",
                "주니어"
            ],

            continent: [
                "전체",
                "대한민국",
                "일본",
                "아시아",
                "아프리카",
                "유럽",
                "오세아니아",
                "북아메리카",
                "남아메리카",
                "남극",
            ],
        },

        is_favorite: false,

        // 키워드
        keyword: {
            date: "최근 3개월",
            age_group: "전체연령",
            continent: "전체",
            name: "",
        },

        // 목록
        list: [],
        currentPageList: [],
        page: 1,
        pageLength: 1,
        loading: true,

        // 기타
        country_list: [],

        sequence: "승점",
        align_point: false,

        is_name_search: false,

        player_keyword_list: [],
    }),

    watch: {
        // 페이징
        page(){
            this.$router.replace({ query: { ...this.$route.query, page: this.page }})
            this.currentPageList = this.list.slice((this.page-1)*20,(this.page)*20)
        },

        // 키워드 저장
        keyword: {
            deep: true,
            handler(newValue) {
                for (let key in newValue) {
                    this.player_keyword_list = this.player_keyword_list.filter(item => item.type !== key)
                    this.player_keyword_list.push({
                        type: key,
                        value: newValue[key]
                    })
                }

                // 컴포넌트에서 player_keyword_list 저장
                this.$store.commit('setPlayerKeywordList', this.player_keyword_list)
            }
        },

        // 승점
        align_point(){
            this.sortList()
        },
    },

    async mounted(){
        // 국가 목록 불러오기
        this.country_list = await this.$load_country_list()

        if(this.from && this.from.path == "/official_league/player/detail"){
            // 컴포넌트에서 player_keyword_list 가져오기
            this.player_keyword_list = this.$store.state.player_keyword_list

            await this.player_keyword_list.forEach(item => {
                switch (item.type) {
                    case 'date':
                    this.keyword.date = item.value;
                    break;

                    case 'age_group':
                    this.keyword.age_group = item.value;
                    break;

                    case 'continent':
                    this.keyword.continent = item.value;
                    break;

                    case 'name':
                    this.keyword.name = item.value;
                    break;
                }
            })
        }

        // 검색
        this.search()

        // 즐겨찾기 목록
        this.loadFavoriteList()
    },

    methods: {
        // 검색
        search(){
            this.loading = true
            this.$http.post("/api/official_league/player/select/search", {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                console.log(res)
                this.list = res.data

                this.list = this.list.map(item => ({
                    ...item, 
                    tier: this.calculateTier(item)
                }))
                // .sort((a, b) => a.tier - b.tier)

                if(this.keyword.name != ""){
                    this.is_name_search = true
                }else{
                    this.is_name_search = false
                }

                // 주소 page 불러오기
                if(this.$route.query.page){
                    this.page = parseInt(this.$route.query.page)
                }else{
                    this.page = 1
                }

                this.currentPageList = this.list.slice((this.page-1)*20,(this.page)*20)
                this.pageLength = Math.ceil(this.list.length/20)
                this.loading = false
            })
        },

        // 플레이어 순위 찾기
        async loadPlayerRank() {
            await this.$http.post("/api/official_league/player/select/rank")
            .then(async(res) => {
                console.log(res)

                // 같은 국가 순위
                let same_country_list = res.data.filter(e =>
                    e.player_country == this.player.player_country
                    && e.player_age_group == this.player.player_age_group
                )
                this.same_country_rank = same_country_list.findIndex(player => player.id == this.$route.query.id) + 1
                this.rank = res.data.findIndex(player => player.id == this.$route.query.id) + 1

                this.total_point = res.data.findIndex(player => player.id == this.$route.query.id)

                console.log(this.same_country_rank)
                console.log(this.rank)
            })
        },

        // 순서
        sortList(){
            if(this.sequence == "승점")
            {
                this.list = this.list.sort((a, b) => new Date(b.total_point) - new Date(a.total_point))
                if(this.align_point){
                    this.list = this.list.reverse()
                }

                this.currentPageList = this.list.slice(0,20)
                this.pageLength = Math.ceil(this.list.length/20)
                // if(this.pageLength > 99) this.pageLength = 99
                this.page = 1
            }

            this.componentKey++
        },

        loadFavoriteList(){
            this.$http.post("/api/official_league/player_favorite/select/all", {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                // console.log(res)
                this.favorite_list = res.data.map(e => e.uid)
            })
        },

        turnToFavorite(){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            else{
                if(this.is_favorite){
                    this.is_favorite = false
                    this.search()
                }
                else{
                    this.is_favorite = true

                    this.list = this.list.filter(item => this.favorite_list.includes(item.id))
                    console.log(this.list)
                    this.currentPageList = this.list.slice(0,20)
                    this.pageLength = Math.ceil(this.list.length/20)
                    // if(this.pageLength > 99) this.pageLength = 99
                    this.page = 1
                }
            }
        },

        // 플레이어 티어 계산
        calculateTier(item) {
            // 같은 점수 처리 전 코드
            // const sameGroupAndCountry = this.list.filter(player => 
            //     player.player_age_group === item.player_age_group && 
            //     player.player_country === item.player_country
            // )
            
            // const sorted = sameGroupAndCountry.sort((a, b) => b.total_point - a.total_point)
            
            // const rank = sorted.findIndex(player => player.id === item.id) + 1
            
            // return this.getRankName(rank)

            const sameGroupAndCountry = this.list.filter(player => 
                player.player_age_group === item.player_age_group && 
                player.player_country === item.player_country
            )
            
            const sorted = sameGroupAndCountry.sort((a, b) => b.total_point - a.total_point)
            
            let rank = 1
            let prevPoint = null
            for (const player of sorted) {
                if (player.total_point !== prevPoint) {
                    rank = sorted.findIndex(p => p.id === player.id) + 1
                    prevPoint = player.total_point
                }
                if (player.id === item.id) break
            }
            
            return this.getRankName(rank)
        },

        getRankName(rank) {
            if (rank >= 1 && rank <= 3) return "챔피언"
            else if (rank >= 4 && rank <= 10) return "레전드"
            else if (rank >= 11 && rank <= 19) return "스페셜" 
            else if (rank >= 20 && rank <= 32) return "어메이징"
            else if (rank >= 33 && rank <= 45) return "레어"
            else if (rank >= 46 && rank <= 60) return "언커먼"
            else if (rank >= 61 && rank <= 100) return "커먼"
            else return "비기너"
        },

        computed_player_kr(item) {
            if(item.player_local){
                return item.player_local
            }
            else{
                if(item.player_country != "KR"){
                    return item.player_kr
                }else{
                    if(item.is_masking != null){
                        if(item.is_masking){
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }else{
                            return item.player_kr
                        }
                    }
                    else{
                        if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                            return item.player_kr
                        }else{
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(item.player_country != "KR"){
                return item.player_en
            }else{
                if(item.is_masking != null){
                    if(item.is_masking){
                        return item.player_en.split(' ')[0] + ' *'
                    }else{
                        return item.player_en
                    }
                }
                else{
                    if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                        return item.player_en
                    }else{
                        return item.player_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* 화살표 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

/* LABEL */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 2px 0 0 2px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:0px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .mobile .v-label{
    font-size:9px !important;
    padding-bottom:1px;
    letter-spacing: -1px;
}

::v-deep .mobile .v-input--selection-controls__input{
    margin: 0 !important;
    padding: 0 !important;
}

</style>
<style scoped>
.diamond-box {
    width: 20px;
    height: 20px;
    background-color: blue; /* Change the color as needed */
    transform: rotate(45deg);
    border-radius: 5px;
}

.diamond-content {
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    display: block;
}
</style>