<template>
    <div>
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="d-flex mobile"
        >
            <!-- 시즌 -->
            <v-select
                height="28"
                style="width:88px;"
                class="rounded-10 font-weight-bold shrink ml-1"
                dense
                outlined
                hide-details
                :items="select_list.term"
                v-model="keyword.term"
                @change="$emit('term_changed', keyword.term)"
            >
                <template v-slot:selection="{ item }">
                    <p class="text-truncate pt-2px" style="height:28px;">{{item}}</p>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-1px" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="d-flex pc"
        >
            <!-- 시즌 -->
            <v-select
                style="width:160px; font-size:30px !important;"
                class="rounded-10 font-weight-bold shrink ml-1"
                dense
                outlined
                hide-details
                :items="select_list.term"
                v-model="keyword.term"
                @change="$emit('term_changed', keyword.term)"
            ></v-select>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "global_term",
    ],

    data: () => ({
        select_list: {
            term: [
                "전체",
                "최근 12개월",
                "최근 9개월",
                "최근 6개월",
                "최근 3개월",
                "최근 1개월"
            ]
        },

        keyword: {
            term: "최근 3개월"
        }
    }),

    mounted(){
        this.keyword.term = this.global_term
    },

    watch: {
        global_term(newVal){
            this.keyword.term = newVal
        }
    }
}
</script>
<style scoped>
/* 테두리 색 변경 (성공) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:8px;
    height:33px !important;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
}

/* 날라가는 거 막는거 성공 (선택지 전체 높이) */
::v-deep .v-select__slot {
    height: 28px;
}

/* 날라가는 거 막는거 성공 */
::v-deep .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 9px;
}

/* 날라가는 거 막는거 성공 (PC) */
::v-deep .pc .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 14px;
    margin-left:4px;
}
</style>