<template>
    <div
        class="px-8 py-6"
    >
        <!-- 제목 -->
        <p>
            이번 대회에서 많이 사용된 카드
        </p>

        <!-- 본문 -->
        <v-row
            v-if="!loading"
            no-gutters
            class="pt-4"
        >
            <!-- 목록 -->
            <v-col
                v-for="(item, index) in list" :key="'card'+index"
                :cols="$vuetify.breakpoint.mobile? 6:2"
                class="pa-2"
                style="position:relative;"
            >
                <v-menu
                    offset-x
                    right
                    open-on-hover
                    nudge-right="0"
                    max-width="320"
                    content-class="rounded-15"
                    :close-on-content-click="true"
                >
                    <template v-slot:activator="{ on: menu }">
                        <v-card
                            class="rounded-lg elevation-0"
                            style="overflow:hidden;"
                        >
                            <v-card
                                class="rounded-lg elevation-0 mb-6"
                                style="position:relative;"
                            >
                                <v-img
                                    v-on="{ ...menu }"
                                    class="rounded-lg"
                                    position="center 30%"
                                    :src="view_image(item)"
                                ></v-img>

                                <v-sheet
                                    style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                    width="72"
                                    height="32"
                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                >
                                    <p
                                        style="font-size:15px; font-weight:bold; color:#424361;"
                                        class="mb-0 text-center mt-1px"
                                    >{{item.count}}</p>
                                </v-sheet>
                            </v-card>
                        </v-card>
                    </template>
                    <Detail
                        v-if="item && item.name_en && !item.name_en.includes('Energy')"
                        :id="item.card_id"
                        :isBoardContent="true"
                        :nation="nation"
                    />
                </v-menu>
            </v-col>
        </v-row>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            class="d-flex mx-auto my-10"
            indeterminate
            color="primary"
            size="140"
        ></v-progress-circular>
    </div>
</template>
<script>
import Detail from '@/components/card/detailForBoard/Detail'

export default {
    components: {
        Detail 
    },

    data: () => ({
        list: [],
        nation: "kr",
        loading: true
    }),

    mounted(){
        // 카드
        this.load()
    },

    methods: {
        load(){
            this.loading = true
            this.$http.post("/api/official_league/select/statistics/card/ptcgo_list", {
                params: {
                    league_id: this.$route.query.id
                }
            }).then(async(res) => {
                let ptcgo_list = res.data.map(e => e.ptcgo_list)
                let deduped_ptcgo_lines = []

                // PTCGO_LIST안에 line 분리해서 deduped_ptcgo_lines에 모으기
                ptcgo_list.forEach(e => {
                    // null 포함시 제외
                    if(e.includes('null')) return
                    

                    let ptcgo_lines = e
                    .split('\n') // 줄바꿈으로 분리
                    .filter(e => e != "") // 빈 줄 제거
                    .filter(e => /^\d/.test(e)) // 숫자(카드 수량)으로 시작하지 않느 설명줄 제거

                    for(let line of ptcgo_lines){
                        let convertedLine = this.convertList(line)
                        // falsy 값이 포함된 배열은 제외
                        if (!convertedLine.some(item => !item || item === '0' || item === '')) {
                            deduped_ptcgo_lines.push(convertedLine)
                        }
                    }
                })

                // 중복 합치기
                let merged_ptcgo_lines = deduped_ptcgo_lines.reduce((acc, curr) => {
                    let key = `${curr[1]}-${curr[2]}-${curr[3]}`
                    if (acc[key]) {
                        acc[key][0] = (parseInt(acc[key][0]) + parseInt(curr[0])).toString()
                    } else {
                        acc[key] = curr
                    }
                    return acc
                }, {})
                deduped_ptcgo_lines = Object.values(merged_ptcgo_lines)

                console.log(deduped_ptcgo_lines)

                // 많은 순 정렬
                deduped_ptcgo_lines.sort((a, b) => b[0] - a[0])

                await this.deckImport(deduped_ptcgo_lines)
            })
        },

        // 덱 입력
        async deckImport(lines){
            for(let line of lines){
                // 페어리 에너지
                if(line[1].includes("{Y}"))
                {
                    await this.$http.post("/api/new/deck/select/card/import/byid", {
                        params: {
                            id: "sm1-172",
                        }
                    }).then((res) => {
                        if(res.data.length){
                            let card = res.data[0]
                            card.count = parseInt(line[0])
                            this.list.push(card)
                            this.list.sort((a, b) => b.count - a.count)
                        }else{
                            let text = line.slice(1).join(' ')
                            this.searchInTable(text, parseInt(line[0]))
                        }
                    })
                }
                // 에너지
                else if(line[1].includes("Energy"))
                {
                    await this.$http.post("/api/new/deck/select/card/import/basicEnergy", {
                        params: {
                            name_en: this.convertName(line[1]),
                            ptcgoCode: line[2],
                            number_en: line[3]
                        }
                    }).then((res) => {
                        if(res.data.length){
                            let card = res.data[0]
                            card.count = parseInt(line[0])
                            this.list.push(card)
                            this.list.sort((a, b) => b.count - a.count)
                        }else{
                            let text = line.slice(1).join(' ')
                            this.searchInTable(text, parseInt(line[0]))
                        }
                    })
                }
                // 특수기호 있는 콜렉션 넘버
                else if(
                    line[2] == "PR-SV"
                    || line[2] == "PR-SW"
                    || line[2] == "PR-SM"
                    || line[2] == "PR-XY"
                    || line[2] == "PR-BLW"
                    || line[2] == "PR-HS"
                    || line[2] == "PR-DPP"
                    || line[2] == "PR-NP"
                    || line[2] == "PR"
                    || line[2].includes("-TG")
                    || line[2].includes("-GG")
                )
                {
                    await this.$http.post("/api/new/deck/select/card/import/numberConvert", {
                        params: {
                            name_en: this.convertName(line[1]),
                            ptcgoCode: line[2],
                            number_en: line[3]
                        }
                    }).then((res) => {
                        if(res.data.length){
                            let card = res.data[0]
                            card.count = parseInt(line[0])
                            this.list.push(card)
                            this.list.sort((a, b) => b.count - a.count)
                        }else{
                            let text = line.slice(1).join(' ')
                            this.searchInTable(text, parseInt(line[0]))
                        }
                    })
                }
                // 위 예외처리 이후 나머지
                else
                {
                    await this.$http.post("/api/new/deck/select/card/import/for_official_league", {
                        params: {
                            name_en: this.convertName(line[1]),
                            ptcgoCode: line[2],
                            number_en: line[3]
                        }
                    }).then((res) => {
                        if(res.data.length){
                            let card = res.data[0]
                            card.count = parseInt(line[0])
                            this.list.push(card)
                            this.list.sort((a, b) => b.count - a.count)
                            console.log(card)
                        }else{
                            let text = line.slice(1).join(' ')
                            this.searchInTable(text, parseInt(line[0]))
                        }
                    })
                }
            }

            this.loading = false
        },

        // PTCGL 카드 데이터 검색
        async searchInTable(deck_code, amount){
            await this.$http.post("/api/admin/deck/ptcgl/select/specific", {
                params: {
                    text: deck_code.substring(deck_code.indexOf(' ') + 1)
                }
            }).then(async(res) => {
                if(res.data.length){
                    await this.$http.post("/api/new/deck/select/card/import/byid", {
                        params: {
                            id: res.data[0].card_id,
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            let card = res.data[0]
                            card.count = parseInt(amount)
                            this.list.push(card)
                            this.list.sort((a, b) => b.count - a.count)
                        }
                    })
                }
            })
        },

        // 에너지 중괄호 변경하기
        convertName(text){
            // 없는 값 들어오면 replace 안하고 return
            if(!text){
                return text
            }

            // 에너지 > 중괄호 변환
            text = text.replace("{G}", "Grass")
            text = text.replace("{R}", "Fire")
            text = text.replace("{W}", "Water")
            text = text.replace("{L}", "Lightning")
            text = text.replace("{P}", "Psychic")
            text = text.replace("{F}", "Fighting")
            text = text.replace("{D}", "Darkness")
            text = text.replace("{M}", "Metal")

            // 에너지 > Basic 값 제거
            text = text.replace("Basic", "")

            // 괄호로 묶인 내용을 제거 (괄호 포함)
            text = text.replace(/\(.*?\)/g, '')

            // 앞뒤 여백 제거
            text = text.trim()

            return text
        },

        // PTCGO_LIST -> [1,2,3,4]
        convertList(str) {
            let parts = str.split(' ')
            if(parts[parts.length - 1] == "PH") parts.pop() 

            let firstValue = parts[0]
            let thirdValue = parts[parts.length - 2]
            let fourthValue = parts[parts.length - 1]
            let secondValue = parts.slice(1, parts.length - 2).join(' ')

            if(!secondValue.includes("Energy") && thirdValue == "Energy"){
                secondValue = secondValue + " Energy"
                thirdValue = "SVE"
            }
            else if(thirdValue == "Energy"){
                thirdValue = "SVE"
            }

            return [firstValue, secondValue, thirdValue, fourthValue]
        },

        // 이미지
        view_image(item){
            if(item.image_kr != "default.png"){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
            else if(item.image_jp != "default.png"){
                return '/upload/card_jp_image/'+encodeURI(item.image_jp)
            }
            else if(item.image_en != "default.png"){
                return '/upload/card_en_image/'+encodeURI(item.image_en)
            }
            else{
                return "@/assets/default.png"
            }
        }
    }
}
</script>
<style>
.deckTable {
    width:100%;
    border:1px solid #eee;
}

.deckTable tr td{
    height:20px;
    border:1px solid #eee;
    padding: 0;
    
}
</style>