<template>
    <div>
        <v-sheet
            class="ma-1 mt-2 px-4 py-2 pb-3 rounded-10"
        >
            <!-- # 상단 -->
            <div
                class="d-flex align-center justify-space-between mb-1"
                :class="$vuetify.breakpoint.mobile? '':'mt-2'"
            >
                <!-- # 제목 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # PC -->
                    <p
                        v-if="!$vuetify.breakpoint.mobile"
                        style="font-size:18px; font-weight:bold;"
                        class="my-0"
                    >
                        대회 기록 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                    </p>

                    <!-- # Mobile -->
                    <p
                        v-if="$vuetify.breakpoint.mobile"
                        style="font-size:10px; font-weight:bold;"
                        class="mb-2 mt-2"
                    >
                        대회 기록 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                    </p>
                </div>

                <!-- # 선택 상단 -->
                <SelectHeader
                    :global_term="term"
                    @term_changed="term_changed_handler"
                    :key="componentKey + 'raceHeader'"
                />
            </div>

            <!-- # 목록 (Mobile) -->
            <div
                v-if="$vuetify.breakpoint.mobile"
            >
                <!-- # 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    height="32"
                    color="#F0F3F5"
                >
                    <div
                        style="width:242px; font-size:12px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        대회명
                    </div>
                    <div
                        class="d-flex align-center"
                        style="width:170px; position:relative;"
                    >
                        <div
                            style="width:96px; font-size:12px;"
                            class="text-center ml-7 d-flex align-center justify-center"
                        >
                            덱레시피
                        </div>
                        <div
                            style="width:100px; font-size:12px; margin-right:10px;"
                            class="text-center d-flex align-center justify-center"
                        >
                            결과
                        </div>
                    </div>
                </v-sheet>

                <!-- # 목록 값 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index+'list'"
                    height="38"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                >
                    <!-- # 덱 (아이콘 & 덱분류값 & 대회이름) -->
                    <div
                        style="width:160px;"
                        @click="$router.push('/official_league/read?id='+item.league_id)"
                    >
                        <!-- # 덱 분류값이 있는 경우 (아이콘 & 덱분류값 & 대회이름) -->
                        <div
                            v-if="item.icon_day1 || item.sub_icon_day1"
                            class="pl-1 d-flex align-center justify-center"
                        >
                            <!-- # 덱 아이콘 -->
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        style="font-size:10px;"
                                        class="d-flex align-center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <!-- # 메인 덱 분류 아이콘 (DAY1) -->
                                        <div
                                            v-if="item.icon_day1"
                                        >
                                            <div
                                                v-if="item.icon_day1"
                                                class="d-flex align-center mt-1"
                                            >
                                                <v-img
                                                    v-for="icon in item.icon_day1.split(',')" :key="icon"
                                                    :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                    :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                    contain
                                                    class="mb-1 mx-3px"
                                                    style="opacity:1"
                                                    :src="'/upload/pokesprite/'+icon+'.png'"
                                                ></v-img>
                                            </div>
                                        </div>

                                        <!-- # 하위 덱 분류 아이콘 (DAY1) -->
                                        <div
                                            v-else
                                        >
                                            <div
                                                v-if="item.sub_icon_day1"
                                                class="d-flex align-center mt-1"
                                            >
                                                <v-img
                                                    v-for="icon in item.sub_icon_day1.split(',')" :key="icon"
                                                    :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                    :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                    contain
                                                    class="mb-1 mx-3px"
                                                    style="opacity:1"
                                                    :src="'/upload/pokesprite/'+icon+'.png'"
                                                ></v-img>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- # 아이콘 툴팁 (덱분류값) -->
                                <div class="text-center white--text pa-1">
                                    <div v-if="item.sub_icon_day1">
                                        {{deck_name_translate(item.deck_identifier1)}}
                                    </div>
                                    <div v-else-if="item.icon_day1">
                                        {{deck_name_translate(item.deck_identifier1)}}
                                    </div>
                                </div>
                            </v-tooltip>

                            <!-- # 대회이름 & 대회날짜 -->
                            <div
                                class="pr-0 pl-2px text-truncate"
                                style="min-width:106px; font-size:10px;"
                            >
                                <!-- # 대회이름 -->
                                <div style="font-size:10px;" class="d-flex">
                                    <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-12">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div
                                                style="width:20px;"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-img
                                                    v-if="item.league_nation"
                                                    width="12"
                                                    height="8"
                                                    class="mx-auto mt-1"
                                                    style="box-shadow:0px 2px 4px #ddd;"
                                                    :src="'/upload/flags/'+item.league_nation.toLowerCase()+'.svg'"
                                                ></v-img>
                                            </div>
                                        </template>
                                        <div class="text-center pa-1 text-caption">
                                            {{$country_name(item.league_nation, country_list)}}
                                        </div>
                                    </v-tooltip>
                            
                                    <span style="font-size:10px; margin-left:4px;">{{item.league_title_kr}}</span>
                                </div>

                                <!-- # 대회날짜 -->
                                <div class="grey--text" style="font-size:10px;">
                                    {{dayjs(item.start_date).format('YYYY-MM-DD')}} ~ {{dayjs(item.end_date).format('DD')}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <!-- # 덱 레시피 & 결과 -->
                    <div
                        class="d-flex align-center"
                        style="width:140px; position:relative;"
                    >
                        <p
                            style="font-size:12px; width:60px;"
                            class="mb-0 text-center blue--text mr-2 text-truncate font-weight-medium"
                            @click="deckView(item, 'DAY1')"
                        >
                            덱 보기
                        </p>
                        <div
                            style="width:56px; font-size:12px; height:38px; font-weight:bold; line-height:16px;"
                            class="text-center mt-10px"
                            @click="$router.push('/official_league/player/detail?id='+item.uid)"
                        >
                            <p class="mb-0" style="font-size:11px;">{{item.top_cut}}</p>
                            <p class="grey--text font-weight-regular mb-0" style="font-size:9px; margin-top:-2px;">승점 {{item.deck_point1}}</p>
                        </div>
                    </div>
                </v-sheet>
            </div>

            <!-- # 목록 (PC) -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
                class="mt-2"
            >
                <!-- # 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    height="40"
                    color="#F0F3F5"
                    width="100%"
                >
                    <div
                        style="width:40%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        대회명
                    </div>
                    <div
                        style="width:30%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        플레이어
                    </div>
                    <div
                        style="width:15%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        성적
                    </div>
                    <div
                        style="width:15%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        덱보기
                    </div>
                </v-sheet>

                <!-- # 목록 값 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index+'list'"
                    height="56"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                >
                    <!-- # 덱 (아이콘 & 덱분류값 & 대회이름) -->
                    <div
                        @click="$router.push('/official_league/read?id='+item.league_id)"
                    >
                        <!-- # 덱 분류값이 있는 경우 (아이콘 & 덱분류값 & 대회이름) -->
                        <div
                            v-if="item.icon_day1 || item.sub_icon_day1"
                            class="pl-2 d-flex align-center justify-center"
                        >
                            <!-- # 덱 아이콘 -->
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        style="font-size:10px;"
                                        class="d-flex align-center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <!-- # 메인 덱 분류 아이콘 (DAY1) -->
                                        <div
                                            v-if="item.icon_day1"
                                        >
                                            <div
                                                v-if="item.icon_day1"
                                                class="d-flex align-center mt-1"
                                            >
                                                <v-img
                                                    v-for="icon in item.icon_day1.split(',')" :key="icon"
                                                    :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                    :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                    contain
                                                    class="mb-1 mx-1"
                                                    style="opacity:1"
                                                    :src="'/upload/pokesprite/'+icon+'.png'"
                                                ></v-img>
                                            </div>
                                        </div>

                                        <!-- # 하위 덱 분류 아이콘 (DAY1) -->
                                        <div
                                            v-else
                                        >
                                            <div
                                                v-if="item.sub_icon_day1"
                                                class="d-flex align-center mt-1"
                                            >
                                                <v-img
                                                    v-for="icon in item.sub_icon_day1.split(',')" :key="icon"
                                                    :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                    :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                    contain
                                                    class="mb-1 mx-1"
                                                    style="opacity:1"
                                                    :src="'/upload/pokesprite/'+icon+'.png'"
                                                ></v-img>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- # 아이콘 툴팁 (덱분류값) -->
                                <div class="text-center white--text pa-1">
                                    <div v-if="item.sub_icon_day1">
                                        {{deck_name_translate(item.deck_identifier1)}}
                                    </div>
                                    <div v-else-if="item.icon_day1">
                                        {{deck_name_translate(item.deck_identifier1)}}
                                    </div>
                                </div>
                            </v-tooltip>

                            <!-- # 대회이름 & 대회날짜 -->
                            <div
                                class="pl-2"
                            >
                                <!-- # 대회이름 -->
                                <div style="font-size:14px;" class="d-flex">
                                    <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-12">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div
                                                style="width:24px;"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-img
                                                    v-if="item.league_nation"
                                                    width="14"
                                                    height="10"
                                                    class="mx-auto mt-6px"
                                                    style="box-shadow:0px 2px 4px #ddd;"
                                                    :src="'/upload/flags/'+item.league_nation.toLowerCase()+'.svg'"
                                                ></v-img>
                                            </div>
                                        </template>
                                        <div class="text-center pa-1 text-caption">
                                            {{$country_name(item.league_nation, country_list)}}
                                        </div>
                                    </v-tooltip>
                            
                                    {{item.league_title_kr}}
                                </div>

                                <!-- # 대회날짜 -->
                                <div class="grey--text" style="font-size:10px;">
                                    {{dayjs(item.start_date).format('YYYY-MM-DD')}} ~ {{dayjs(item.end_date).format('DD')}}
                                </div>
                            </div>
                        </div>

                        <!-- # 덱 분류값이 없는 경우 (대회이름) -->
                        <div
                            v-else
                            class="pl-3"
                        >
                            <!-- # 대회이름 -->
                            <div style="font-size:14px;">
                                {{item.league_title_kr}}
                            </div>

                            <!-- # 대회날짜 -->
                            <div class="grey--text" style="font-size:10px;">
                                {{dayjs(item.start_date).format('YYYY-MM-DD')}} ~ {{dayjs(item.end_date).format('DD')}}
                            </div>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <div
                        style="width:30%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center"
                        @click="$router.push('/official_league/player/detail?id='+item.uid)"
                    >
                        <div
                            v-if="!item.player_local"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-12">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div
                                            style="width:24px;"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-img
                                                v-if="item.player_country"
                                                width="14"
                                                height="10"
                                                class="mx-auto mt-5px"
                                                style="box-shadow:0px 2px 4px #ddd;"
                                                :src="'/upload/flags/'+item.player_country.toLowerCase()+'.svg'"
                                            ></v-img>
                                        </div>
                                    </template>
                                    <div class="text-center pa-1 text-caption">
                                        {{$country_name(item.player_country, country_list)}}
                                    </div>
                                </v-tooltip>
                                <p
                                    style="font-size:14px; font-weight:600; margin-bottom:-2px;"
                                >
                                    {{computed_player_kr(item)}}
                                </p>
                            </div>
                            <p
                                style="font-size:12px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                            >
                                {{computed_player_en(item)}}
                            </p>
                        </div>
                        <div
                            v-else
                        >
                            <div
                                class="d-flex"
                            >
                                <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-12">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div
                                            style="width:24px;"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-img
                                                v-if="item.player_country"
                                                width="14"
                                                height="10"
                                                class="mx-auto mt-5px"
                                                style="box-shadow:0px 2px 4px #ddd;"
                                                :src="'/upload/flags/'+item.player_country.toLowerCase()+'.svg'"
                                            ></v-img>
                                        </div>
                                    </template>
                                    <div class="text-center pa-1 text-caption">
                                        {{$country_name(item.player_country, country_list)}}
                                    </div>
                                </v-tooltip>
                                <p
                                    style="font-size:14px; font-weight:600; margin-bottom:-2px;"
                                >
                                    {{item.player_local}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        style="width:15%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.top_cut}}
                    </div>
                    <div
                        style="width:15%; font-size:14px; height:56px;"
                        class="text-center d-flex align-center justify-center font-weight-medium icu_purple--text"
                        @click="deckView(item, 'DAY1')"
                    >
                        덱보기
                    </div>
                </v-sheet>
            </div>

            <!-- # 버튼 & 페이지네이션 -->
            <v-pagination
                class="mt-2"
                :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>
        </v-sheet>

        <!-- # 덱 보기 (dialog) -->
        <v-dialog
            v-model="dialog.deckView"
            :width="$vuetify.breakpoint.mobile? '':1068"
            content-class="rounded-15"
        >
            <DeckView
                :player_country="selected_item.player_country"
                :player_kr="selected_item.player_kr"
                :player_en="selected_item.player_en"
                :player_local="selected_item.player_local"
                :player_age_group="selected_item.player_age_group"
                :is_masking="selected_item.is_masking"
                :deck_name_kr="selected_item.deck_name_kr"
                :deck_name_en="selected_item.deck_name_en"
                :sub_deck_name_kr="selected_item.sub_deck_name_kr"
                :sub_deck_name_en="selected_item.sub_deck_name_en"
                :deck_card_list="selected_item.deck_card_list"
                :title_kr="selected_item.league_title"
                :title_en="selected_item.league_title_en"
                :top_cut="selected_item.top_cut"
                @close="dialog.deckView = false; load_card_shop_standing_list()"
                :key="componentKey"
            />
        </v-dialog>

        <!-- # 덱 보기 (dialog) -->
        <v-dialog
            v-model="dialog.deckView"
            :width="$vuetify.breakpoint.mobile? '':1068"
            content-class="rounded-15"
        >
            <DeckView
                :player_country="selected_item.player_country"
                :player_kr="selected_item.player_kr"
                :player_en="selected_item.player_en"
                :player_local="selected_item.player_local"
                :player_age_group="selected_item.player_age_group"
                :is_masking="selected_item.is_masking"
                :deck_name_kr="selected_item.deck_name_kr"
                :deck_name_en="selected_item.deck_name_en"
                :sub_deck_name_kr="selected_item.sub_deck_name_kr"
                :sub_deck_name_en="selected_item.sub_deck_name_en"
                :deck_card_list="selected_item.deck_card_list"
                :title_kr="selected_item.league_title"
                :title_en="selected_item.league_title_en"
                :top_cut="selected_item.top_cut"
                @close="dialog.deckView = false; load_card_shop_standing_list()"
                :key="componentKey"
            />
        </v-dialog>
    </div>
</template>
<script>
import DeckView from '@/views/official_league/components/read/DeckView'
import SelectHeader from './components/SelectHeader'

export default {
    props: ["global_term"],

    components: {
        DeckView,
        SelectHeader
    },

    data: () => ({
        term: "최근 3개월",

        list: [],
        currentPageList: [],
        page: 1,
        pageLength: 1,

        selected_item: {},
        deck_auto_translate_list: [],
        componentKey: 0,

        dialog: {
            deckView: false
        },
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*6,(this.page)*6)
        },

        global_term(newVal){
            this.term = newVal
        }
    },

    async mounted(){
        this.country_list = await this.$load_country_list()

        this.term = this.global_term

        // # 덱분류값 번역 목록 불러오기
        await this.load_deck_auto_translate_list()

        // 대회 성적 불러오기
        this.loadRaceRecord()
    },

    methods: {
        term_changed_handler(term){
            this.term = term
            this.loadRaceRecord()
        },

        // 대회 성적 불러오기
        loadRaceRecord(){
            this.$http.post("/api/official_league/deck_rank/select/race_record", {
                params: {
                    identifier: this.$route.query.id,
                    term: this.term
                }
            }).then((res) => {
                console.log("loadRaceRecord", res.data)
                if(res.data.length){
                    this.list = res.data
                    this.currentPageList = this.list.slice(0,6)
                    this.pageLength = Math.ceil(this.list.length/6)
                    if(this.pageLength > 99) this.pageLength = 99
                    this.page = 1
                }
            })
        },

        // 덱 보기
        deckView(item, day){
            console.log("deckView", item)

            if(day == "DAY1" && !item.deck_identifier1){
                alert("입력되지 않은 덱 레시피입니다")
                return
            }

            if(!item.deck_card_list1){
                alert("PTCGL 정보가 입력되지 않은 덱 레시피입니다")
                return
            }

            this.selected_item = item

            if(day == 'DAY1'){
                this.selected_item.deck_name_en = item.deck_identifier1
                this.selected_item.deck_name_kr = this.deck_name_translate(item.deck_identifier1)
                this.selected_item.sub_deck_name_en = item.deck_identifier1
                this.selected_item.sub_deck_name_kr = this.deck_name_translate(item.deck_identifier1)
                this.selected_item.deck_card_list = this.selected_item.deck_card_list1
            }

            this.dialog.deckView = true
            this.componentKey++
        },

        // # 덱분류값 번역 목록 불러오기
        load_deck_auto_translate_list(){
            this.$http.get('/api/admin/league/deck_auto_translate_list/list')
            .then((res) => {
                this.deck_auto_translate_list = res.data
            })
        },

        // # 덱분류값 한글로 번역
        deck_name_translate(en) {
            if(!en) return 
            let translation = this.deck_auto_translate_list.find(item => item.en === en)
            return translation ? (translation.kr || en) : en
        },

        // 카드샵대항전 승점 계산
        caculateTotal(item){
            return item.round_win * 2 + item.round_lose * -1
        },

        computed_player_kr(item) {
            if(item.player_local){
                return item.player_local
            }
            else{
                if(item.player_country != "KR"){
                    return item.player_kr
                }else{
                    if(item.is_masking != null){
                        if(item.is_masking){
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }else{
                            return item.player_kr
                        }
                    }
                    else{
                        if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                            return item.player_kr
                        }else{
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(item.player_country != "KR"){
                return item.player_en
            }else{
                if(item.is_masking != null){
                    if(item.is_masking){
                        return item.player_en.split(' ')[0] + ' *'
                    }else{
                        return item.player_en
                    }
                }
                else{
                    if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                        return item.player_en
                    }else{
                        return item.player_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>