<template>
    <v-sheet
        class="ma-2 px-4 py-2 pb-3 rounded-10"
        outlined
    >
        <!-- 상단 -->
        <div
            class="d-flex align-center justify-space-between mb-3"
            :class="$vuetify.breakpoint.mobile? '':'mt-2'"
        >
            <!-- 제목 -->
            <div
                class="d-flex align-center"
            >
                <!-- PC -->
                <p
                    v-if="!$vuetify.breakpoint.mobile"
                    style="font-size:18px; font-weight:bold;"
                    class="my-0"
                >
                    티어 추이 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                </p>

                <!-- Mobile -->
                <p
                    v-if="$vuetify.breakpoint.mobile"
                    style="font-size:10px; font-weight:bold;"
                    class="mb-2 mt-2"
                >
                    티어 추이 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                </p>
            </div>

            <!-- 선택 상단 -->
            <div>
                <!-- Mobile -->
                <div
                    v-if="$vuetify.breakpoint.mobile"
                    class="d-flex mobile"
                >
                    <!-- 시즌 -->
                    <v-select
                        height="28"
                        style="width:90px;"
                        class="rounded-10 font-weight-bold shrink ml-1"
                        dense
                        outlined
                        hide-details
                        :items="select_list.interval"
                        v-model="interval"
                        @change="search()"
                    >
                        <template v-slot:selection="{ item }">
                            <p class="text-truncate pt-2px" style="height:28px;">{{item}}</p>
                        </template>
                        <template v-slot:append>
                            <v-icon small class="pt-1px" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>

                <!-- PC -->
                <div
                    v-if="!$vuetify.breakpoint.mobile"
                    class="d-flex pc"
                >
                    <v-select
                        style="width:160px; font-size:30px !important;"
                        class="rounded-10 font-weight-bold shrink"
                        dense
                        outlined
                        hide-details
                        :items="select_list.interval"
                        v-model="interval"
                        @change="search()"
                    ></v-select>
                </div>
            </div>
        </div>

        <!-- 그래프 -->
        <ChartForTier
            v-if="!loading"
            :height="$vuetify.breakpoint.mobile? 140 : 210"
            :list="tier"
            :interval="interval"
        />
    </v-sheet>
</template>
<script>
import ChartForTier from "./components/ChartForTier"

export default {
    props: [
        "player_country",
        "player_age_group"    
    ],

    components: {
        ChartForTier
    },

    data: () => ({
        // 목록
        list: [],
        tier: [],

        select_list: {
            interval: [
                "월간 추이",
                "주간 추이",
                "일간 추이"
            ]
        },

        loading: true,

        interval: "월간 추이"
    }),

    mounted(){
        this.search()
    },

    methods: {
        // 검색
        async search(){
            this.loading = true
            this.list = []
            this.tier = []
            const numbers = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
            for (const number of numbers) {
                const response = await this.$http.post("/api/official_league/player/select/tier_graph", {
                    params: {
                        number: number,
                        interval: this.interval,
                        player_country: this.player_country,
                        player_age_group: this.player_age_group,
                    }
                })

                console.log(response)

                const list = response.data

                // (같은 점수 처리 전 코드)
                // let rank = list.findIndex(player => player.id == this.$route.query.id) + 1

                // 같은 국가 순위 계산
                let prevPoint = null
                let rank = 1
                let found = false
                
                for (let i = 0; i < list.length; i++) {
                    const player = list[i]
                    if (player.total_point !== prevPoint) {
                        rank = i + 1
                        prevPoint = player.total_point
                    }
                    if (player.id === parseInt(this.$route.query.id)) {
                        found = true
                        break
                    }
                }
                
                if (found) {
                    this.tier.push(this.computed_rank_value(rank))
                } else {
                    this.tier.push(8)
                }
            }

            console.log(this.tier)

            this.loading = false
        },

        computed_rank_value(rank){
            if(rank >= 1 && rank <= 3){
                return 1
            }
            else if(rank >= 4 && rank <= 10){
                return 2
            }
            else if(rank >= 11 && rank <= 19){
                return 3
            }
            else if(rank >= 20 && rank <= 32){
                return 4
            }
            else if(rank >= 33 && rank <= 45){
                return 5
            }
            else if(rank >= 46 && rank <= 60){
                return 6
            }
            else if(rank >= 61 && rank <= 100){
                return 7
            }
            else if(rank > 100){
                return 8
            }
        },
    }
}
</script>
<style scoped>
/* 테두리 색 변경 (성공) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:8px;
    height:33px !important;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
}

/* 날라가는 거 막는거 성공 (선택지 전체 높이) */
::v-deep .v-select__slot {
    height: 28px;
}

/* 날라가는 거 막는거 성공 */
::v-deep .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 9px;
}

/* 날라가는 거 막는거 성공 (PC) */
::v-deep .pc .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 14px;
    margin-left:4px;
}
</style>