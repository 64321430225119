<template>
    <div
        :key="componentKey"
    >
        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <v-sheet
                v-if="!is_exist"
                height="400"
                class="rounded-15"
                style="padding-top:130px;"
            >
                <v-col class="text-center">
                    <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                    <p class="blue--text">존재하지 않는 덱 레시피 입니다</p>
                </v-col>
            </v-sheet>
        
            <div
                v-if="is_exist"
            >
                <!-- # 덱 -->
                <div
                    id="capture"
                    class="rounded-15 white"
                    style="padding:20px 20px;"
                >
                    <!-- # 상단 -->
                    <div>
                        <div
                            class="d-flex"
                        >
                            <!-- 이미지 -->
                            <v-card
                                class="rounded-15 elevation-0"
                                style="overflow:hidden;"
                            >
                                <v-card
                                    class="rounded-15 elevation-0 mb-6"
                                    style="position:relative;"
                                >
                                    <v-img
                                        height="50"
                                        width="134"
                                        class="rounded-15 grey"
                                        position="center 30%"
                                        :src="view_image(main_pokemon)"
                                    ></v-img>
                                    <v-sheet
                                        style="position:absolute; bottom:-12px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                        width="100"
                                        height="16"
                                        class="d-flex align-center justify-center rounded-20"
                                    >
                                        <div>
                                            <v-img
                                                width="18"
                                                height="12"
                                                class="rounded-4 ml-1"
                                                :src="'/upload/flags/'+player_country.toLowerCase()+'.svg'"
                                            ></v-img>
                                        </div>
                                        <p
                                            style="font-size:10px; font-weight:bold; color:#424361;"
                                            class="mb-0 ml-1 mr-1 mt-1px text-truncate"
                                        >
                                            {{nation == 'kr'? computed_player_kr() : (player_local? player_local : computed_player_en())}}
                                        </p>
                                    </v-sheet>
                                </v-card>
                            </v-card>

                            <div
                                class="ml-2"
                                style="position:relative;"
                            >
                                <p
                                    style="font-size:12px; font-weight:bold; width:120px;"
                                    class="mb-0"
                                >
                                    {{deck_name_kr? deck_name_kr : sub_deck_name_kr}}
                                </p>
                                <p
                                    style="font-size:10px; margin-top:-2px; width:120px;"
                                    class="mb-0 grey--text"
                                >
                                    {{deck_name_en? deck_name_en : sub_deck_name_en}}
                                </p>

                                <p
                                    style="font-size:10px; margin-top:2px; font-weight:bold; width:120px;"
                                    class="mb-0"
                                >
                                    {{nation == 'kr'? title_kr : title_en}}
                                    <span class="icu_purple--text">{{translate_top_cut(top_cut)}}</span>
                                </p>
                                <p
                                    style="font-size:10px; margin-top:-2px; width:120px;"
                                    class="mb-2 grey--text text-truncate"
                                >
                                    {{nation == 'kr'? title_en : title_kr}}
                                </p>
                            </div>
                        </div>

                        <div
                            class="d-flex align-center justify-space-between"
                        >
                            <div
                                class="d-flex align-center ml-2px"
                            >
                                <p
                                    class="icu_purple--text font-weight-medium mb-0"
                                    style="cursor:pointer; font-size:10px;"
                                    @click="dialog.summary = true"
                                >
                                    <v-icon size="12" class="pb-2px" color="icu_purple">mdi-open-in-new</v-icon>
                                    한 화면에 모든 카드 보기
                                </p>
                            </div>

                            <v-btn
                                class="pl-2 pr-10px rounded-15"
                                outlined
                                color="#ddd"
                                small
                                style="font-size:10px; font-weight:bold; letter-spacing:-0.4px; min-width:0px; width:72px; height:24px;"
                                depressed
                                v-ripple="false"
                                @click="deckExport_handler()"
                            >
                                <v-sheet height="10" width="12" class="d-flex mr-2px">
                                    <v-img contain src="@/assets/deck_export.svg"></v-img>
                                </v-sheet>
                                <span style="color:#424361;">덱 코드</span>
                            </v-btn>
                        </div>

                        <div
                            class="d-flex justify-space-between mt-3 mobile"
                        >
                            <v-select
                                height="34"
                                style="font-size:12px; width:49%;"
                                class="shrink rounded-15 font-weight-bold"
                                label="카드보기 형식"
                                dense
                                outlined
                                hide-details
                                :items="select_list.view_type_mobile"
                                v-model="view_type"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="">{{item}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>

                            <v-select
                                height="34"
                                style="font-size:12px; width:49%;"
                                class="shrink rounded-15 font-weight-bold"
                                label="국가보기 변경"
                                dense
                                outlined
                                hide-details
                                :items="select_list.nation"
                                v-model="nation"
                                item-text="name"
                                item-value="value"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="">{{item.name}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>
                        </div>
                    </div>

                    <v-divider class="mt-3"></v-divider>

                    <!-- # 기본 -->
                    <div
                        v-if="view_type == '기본' && !loading"
                        class="ml-2"
                    >
                        <!-- 포켓몬 -->
                        <div
                            style="margin-top:12px;"
                        >
                            <p
                                class="mb-1"
                                style="font-size:12px; color:#F8468D;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <v-sheet
                                v-for="item in card_list_pokemon" :key="item.id"
                                class="mt-1"
                                width="245"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                height="50"
                                                position="center 30%"
                                                style="position:relative;"
                                                :src="view_image(item)"
                                            >
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                                <div
                                                    class="d-flex"
                                                    :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                    style="position:relative;"
                                                >
                                                    <p
                                                        v-if="!is_Standard(item)"
                                                        style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                    >
                                                        익스펜디드
                                                    </p>
                                                    <v-sheet
                                                        height="34"
                                                        width="34"
                                                        class="rounded-circle text-center ma-2" 
                                                        style="background:#f8468d"
                                                    >
                                                        <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                    </v-sheet>
                                                    <div
                                                        class="pl-2 mr-1 mt-1 text-truncate"
                                                        style="width:184px;"
                                                    >
                                                        <p
                                                            style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:14px; margin-bottom:0px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:9px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-img>
                                        </template>
                                        <Detail
                                            :id="item.id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>

                        <!-- 트레이너스 -->
                        <div
                            style="margin-top:12px;"
                        >
                            <p
                                class="mb-1"
                                style="font-size:12px; color:#F8468D;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <v-sheet
                                v-for="item in card_list_trainer" :key="item.id"
                                class="mt-1"
                                width="245"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                height="50"
                                                position="center 30%"
                                                style="position:relative;"
                                                :src="view_image(item)"
                                            >
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                                <div
                                                    class="d-flex"
                                                    :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                    style="position:relative;"
                                                >
                                                    <p
                                                        v-if="!is_Standard(item)"
                                                        style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                    >
                                                        익스펜디드
                                                    </p>
                                                    <v-sheet
                                                        height="34"
                                                        width="34"
                                                        class="rounded-circle text-center ma-2" 
                                                        style="background:#f8468d"
                                                    >
                                                        <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                    </v-sheet>
                                                    <div
                                                        class="pl-2 mr-1 mt-1 text-truncate"
                                                        style="width:184px;"
                                                    >
                                                        <p
                                                            style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:14px; margin-bottom:0px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:9px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-img>
                                        </template>
                                        <Detail
                                            :id="item.id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>

                        <!-- 에너지 -->
                        <div
                            style="margin-top:12px;"
                        >
                            <p
                                class="mb-1"
                                style="font-size:12px; color:#F8468D;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <v-sheet
                                v-for="item in card_list_energy" :key="item.id"
                                class="mt-1"
                                width="245"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                height="50"
                                                position="center 30%"
                                                style="position:relative;"
                                                :src="view_image(item)"
                                            >
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                                <div
                                                    class="d-flex"
                                                    :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                    style="position:relative;"
                                                >
                                                    <p
                                                        v-if="!is_Standard(item)"
                                                        style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                    >
                                                        익스펜디드
                                                    </p>
                                                    <v-sheet
                                                        height="34"
                                                        width="34"
                                                        class="rounded-circle text-center ma-2" 
                                                        style="background:#f8468d"
                                                    >
                                                        <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                    </v-sheet>
                                                    <div
                                                        class="pl-2 mr-1 mt-1 text-truncate"
                                                        style="width:184px;"
                                                    >
                                                        <p
                                                            style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:14px; margin-bottom:0px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:9px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-img>
                                        </template>
                                        <Detail
                                            :id="item.id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>
                    </div>

                    <!-- # 이미지(전체) -->
                    <div
                        v-if="view_type == '이미지(전체)' && !loading"
                    >
                        <p
                            style="font-size:10px; color:#F8468D;"
                            class="mt-2 mb-0"
                        >
                            포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}}), &nbsp;
                            트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}}), &nbsp;
                            에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>

                        <!-- 포켓몬 -->
                        <v-row
                            no-gutters
                        >
                            <v-col
                                v-for="(item, index) in card_list_total_view" :key="'전체'+ index"
                                class="pa-1"
                                cols="6"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                :src="view_image(item)"
                                            ></v-img>
                                        </template>
                                        <Detail
                                            :id="item.id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- # 이미지(중복) -->
                    <div
                        v-if="view_type == '이미지(중복)' && !loading"
                    >
                        <p
                            style="font-size:10px; color:#F8468D;"
                            class="mt-2 mb-0"
                        >
                            포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}}), &nbsp;
                            트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}}), &nbsp;
                            에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>

                        <!-- 포켓몬 -->
                        <v-row
                            no-gutters
                        >
                            <v-col
                                v-for="item in card_list" :key="'중복'+item.id"
                                class="pa-1"
                                cols="6"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <!-- 이미지 -->
                                            <v-card
                                                class="rounded-lg elevation-0"
                                                style="overflow:hidden;"
                                            >
                                                <v-card
                                                    class="rounded-lg elevation-0 mb-6"
                                                    style="position:relative;"
                                                >
                                                    <v-img
                                                        v-on="{ ...menu }"
                                                        width="258"
                                                        class="rounded-lg"
                                                        position="center 30%"
                                                        :src="view_image(item)"
                                                    ></v-img>
                                                    <v-sheet
                                                        style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                        width="72"
                                                        height="32"
                                                        class="d-flex align-center justify-center mx-auto rounded-20"
                                                    >
                                                        <p
                                                            style="font-size:15px; font-weight:bold; color:#424361;"
                                                            class="mb-0 text-center mt-1px"
                                                        >{{item.amount}}</p>
                                                    </v-sheet>
                                                </v-card>
                                            </v-card>
                                        </template>
                                        <Detail
                                            :id="item.id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- 로딩 -->
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                        class="d-flex my-16 mx-auto"
                        size="100"
                    ></v-progress-circular>
                </div>
            </div>
        </div>

        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <v-sheet
                v-if="!is_exist"
                height="400"
                class="rounded-15"
                style="padding-top:130px;"
            >
                <v-col class="text-center">
                    <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                    <p class="blue--text">존재하지 않는 덱 레시피 입니다</p>
                </v-col>
            </v-sheet>
        
            <div
                v-if="is_exist"
            >
                <!-- # 덱 -->
                <div
                    id="capture"
                    class="rounded-15 white"
                    style="padding:30px 30px;"
                >
                    <!-- # 상단 -->
                    <div
                        class="py-4 d-flex justify-space-between"
                    >
                        <div
                            class="d-flex"
                        >
                            <!-- 이미지 -->
                            <v-card
                                class="rounded-15 elevation-0"
                                style="overflow:hidden;"
                            >
                                <v-card
                                    class="rounded-15 elevation-0 mb-6"
                                    style="position:relative;"
                                >
                                    <v-img
                                        height="100"
                                        width="267"
                                        class="rounded-15"
                                        position="center 30%"
                                        :src="view_image(main_pokemon)"
                                    ></v-img>
                                    <v-sheet
                                        style="position:absolute; bottom:-12px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                        width="180"
                                        height="32"
                                        class="d-flex align-center justify-center mx-auto rounded-20"
                                    >
                                        <div>
                                            <v-img
                                                width="22"
                                                height="15"
                                                class="rounded-4"
                                                :src="'/upload/flags/'+player_country.toLowerCase()+'.svg'"
                                            ></v-img>
                                        </div>
                                        <p
                                            style="font-size:15px; font-weight:bold; color:#424361;"
                                            class="mb-0 ml-1 mr-2 mt-1px"
                                        >
                                            {{nation == 'kr'? computed_player_kr() : (player_local? player_local : computed_player_en())}}
                                        </p>
                                    </v-sheet>
                                </v-card>
                            </v-card>

                            <div
                                class="ml-10 pr-4"
                                style="position:relative;"
                            >
                                <p
                                    style="font-size:30px; font-weight:bold;"
                                    class="mb-0"
                                >
                                    {{deck_name_kr? deck_name_kr : sub_deck_name_kr}}
                                </p>
                                <p
                                    style="font-size:14px; margin-top:-4px;"
                                    class="mb-1 grey--text"
                                >
                                    {{deck_name_en? deck_name_en : sub_deck_name_en}}
                                </p>

                                <p
                                    style="font-size:16px; font-weight:bold;"
                                    class="mb-0"
                                >
                                    {{nation == 'kr'? title_kr : title_en}} <span class="ml-2px icu_purple--text">{{translate_top_cut(top_cut)}}</span>
                                </p>
                                <p
                                    style="font-size:14px; margin-top:-4px;"
                                    class="mb-2 grey--text"
                                >
                                    {{nation == 'kr'? title_en : title_kr}}
                                </p>

                                <div
                                    class="d-flex align-center"
                                >
                                    <p
                                        class="icu_purple--text text-caption font-weight-medium mb-0"
                                        style="cursor:pointer;"
                                        @click="dialog.summary = true"
                                    >
                                        <v-icon size="16" class="pb-2px" color="icu_purple">mdi-open-in-new</v-icon>
                                        한 화면에 모든 카드 보기
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <v-select
                                height="44"
                                style="font-size:18px; width:188px;"
                                class="shrink rounded-15 font-weight-bold mr-3 mb-3"
                                label="카드보기 형식"
                                dense
                                outlined
                                hide-details
                                :items="select_list.view_type"
                                v-model="view_type"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-3 pt-1">{{item}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>

                            <v-select
                                height="44"
                                style="font-size:18px; width:188px;"
                                class="shrink rounded-15 font-weight-bold mr-3"
                                label="국가보기 변경"
                                dense
                                outlined
                                hide-details
                                :items="select_list.nation"
                                v-model="nation"
                                item-text="name"
                                item-value="value"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-3 pt-1">{{item.name}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>

                            <div
                                class="d-flex justify-end mt-3 mr-3"
                            >
                                <v-btn
                                    class="pl-2 pr-4 rounded-15"
                                    outlined
                                    color="#ddd"
                                    style="font-size:14px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                                    depressed
                                    v-ripple="false"
                                    @click="deckExport_handler()"
                                >
                                    <v-sheet height="16" class="d-flex">
                                        <v-img contain src="@/assets/deck_export.svg"></v-img>
                                    </v-sheet>
                                    <span style="color:#424361;">덱 코드 출력</span>
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <v-divider class="mb-4"></v-divider>

                    <!-- # 기본 -->
                    <div
                        v-if="view_type == '기본' && !loading"
                    >
                        <div
                            class="d-flex pa-4 mb-10"
                        >
                            <!-- 포켓몬 -->
                            <div
                                style="margin-right:80px;"
                            >
                                <p
                                    class="mb-2"
                                    style="font-size:15px; color:#F8468D;"
                                >
                                    포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                                </p>
                                <v-sheet
                                    v-for="item in card_list_pokemon" :key="item.id"
                                    class="mt-1"
                                    width="245"
                                >
                                    <div
                                        class="d-flex"
                                    >
                                        <v-menu
                                            offset-x
                                            right
                                            open-on-hover
                                            nudge-right="0"
                                            max-width="320"
                                            content-class="rounded-15"
                                            :close-on-content-click="true"
                                        >
                                            <template v-slot:activator="{ on: menu }">
                                                <v-img
                                                    v-on="{ ...menu }"
                                                    width="258"
                                                    height="50"
                                                    position="center 30%"
                                                    style="position:relative;"
                                                    :src="view_image(item)"
                                                >
                                                    <v-img
                                                        v-if="checkBasicEnergy(item)"
                                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                        class="d-flex"
                                                        width="26"
                                                        height="22"
                                                        src="@/assets/tcg.png"
                                                    ></v-img>
                                                    <div
                                                        class="d-flex"
                                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                        style="position:relative;"
                                                    >
                                                        <p
                                                            v-if="!is_Standard(item)"
                                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                        >
                                                            익스펜디드
                                                        </p>
                                                        <v-sheet
                                                            height="34"
                                                            width="34"
                                                            class="rounded-circle text-center ma-2" 
                                                            style="background:#f8468d"
                                                        >
                                                            <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                        </v-sheet>
                                                        <div
                                                            class="pl-2 mr-1 mt-1 text-truncate"
                                                            style="width:184px;"
                                                        >
                                                            <p
                                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:14px; margin-bottom:0px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:9px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </v-img>
                                            </template>
                                            <Detail
                                                :id="item.id"
                                                :isBoardContent="true"
                                                :nation="nation"
                                            />
                                        </v-menu>
                                    </div>
                                </v-sheet>
                            </div>

                            <!-- 트레이너스 -->
                            <div
                                style="margin-right:80px;"
                            >
                                <p
                                    class="mb-2"
                                    style="font-size:15px; color:#F8468D;"
                                >
                                    트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                                </p>
                                <v-sheet
                                    v-for="item in card_list_trainer" :key="item.id"
                                    class="mt-1"
                                    width="245"
                                >
                                    <div
                                        class="d-flex"
                                    >
                                        <v-menu
                                            offset-x
                                            right
                                            open-on-hover
                                            nudge-right="0"
                                            max-width="320"
                                            content-class="rounded-15"
                                            :close-on-content-click="true"
                                        >
                                            <template v-slot:activator="{ on: menu }">
                                                <v-img
                                                    v-on="{ ...menu }"
                                                    width="258"
                                                    height="50"
                                                    position="center 30%"
                                                    style="position:relative;"
                                                    :src="view_image(item)"
                                                >
                                                    <v-img
                                                        v-if="checkBasicEnergy(item)"
                                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                        class="d-flex"
                                                        width="26"
                                                        height="22"
                                                        src="@/assets/tcg.png"
                                                    ></v-img>
                                                    <div
                                                        class="d-flex"
                                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                        style="position:relative;"
                                                    >
                                                        <p
                                                            v-if="!is_Standard(item)"
                                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                        >
                                                            익스펜디드
                                                        </p>
                                                        <v-sheet
                                                            height="34"
                                                            width="34"
                                                            class="rounded-circle text-center ma-2" 
                                                            style="background:#f8468d"
                                                        >
                                                            <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                        </v-sheet>
                                                        <div
                                                            class="pl-2 mr-1 mt-1 text-truncate"
                                                            style="width:184px;"
                                                        >
                                                            <p
                                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:14px; margin-bottom:0px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:9px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </v-img>
                                            </template>
                                            <Detail
                                                :id="item.id"
                                                :isBoardContent="true"
                                                :nation="nation"
                                            />
                                        </v-menu>
                                    </div>
                                </v-sheet>
                            </div>

                            <!-- 에너지 -->
                            <div
                                style="margin-right:80px;"
                            >
                                <p
                                    class="mb-2"
                                    style="font-size:15px; color:#F8468D;"
                                >
                                    에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                                </p>
                                <v-sheet
                                    v-for="item in card_list_energy" :key="item.id"
                                    class="mt-1"
                                    width="245"
                                >
                                    <div
                                        class="d-flex"
                                    >
                                        <v-menu
                                            offset-x
                                            right
                                            open-on-hover
                                            nudge-right="0"
                                            max-width="320"
                                            content-class="rounded-15"
                                            :close-on-content-click="true"
                                        >
                                            <template v-slot:activator="{ on: menu }">
                                                <v-img
                                                    v-on="{ ...menu }"
                                                    width="258"
                                                    height="50"
                                                    position="center 30%"
                                                    style="position:relative;"
                                                    :src="view_image(item)"
                                                >
                                                    <v-img
                                                        v-if="checkBasicEnergy(item)"
                                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                        class="d-flex"
                                                        width="26"
                                                        height="22"
                                                        src="@/assets/tcg.png"
                                                    ></v-img>
                                                    <div
                                                        class="d-flex"
                                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                        style="position:relative;"
                                                    >
                                                        <p
                                                            v-if="!is_Standard(item)"
                                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                        >
                                                            익스펜디드
                                                        </p>
                                                        <v-sheet
                                                            height="34"
                                                            width="34"
                                                            class="rounded-circle text-center ma-2" 
                                                            style="background:#f8468d"
                                                        >
                                                            <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                        </v-sheet>
                                                        <div
                                                            class="pl-2 mr-1 mt-1 text-truncate"
                                                            style="width:184px;"
                                                        >
                                                            <p
                                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:14px; margin-bottom:0px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:9px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </v-img>
                                            </template>
                                            <Detail
                                                :id="item.id"
                                                :isBoardContent="true"
                                                :nation="nation"
                                            />
                                        </v-menu>
                                    </div>
                                </v-sheet>
                            </div>
                        </div>
                    </div>

                    <!-- # 이미지(전체) -->
                    <div
                        v-if="view_type == '이미지(전체)' && !loading"
                        class="mb-10"
                    >
                        <div
                            class="d-flex align-center pr-2"
                        >
                            <p
                                class="mb-2"
                                style="font-size:15px; color:#424361;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="mb-2 ml-9"
                                style="font-size:15px; color:#424361;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="mb-2 ml-9 mr-10"
                                style="font-size:15px; color:#424361;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                        </div>

                        <!-- 포켓몬 -->
                        <v-row
                            no-gutters
                        >
                            <v-col
                                v-for="(item, index) in card_list_total_view" :key="'전체'+ index"
                                class="pa-1"
                                cols="2"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                :src="view_image(item)"
                                            ></v-img>
                                        </template>
                                        <Detail
                                            :id="item.id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- # 이미지(중복) -->
                    <div
                        v-if="view_type == '이미지(중복)' && !loading"
                        class="mb-10"
                    >
                        <div
                            class="d-flex align-center pr-2"
                        >
                            <p
                                class="mb-2"
                                style="font-size:15px; color:#424361;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="mb-2 ml-9"
                                style="font-size:15px; color:#424361;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="mb-2 ml-9 mr-10"
                                style="font-size:15px; color:#424361;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                        </div>

                        <!-- 포켓몬 -->
                        <v-row
                            no-gutters
                        >
                            <v-col
                                v-for="item in card_list" :key="'중복'+item.id"
                                class="pa-1"
                                cols="2"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <!-- 이미지 -->
                                            <v-card
                                                class="rounded-lg elevation-0"
                                                style="overflow:hidden;"
                                            >
                                                <v-card
                                                    class="rounded-lg elevation-0 mb-6"
                                                    style="position:relative;"
                                                >
                                                    <v-img
                                                        v-on="{ ...menu }"
                                                        width="258"
                                                        class="rounded-lg"
                                                        position="center 30%"
                                                        :src="view_image(item)"
                                                    ></v-img>
                                                    <v-sheet
                                                        style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                        width="72"
                                                        height="32"
                                                        class="d-flex align-center justify-center mx-auto rounded-20"
                                                    >
                                                        <p
                                                            style="font-size:15px; font-weight:bold; color:#424361;"
                                                            class="mb-0 text-center mt-1px"
                                                        >{{item.amount}}</p>
                                                    </v-sheet>
                                                </v-card>
                                            </v-card>
                                        </template>
                                        <Detail
                                            :id="item.id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- # 표(카드포함) -->
                    <div
                        v-if="view_type == '표(카드포함)' && !loading"
                        class="mb-10"
                    >
                        <div
                            class="d-flex align-center pr-2"
                        >
                            <p
                                class="mb-2"
                                style="font-size:15px; color:#424361;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="mb-2 ml-9"
                                style="font-size:15px; color:#424361;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="mb-2 ml-9 mr-10"
                                style="font-size:15px; color:#424361;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                        </div>

                        <v-data-table
                            id="list"
                            hide-default-header
                            :items="card_list"
                            :footer-props="{
                                'items-per-page-options': [-1],
                                'itemsPerPageText': ''
                            }"
                            hide-default-footer
                        >
                            <template v-slot:header>
                                <tr>
                                    <th style="width:80px; padding-left:0px;">이미지</th>
                                    <th style="width:400px;">카드</th>
                                    <th style="width:120px">타입</th>
                                    <th style="width:100px; padding-left:0px; padding:right:0px;">레귤레이션</th>
                                    <th style="width:80px">수량</th>
                                    <th style="width:120px">가격</th>
                                </tr>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td style="width:80px; padding:8px 4px;">
                                        <v-img
                                            v-if="nation=='en'" 
                                            width="48"
                                            class="mx-auto"
                                            :src="'/upload/card_en_image/'+(item.image_en==null? 'default.png':encodeURI(item.image_en))"
                                        ></v-img>
                                        <v-img 
                                            v-if="nation=='kr'"
                                            width="48"
                                            class="mx-auto"
                                            :src="'/upload/card_kr_image/'+(item.image_kr==null? 'default.png':encodeURI(item.image_kr))"
                                        ></v-img>
                                        <v-img 
                                            v-if="nation=='jp'" 
                                            width="48"
                                            class="mx-auto"
                                            :src="'/upload/card_jp_image/'+(item.image_jp==null? 'default.png':encodeURI(item.image_jp))"
                                        ></v-img>
                                    </td>
                                    <td style="width:100%">
                                        <p v-if="nation=='en'" class="mb-0" style="font-size:18px; font-weight:bold;">{{item.name_en}}</p>
                                        <p v-if="nation=='kr'" class="mb-0" style="font-size:18px; font-weight:bold;">{{item.name_kr}}</p>
                                        <p v-if="nation=='jp'" class="mb-0" style="font-size:18px; font-weight:bold;">{{item.name_jp}}</p>
                                        <p class="mb-0 text-caption">
                                            <font v-if="nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                                            <font v-if="nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                                            <font v-if="nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                                            <font v-if="nation=='en' && item.printedTotal_en"> / {{item.printedTotal_en.replaceAll("\"", "")}}</font>
                                            <font v-if="nation=='kr' && item.printedTotal_kr"> / {{item.printedTotal_kr}}</font>
                                            <font v-if="nation=='jp' && item.printedTotal_jp"> / {{item.printedTotal_jp}}</font>
                                            <span class="ml-2">
                                                <font v-if="nation=='en' && item.rarity_en">{{item.rarity_en}}</font>
                                                <font v-if="nation=='kr' && item.rarity_kr">{{item.rarity_kr}}</font>
                                                <font v-if="nation=='jp' && item.rarity_jp">{{item.rarity_jp}}</font>
                                            </span>
                                        </p>
                                        <p v-if="nation=='en' && item.set_en" class="text-caption mb-0">{{item.set_en}}</p>
                                        <p v-if="nation=='kr' && item.set_kr" class="text-caption mb-0">{{item.set_kr}}</p>
                                        <p v-if="nation=='jp' && item.set_jp" class="text-caption mb-0">{{item.set_jp}}</p>
                                    </td>
                                    <td style="width:128px">
                                        <font class="d-block text-center" style="min-width:128px; font-size:18px; font-weight:bold;">
                                            <span v-if="item.supertype == 'Pokémon'">포켓몬</span>
                                            <span v-if="item.supertype == 'Trainer'">트레이너스</span>
                                            <span v-if="item.supertype == 'Energy'">에너지</span>
                                        </font>
                                        <font v-if="item.subtypes" class="d-block text-center" style="min-width:128px; font-size:12px;">
                                            {{computed_subtypes(item)}}
                                        </font>
                                    </td>
                                    <td style="width:100px">
                                        <font class="d-block text-center" style="min-width:100px;">
                                            <v-icon class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                                        </font>
                                    </td>
                                    <td style="width:80px"><font class="d-block text-center" style="min-width:80px;">{{item.amount}}</font></td>
                                    <td style="width:120px"><font class="d-block text-center" style="min-width:120px;">{{item.price? $toComma(item.price):0}}원</font></td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>

                    <!-- # 표(텍스트만) -->
                    <div
                        v-if="view_type == '표(텍스트만)' && !loading"
                        class="mb-10"
                    >
                        <div
                            class="d-flex align-center pr-2"
                        >
                            <p
                                class="mb-2"
                                style="font-size:15px; color:#424361;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="mb-2 ml-9"
                                style="font-size:15px; color:#424361;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="mb-2 ml-9 mr-10"
                                style="font-size:15px; color:#424361;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                        </div>

                        <v-data-table
                            id="list"
                            hide-default-header
                            :items="card_list"
                            :footer-props="{
                                'items-per-page-options': [-1],
                                'itemsPerPageText': ''
                            }"
                            hide-default-footer
                        >
                            <template v-slot:header>
                                <tr>
                                    <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:8px; width:180px;">카드이름</th>
                                    <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:100px;">콜렉션넘버</th>
                                    <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:200px">제품명</th>
                                    <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:120px">희귀도</th>
                                    <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:100px">타입</th>
                                    <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:120px">서브타입</th>
                                    <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:100px">레귤레이션</th>
                                    <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:100px">수량</th>
                                </tr>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="pl-2">
                                        <p v-if="nation=='en'" class="mb-0" style="">{{item.name_en}}</p>
                                        <p v-if="nation=='kr'" class="mb-0" style="">{{item.name_kr}}</p>
                                        <p v-if="nation=='jp'" class="mb-0" style="">{{item.name_jp}}</p>
                                    </td>
                                    <td class="pl-0">
                                        <font v-if="nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                                        <font v-if="nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                                        <font v-if="nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                                    </td>
                                    <td class="pl-0">
                                        <p v-if="nation=='en' && item.set_en" class="text-caption mb-0">{{item.set_en}}</p>
                                        <p v-if="nation=='kr' && item.set_kr" class="text-caption mb-0">{{item.set_kr}}</p>
                                        <p v-if="nation=='jp' && item.set_jp" class="text-caption mb-0">{{item.set_jp}}</p>
                                    </td>
                                    <td class="pl-0">
                                        <font v-if="nation=='en' && item.rarity_en">{{item.rarity_en}}</font>
                                        <font v-if="nation=='kr' && item.rarity_kr">{{item.rarity_kr}}</font>
                                        <font v-if="nation=='jp' && item.rarity_jp">{{item.rarity_jp}}</font>
                                    </td>
                                    <td class="pl-0">
                                        <span v-if="item.supertype == 'Pokémon'">포켓몬</span>
                                        <span v-if="item.supertype == 'Trainer'">트레이너스</span>
                                        <span v-if="item.supertype == 'Energy'">에너지</span>
                                    </td>
                                    <td class="pl-0" v-if="item.subtypes">
                                        {{computed_subtypes(item)}}
                                    </td>
                                    <td>
                                        <v-icon class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                                    </td>
                                    <td class="pl-0">
                                        {{item.amount}}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>

                    <!-- 로딩 -->
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                        class="d-flex my-16 mx-auto"
                        size="100"
                    ></v-progress-circular>
                </div>
            </div>
        </div>

        <!-- # PTCGL 덱 코드 출력 (dialog) -->
        <v-dialog
            v-model="dialog.export"
            content-class="rounded-xl"
            width="480"
        >
            <v-sheet
                class="px-8 py-6"
            >
                <p
                    class="text-center text-h6 font-weight-bold"
                >
                    PTCGL 덱 코드 출력
                </p>
                <v-textarea
                    style="max-height:680px;"
                    class="rounded-lg"
                    outlined
                    rows="16"
                    hide-details
                    v-model="deck_export_text"
                ></v-textarea>

                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        depressed
                        width="120"
                        height="40"
                        color="#F8468D"
                        class="rounded-10 white--text mr-2"
                        @click="deckExport_copy()"
                    >
                        <v-icon small class="mr-1">mdi-content-copy</v-icon>
                        복사
                    </v-btn>
                    <v-btn
                        depressed
                        width="120"
                        height="40"
                        color="#ADAFCA"
                        class="rounded-10 white--text"
                        @click="dialog.export = false"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- # 한 화면에 모든 카드 보기 (dialog) -->
        <v-dialog
            v-model="dialog.summary"
            content-class="rounded-xl"
            width="1600"
        >
            <!-- PC -->
            <v-sheet
                v-if="!$vuetify.breakpoint.mobile"
                class="px-8 py-6"
                style="position:relative;"
            >
                <v-icon style="position:absolute; top:16px; right:16px;" size="24" color="black" @click="dialog.summary = false">mdi-close</v-icon>
                <p
                    class="text-h6 font-weight-bold mb-0"
                >
                    {{title_kr}}
                </p>
                <p
                    class="text-subtitle-1 mb-0"
                >
                    {{title_en}}
                </p>

                <v-sheet
                    class="rounded-10 mt-2 pa-2"
                    outlined
                >
                    <!-- 포켓몬 -->
                    <v-row
                        no-gutters
                    >
                        <v-col
                            v-for="item in card_list" :key="'중복'+item.id"
                            class="px-1"
                            cols="1"
                        >
                            <div
                                class="d-flex"
                            >
                                <!-- 이미지 -->
                                <v-card
                                    class="rounded-lg elevation-0"
                                    style="overflow:hidden;"
                                >
                                    <v-card
                                        class="rounded-lg elevation-0 mb-4"
                                        style="position:relative;"
                                    >
                                        <v-img
                                            class="rounded-lg"
                                            position="center 30%"
                                            :src="view_image(item)"
                                        ></v-img>
                                        <v-sheet
                                            style="position:absolute; bottom:-10px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                            width="44"
                                            height="20"
                                            class="d-flex align-center justify-center mx-auto rounded-20"
                                        >
                                            <p
                                                style="font-size:12px; font-weight:bold; color:#424361;"
                                                class="mb-0 text-center mt-1px"
                                            >{{item.amount}}</p>
                                        </v-sheet>
                                    </v-card>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-sheet>

            <!-- Mobile -->
            <v-sheet
                v-if="$vuetify.breakpoint.mobile"
                class="pa-6"
                style="position:relative;"
            >
                <v-icon style="position:absolute; top:16px; right:16px;" size="24" color="black" @click="dialog.summary = false">mdi-close</v-icon>
                <p
                    class="text-h6 font-weight-bold mb-0"
                >
                    {{title_kr}}
                </p>
                <p
                    class="text-subtitle-1 mb-0"
                >
                    {{title_en}}
                </p>

                <v-sheet
                    class="rounded-10 mt-2 pa-2"
                    outlined
                >
                    <!-- 포켓몬 -->
                    <v-row
                        no-gutters
                    >
                        <v-col
                            v-for="item in card_list" :key="'중복'+item.id"
                            class="px-1"
                            cols="2"
                        >
                            <div
                                class="d-flex"
                            >
                                <!-- 이미지 -->
                                <v-card
                                    class="rounded-lg elevation-0"
                                    style="overflow:hidden;"
                                >
                                    <v-card
                                        class="rounded-lg elevation-0 mb-4"
                                        style="position:relative;"
                                    >
                                        <v-img
                                            class="rounded-lg"
                                            position="center 30%"
                                            :src="view_image(item)"
                                        ></v-img>
                                        <!-- <v-img
                                            class="rounded-lg"
                                            position="center 30%"
                                            src="@/assets/card.png"
                                        ></v-img> -->
                                        <v-sheet
                                            style="position:absolute; bottom:-6px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                            width="24"
                                            height="12"
                                            class="d-flex align-center justify-center mx-auto rounded-20"
                                        >
                                            <p
                                                style="font-size:8px; font-weight:bold; color:#424361;"
                                                class="mb-0 text-center mt-2px"
                                            >{{item.amount}}</p>
                                        </v-sheet>
                                    </v-card>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import Avatar from '@/components/AvatarForDeck'
import TipTapReader from "@/components/tiptap/Reader"
import PointModule from '@/components/PointModule.js'
import Detail from '@/components/card/detailForBoard/Detail'
import html2canvas from 'html2canvas'

export default {
    components: {
        Avatar,
        TipTapReader,
        PointModule,
        Detail
    },

    props: [
        "player_country",
        "player_kr",
        "player_en",
        "player_local",
        "player_age_group",
        "is_masking",
        "deck_name_kr",
        "deck_name_en",
        "sub_deck_name_kr",
        "sub_deck_name_en",
        "deck_card_list",
        "title_kr",
        "title_en",
        "top_cut",
    ],

    data: () => ({
        select_list: {
            view_type: [
                "기본",
                "이미지(전체)",
                "이미지(중복)",
                "표(카드포함)",
                "표(텍스트만)"
            ],

            view_type_mobile: [
                "기본",
                "이미지(전체)",
                "이미지(중복)"
            ],

            nation: [
                {name: "국내판", value: "kr"},
                {name: "북미판", value: "en"},
                {name: "일본판", value: "jp"}
            ],

            draw_amount: [
                { name: "1장", value: 1 },
                { name: "2장", value: 2 },
                { name: "3장", value: 3 },
                { name: "4장", value: 4 },
                { name: "5장", value: 5 },
                { name: "6장", value: 6 },
                { name: "7장", value: 7 },
                // { name: "전부", value: 60 },
            ],

            renewal: {
                en: [],
                kr: [],
                jp: []
            },
        },

        // 덱 기본 정보
        deck: {
            deck_name: "",
            deck_name_kr: "",
            title: "",
            writer: "",
            image: "",
            image_en: "",
            image_kr: "",
            image_jp: "",
            writer_nickname: "",
            regulation_group: "",
            deck_nation: "",
            size: "",
            deck_code: "",
            status: "",
            created: "",
            view_count: 0,
            like_count: 0,
            copy_count: 0,
            rating_avg: 0,
            rating_count: 0,
            tag: "",
            original_id: null
        },

        original_deck: {
            title: "",
            writer_nickname: "",
            regulation_group: "",
            deck_nation: "",
            size: "",
            deck_code: "",
            status: "",
            created: "",
        },

        nation_list: [
            {
                text: "국내판",
                value: "kr"
            },
            {
                text: "북미판",
                value: "en"
            },
            {
                text: "일본판",
                value: "jp"
            }
        ],

        copy_nation: "",


        view_type: "기본",
        nation: "kr",
        sort: "기본",


        // 덱 카드 목록
        card_list: [],

        // 덱 속성 목록
        type_list: [],

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        standard_list: [],

        dialog: {
            deck_test: false,
            copy:false,
            export: false,
            summary: false
        },

        draw_amount: 1,

        card_list_hand: [],
        card_list_prize: [],
        card_list_draw: [],

        like_list: [],

        componentKey: 0,

        supertype: {
            Energy: [],
            Pokémon: [],
            Trainer: []
        },

        is_exist:true,
        deck_export_text: "",

        loading: false,

        main_pokemon: {}
    }),

    computed: {
        // 재수록카드 목록
        renewal_list(){
            switch(this.nation){
                case 'en' : return this.select_list.renewal.en
                case 'kr' : return this.select_list.renewal.kr
                case 'jp' : return this.select_list.renewal.jp
            }
        },

        // 선택한 목록 > 포켓몬
        card_list_pokemon() {
            console.log(this.card_list)
            return this.card_list.filter((e) => e.supertype == 'Pokémon')
        },

        // 선택한 목록 > 트레이너스
        card_list_trainer() {
            return this.card_list.filter((e) => e.supertype == 'Trainer')
        },

        // 선택한 목록 > 에너지
        card_list_energy() {
            return this.card_list.filter((e) => e.supertype == 'Energy')
        },

        card_list_amount() {
            return this.card_list.reduce((sum, item) => sum + (item.amount || 1), 0);
        },

        // 카드 보기 형식 > 이미지(전체)
        card_list_total_view(){
            let result = []
            for (let card of this.card_list) {
                for (let i = 0; i < card.amount; i++) {
                    // 객체를 복사하여 결과 배열에 추가
                    result.push({ ...card })
                }
            }
            return result
        },
    },

    mounted(){
        // 덱 카드 목록 불러오기
        this.loadDeckCardList()

        // 레귤레이션 스탠다드 가져오기
        this.load_standard_list()

        // 카드종류(supertype)별 subtypes list 불러오기
        this.load_supertype_list()

        // 재수록카드 목록 불러오기
        this.loadRenewalList()

        console.log("player_country", this.$eventToObservableplayer_country)
        console.log("player_kr", this.$eventToObservableplayer_kr)
        console.log("player_en", this.$eventToObservableplayer_en)
        console.log("player_local", this.$eventToObservableplayer_local)
        console.log("player_age_group", this.$eventToObservableplayer_age_group)
        console.log("is_masking", this.$eventToObservableis_masking)
    },

    methods: {
        // 탑컷 영어 번역
        translate_top_cut(top_cut){
            if(this.nation == 'kr'){
                return top_cut
            }
            else{
                if(top_cut == "1등") return "1st"
                else if(top_cut == "2등") return "2nd"
                else if(top_cut == "3등") return "3rd"
                else if(top_cut == "4강") return "T4"
                else if(top_cut == "8강") return "T8"
                else if(top_cut == "16강") return "T16"
                else if(top_cut == "32강") return "T32"
                else if(top_cut == "64강") return "T64"
                else if(top_cut == "128강") return "T128"
                else if(top_cut == "256강") return "T256"
                else if(top_cut == "예선") return "Entry"
            }
        },

        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },

        // PTCGO 마크 추가시 확인용
        checkBasicEnergy(item) {
            try {
                const subtypes = JSON.parse(item.subtypes)
                return item.ptcgoCode != null || (item.supertype == 'Energy' && Array.isArray(subtypes) && subtypes.includes('Basic'))
            } catch (e) {
                return false
            }
        },

        // 덱 출력 핸들러
        deckExport_handler(){
            // null이 없을 경우
            if("every", this.card_list.every(e => e.ptcgoCode != null))
            {
                this.dialog.export = true

                this.deck_export_text = ""

                this.deck_export_text +=  "Pokémon: " + this.card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for (let card of this.card_list_pokemon){
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"

                this.deck_export_text +=  "Trainer: " + this.card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for (let card of this.card_list_trainer){
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"

                this.deck_export_text +=  "Energy: " + this.card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for (let card of this.card_list_energy){
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"

                this.deck_export_text +=  "Total Cards: 60"

                this.$copyText(this.deck_export_text)
            }
            // null이 있을 경우
            else
            {
                alert("PTCGL에 출시 되지 않은 카드가 있어 출력이 불가능합니다.\n다른 카드로 변경해주세요. (카드 우측 하단의 PTCGL 마크를 확인해주세요)")
            }
        },

        convertName(){
            // 없는 값 들어오면 replace 안하고 return
            if(!text){
                return text
            }

            // 에너지 > 중괄호 변환
            if(text.includes("Energy")){
                text = text.replace("Grass", "{G}")
                text = text.replace("Fire", "{R}")
                text = text.replace("Water", "{W}")
                text = text.replace("Lightning", "{L}")
                text = text.replace("Psychic", "{P}")
                text = text.replace("Fighting", "{F}")
                text = text.replace("Darkness", "{D}")
                text = text.replace("Metal", "{M}")
            }

            // 에너지 > Basic 값 제거
            text = text.replace("Basic", "")

            // 괄호로 묶인 내용을 제거 (괄호 포함)
            text = text.replace(/\(.*?\)/g, '')

            // 앞뒤 여백 제거
            text = text.trim()

            return text
        },

        deckExport_copy(){
            // console.log(this.deck_export_text)
            // this.$copyText(this.deck_export_text)
            alert("PTCGL 덱 코드가 복사되었습니다")
        },

        // 재수록카드 목록 불러오기
        loadRenewalList(){
            // 한글
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })

            // 영어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.renewal.en = res.data.map(a => a.card_id)
            })

            // 일어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })
        },

        // 카드종류(supertype)별 subtypes list 불러오기
        load_supertype_list(){
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.supertype.Energy = res.data.filter(e => e.category == "Energy")
                this.supertype.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.supertype.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        },

        // Subtypes 매칭
        computed_subtypes(item){
            // console.log(item)
            if(item.supertype == "Energy")
            {
                // console.log(this.supertype.Energy.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Energy.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
            else if(item.supertype == "Pokémon")
            {
                // console.log(this.supertype.Pokémon.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Pokémon.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
            else if(item.supertype == "Trainer")
            {
                // console.log(this.supertype.Trainer.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Trainer.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
        },

        getRandomElements(arr, count) {
            // 배열 복사 및 셔플
            const shuffled = [...arr].sort(() => 0.5 - Math.random())
            // 상위 'count' 개의 요소 반환
            return shuffled.slice(0, count)
        },

        // 레귤레이션 스탠다드 가져오기
        load_standard_list(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.standard_list = res.data
                    .filter(e => e.type === '스탠다드')
                    .map(e => e.regulation_mark)
            })
        },

        // 스탠다드인지 확인
        is_Standard(item){
            if(this.renewal_list.includes(item.id))
            {
                return true
            }
            else
            {
                if(item.supertype == "Energy" && item.subtypes != null)
                {
                    if(JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null)))
                    {
                        return true
                    }
                    else
                    {
                        if(this.standard_list.includes(item.regulationMark))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                }
                else
                {
                    if(this.standard_list.includes(item.regulationMark))
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                }
            }
        },
        
        // 이미지 표시
        view_image(item){
            // if(!item.image_kr || item.image_kr=='default.png'){
            //     if(item.image_en!=null && item.image_en!='default.png'){
            //         return '/upload/card_en_image/'+encodeURI(item.image_en)
            //     }
            //     else if(item.image_jp!=null || item.image_jp!='default.png'){
            //         return '/upload/card_jp_image/'+encodeURI(item.image_jp)
            //     }
            //     else {
            //         return "@/assets/default.png"
            //     }
            // }
            // else if(item.image_kr && item.image_kr!='default.png'){
            //     return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            // }

            if(this.nation == "kr"){
                if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }

            else if(this.nation == "jp")
            {
                if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }

            else if(this.nation == "en")
            {
                if(item.image_en != "default.png")
                {
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_kr != "default.png")
                {
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_jp != "default.png")
                {
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else
                {
                    return "@/assets/default.png"
                }
            }
        },

        // 덱 카드 목록 불러오기
        async loadDeckCardList(){
            this.loading = true
            this.card_list = []
            console.log(JSON.parse(this.deck_card_list))

            let imported_card_list = await JSON.parse(this.deck_card_list)

            console.log(imported_card_list)

            if(!imported_card_list){
                this.loading = false
                return
            }

            for(let i=0; i<imported_card_list.length;i++){
                await this.$http.post("/api/new/deck/select/viewDeck", {
                    params: {
                        card_id: imported_card_list[i].id,
                        amount: imported_card_list[i].amount,
                    }
                }).then(async(res) => {
                    if(res.data.length){
                        console.log("card_list", res)
                        await this.card_list.push(res.data[0])
                    }
                })
            }

            console.log(this.card_list)
            this.main_pokemon = this.card_list[0]
            await this.sortList()

            console.log(this.card_list)


            // 선택한 목록 > 포켓몬
            console.log(this.card_list_pokemon)
            // 선택한 목록 > 트레이너스
            console.log(this.card_list_trainer)

            // 선택한 목록 > 에너지
            console.log(this.card_list_energy)

            console.log(this.card_list_amount)

            // 카드 보기 형식 > 이미지(전체)
            console.log(this.card_list_total_view)

            this.componentKey++

            this.loading = false
        },

        // 덱 카드 목록 불러오기
        // async loadDeckCardList() {
        //     this.loading = true
        //     this.card_list = []
            
        //     let imported_card_list
        //     try {
        //         imported_card_list = JSON.parse(this.deck_card_list)
        //         console.log(imported_card_list)
        //     } catch (e) {
        //         console.error("Failed to parse deck_card_list:", e)
        //         this.loading = false
        //         return
        //     }

        //     for (let i = 0; i < imported_card_list.length; i++) {
        //         const card = imported_card_list[i]
        //         if (!card || !card.id) {
        //             console.warn(`Skipping invalid card at index ${i}:`, card)
        //             continue
        //         }

        //         try {
        //             const res = await this.$http.post("/api/new/deck/select/viewDeck", {
        //                 params: {
        //                     card_id: card.id,
        //                     amount: card.amount,
        //                 }
        //             })
        //             console.log("card_list", res.data[0])
        //             this.card_list.push(res.data[0])
        //         } catch (e) {
        //             console.error(`Failed to load card with id ${card.id}:`, e)
        //         }
        //     }

        //     await this.sortList()
        //     this.main_pokemon = this.card_list[0]
        //     this.loading = false
        // },

        // 정렬
        sortList(){
            console.log(this.card_list)
            this.card_list = 
                [
                    ...this.card_list.filter((e) => e.supertype == 'Pokémon'),
                    ...this.card_list.filter((e) => e.supertype == 'Trainer'),
                    ...this.card_list.filter((e) => e.supertype == 'Energy')
                ]
            console.log(this.card_list)
        },

        // computed_player_kr() {
        //     if(this.player_local){
        //         return this.player_local
        //     }
        //     else{
        //         if(this.player_country != "KR"){
        //             return this.player_kr
        //         }else{
        //             if(this.player_age_group != '시니어' && this.player_age_group != '주니어'){
        //                 return this.player_kr
        //             }else{
        //                 return this.player_kr[0] + '✱' + this.player_kr.slice(2)
        //             }
        //         }
        //     }
        // },

        // computed_player_en() {
        //     if(this.player_country != "KR"){
        //         return this.player_en
        //     }else{
        //         if(this.player_age_group != '시니어' && this.player_age_group != '주니어'){
        //             return this.player_en
        //         }else{
        //             return this.player_en.split(' ')[0] + ' *'
        //         }
        //     }
        // },
        
        computed_player_kr(item) {
            console.log(this.is_masking)
            if(this.player_local){
                return this.player_local
            }
            else{
                if(this.player_country != "KR"){
                    return this.player_kr
                }else{
                    if(this.is_masking != null){
                        if(this.is_masking){
                            return this.player_kr[0] + '✱' + this.player_kr.slice(2)
                        }else{
                            return this.player_kr
                        }
                    }
                    else{
                        if(this.player_age_group != '시니어' && this.player_age_group != '주니어'){
                            return this.player_kr
                        }else{
                            return this.player_kr[0] + '✱' + this.player_kr.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(this.player_country != "KR"){
                return this.player_en
            }else{
                if(this.is_masking != null){
                    if(this.is_masking){
                         return this.player_en.split(' ')[0] + ' *'
                    }else{
                        return this.player_en
                    }
                }
                else{
                    if(this.player_age_group != '시니어' && this.player_age_group != '주니어'){
                        return this.player_en
                    }else{
                         return this.player_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>
<style scoped>
.v-btn::before {
  background-color: transparent;
}

.gradient-overlay-expanded {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #ff521c, transparent);
  position: relative;
}

.gradient-overlay-standard {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #232323, transparent);
  position: relative;
}


.mobile .v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:10px;
    font-weight:400;
}

/* v-text-field, v-select, v-autocomplete > 전체 높이 지정 */
::v-deep .mobile .v-text-field .v-input__slot { 
    height: 34px !important;
}

/* mobile 테두리 실질 */ 
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 0 0 10px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}
</style>
