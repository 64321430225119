<script>
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
    extends: Doughnut,

    props: [
        "list"
    ],

    data: () => ({

    }),

    async mounted() {
        this.createGraph()
    },

    methods: {
        calculateProbability(count, totalNumber) {
            if (count == null || totalNumber == null || isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0 // 확률을 계산할 수 없으므로 0%로 표시
            }

            const probability = (count / totalNumber) * 100 // 확률 계산 (백분율로 표시)
            return parseInt(probability) // 소수점 두 자리까지 표시
        },

        getBackgroundColors() {
			const colors = [
				'#377eae', '#429aaa', '#5ab3a1', '#79c69c', '#99d59a',
				'#b8e297', '#d4ed96', '#e9f69d', '#f6f9a8', '#fdf3a3',
				'#fee590', '#fed07b', '#fdb865', '#fb9c54', '#f67d46',
				'#ee613f', '#e0493e', '#cc3140'
			]
			return this.list.map((_, index) => index < colors.length ? colors[index] : '#ddd')
		},

        createGraph() {
            console.log(this.list)
            this.addPlugin(ChartDataLabels)
            this.renderChart(
                {
                    labels: this.list.map(e => e.deck_kr ? e.deck_kr : e.deck_en),
                    datasets: [
                        {
                            data: this.list.map(e => e.deck_count),
                            backgroundColor: this.getBackgroundColors(),
                            borderWidth: 5,
                            borderColor: '#ffffff'
                        }
                    ]
                },
                // {
                //     plugins: [ChartDataLabels],
                //     responsive: true,
                //     maintainAspectRatio: false,
                //     legend: {
                //         display: false
                //     },
                //     options: {
                //         borderWidth: 10,
                //         offset: 10,
                //         spacing: 20,
                //         plugins: {
                //             datalabels: {
                //                 color: '#fff',
                //                 textAlign: 'center',
                //                 font: {
                //                     weight: 'bold'
                //                 },
                //                 formatter: (value, context) => {
                //                     return this.list.map(e => e.deck_en)
                //                 }
                //             }
                //         }
                //     }
                // },

                {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: { display: false },
                    plugins: {
                        // datalabels: {
                        //     color: '#fff',
                        //     textAlign: 'center',
                        //     font: {
                        //         weight: 'bold',
                        //         size: 10,
                        //     },
                        //     formatter: (value, context) => {
                        //         return context.chart.data.labels[context.dataIndex]
                        //     }
                        // }

                        datalabels: {
                            labels: {
                                name: {
                                    color: 'black',
                                    align: 'bottom',
                                    font: { size: 16 },
                                    formatter: (value, ctx) => {
                                        const sum = ctx.dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                        return this.calculateProbability(ctx.dataset.data[ctx.dataIndex], sum) + "%"
                                    }
                                },

                                value: {
                                    align: 'top',
                                    font: {size: 14, weight: 'bold'},
                                    backgroundColor: function(ctx) {
                                        var value = ctx.dataset.data[ctx.dataIndex];
                                        return 'white';
                                    },

                                    borderColor: '#000000',
                                    borderWidth: 2,
                                    borderRadius: 4,
                                    color: 'black',
                                    // color: function(ctx) {
                                    // var value = ctx.dataset.data[ctx.dataIndex]
                                    //     return ctx.dataset.backgroundColor
                                    // },

                                    // // 글자 (기존)
                                    // formatter: function(value, ctx) {
                                    //     return ctx.chart.data.labels[ctx.dataIndex].replace(" ", "\n") // 줄바꿈 추가
                                    // },

                                    // 2% 이하 표시하지 않기
                                    formatter: (value, ctx) => {
                                        const sum = ctx.dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                                        const probability = this.calculateProbability(ctx.dataset.data[ctx.dataIndex], sum)
                                        
                                        if (probability <= 2) {
                                            return null // 2% 이하일 경우 레이블을 표시하지 않음
                                        }
                                        
                                        return ctx.chart.data.labels[ctx.dataIndex].replace(" ", "\n") // 줄바꿈 추가
                                    },

                                    padding: 4
                                }
                            }
                        }
                    }
                }
            )
        }
    }
}
</script>