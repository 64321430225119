<template>
    <div>
        <Mobile
            v-if="$vuetify.breakpoint.mobile"
            :from="from"
        />

        <Pc
            v-if="!$vuetify.breakpoint.mobile"
            :from="from"
        />
    </div>
</template>
<script>
import Mobile from "./detail/Mobile"
import Pc from "./detail/Pc"

export default {
    components: {
        Mobile,
        Pc
    },

    data: () => ({
        from: null
    }),

    beforeRouteEnter(to, from, next) {
        next(vm => {
            // beforeRouteEnter에서는 this 대신 vm 사용
            vm.from = from
        })
    }
}
</script>