<template>
    <div
        style="position:relative;"
    >
        <!-- # PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="rounded-15 px-10 pt-6 pb-4"
            color="#F8F7FB"
            style="border:2px solid #1DA1F2; position:relative;"
        >
            <!-- # 제목 -->
            <p class="mb-2 font-weight-bold" style="font-size:20px;">
                플레이어 입력
            </p>

            <!-- # 공식대회 제목 -->
            <p class="mb-2" style="font-size:15px; font-weight:500; color:#23D2E2;">
                {{title_kr}}
            </p>

            <!-- # Excel 입력 -->
            <v-btn
                style="position:absolute; top:20px; right:40px;"
                width="40"
                height="40"
                class="rounded-10"
                depressed
                color="#adafca"
                @click="triggerFileInput"
            >
                <v-icon color="white">mdi-microsoft-excel</v-icon>
            </v-btn>

            <!-- # Excel 입력용 (숨김 처리) -->
            <input
                type="file"
                ref="fileInput"
                @change="readExcelFile"
                style="display: none;"
            >

            <!-- # 입력 목록 -->
            <v-sheet class="py-4 px-2 mt-3 rounded-10">
                <!-- # 행 제목 -->
                <div
                    class="d-flex"
                >
                    <div
                        style="width:340px;"
                        class="pl-4"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        플레이어 이름
                    </div>

                    <div
                        style="width:100px;"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        순위
                    </div>

                    <div
                        style="width:280px;"
                    >
                        <v-icon size="8" color="#b7b7c5" class="pb-2px mr-1">mdi-circle</v-icon>
                        분류값
                    </div>

                    <div
                        v-if="league_type == '카드샵대항전'"
                        style="width:120px;"
                    >
                        <v-icon size="8" color="#b7b7c5" class="pb-2px mr-1">mdi-circle</v-icon>
                        카드샵
                    </div>

                    <div
                        style="width:300px;"
                    >
                        <v-icon size="8" color="#b7b7c5" class="pb-2px mr-1">mdi-circle</v-icon>
                        DAY1
                    </div>

                    <div
                        style="width:300px;"
                    >
                        <v-icon size="8" color="#b7b7c5" class="pb-2px mr-1">mdi-circle</v-icon>
                        DAY2
                    </div>
                </div>

                <v-divider class="mt-3 mb-1"></v-divider>

                <!-- # 입력행 -->
                <div
                    v-if="list != undefined"
                    :key="componentKey"
                >
                    <div
                        class="d-flex"
                        v-for="(item, index) in list" :key="index"
                        style="border-bottom:1px solid #eee;"
                    >
                        <!-- # 플레이어 이름 -->
                        <div
                            style="width:340px;"
                            class="d-flex align-center pl-2 pr-4"
                        >
                            <!-- # 플레이어 이름 (kr, en, local) -->
                            <div
                                style="width:170px;"
                            >
                                <v-text-field
                                    solo
                                    flat
                                    dense
                                    hide-details
                                    placeholder="플레이어 이름(한글)"
                                    v-model="item.player_kr"
                                    @change="check_player(item, 'kr')"
                                ></v-text-field>
                                <v-text-field
                                    solo
                                    flat
                                    dense
                                    hide-details
                                    placeholder="플레이어 이름(영어)"
                                    v-model="item.player_en"
                                    @change="check_player(item, 'en')"
                                ></v-text-field>
                                <v-text-field
                                    solo
                                    flat
                                    dense
                                    hide-details
                                    placeholder="플레이어 이름(현지)"
                                    v-model="item.player_local"
                                    @change="check_player(item, 'local')"
                                ></v-text-field>
                            </div>

                            <!-- # 플레이어 추가 정보 (UID, 국가, 유형) -->
                            <div
                                class="text-center d-flex justify-center align-center flex-grow-1 px-2"
                                style="font-weight:bold;"
                            >
                                <div>
                                    UID<br/>
                                    <span v-if="item.uid == 0">생성전</span>
                                    <span v-if="item.uid != 0 && item.uid_list && item.uid_list.length == 1">{{item.uid}}</span>
                                    <v-select
                                        v-if="item.uid_list && item.uid_list.length > 1"
                                        style="width:130px;"
                                        class="shrink"
                                        hide-details
                                        dense
                                        flat
                                        solo
                                        :items="item.uid_list"
                                        v-model="item.uid"
                                        @change="uid_updated(item)"
                                    >
                                        <template v-slot:append>
                                            <v-icon class="ma-0 pa-0" color="#424361">mdi-chevron-down</v-icon>
                                        </template>
                                    </v-select>
                                    <v-autocomplete
                                        class="shrink ml-1"
                                        style="width:106px;"
                                        :items="select_list.country"
                                        v-model="item.player_country"
                                        item-text="country_kr"
                                        item-value="code"
                                        solo
                                        dense
                                        flat
                                        hide-details
                                        placeholder="국가"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        class="shrink ml-1"
                                        style="width:106px;"
                                        :items="select_list.age_group"
                                        v-model="item.player_age_group"
                                        solo
                                        dense
                                        flat
                                        hide-details
                                        placeholder="유형"
                                    ></v-autocomplete>
                                </div>
                            </div>
                        </div>

                        <!-- # 순위 -->
                        <div
                            style="width:100px;"
                            class="d-flex align-center"
                        >
                            <v-text-field
                                v-model="item.standing"
                                solo
                                flat
                                hide-details
                                type="number"
                                placeholder="순위"
                            ></v-text-field>
                        </div>

                        <!-- # 분류값 -->
                        <div
                            style="width:280px;"
                            class="d-flex align-center text-caption"
                        >
                            <div>
                                <!-- # DAY 1 -->
                                <div class="d-flex align-center">
                                    <font class="icu_purple--text font-weight-medium">DAY 1 :</font>
                                    <v-autocomplete
                                        :items="deck_identifier_list"
                                        v-model="item.deck_identifier1"
                                        solo
                                        class="shrink"
                                        style="width:220px;"
                                        flat
                                        dense
                                        hide-details
                                        :item-text="(item) => item.kr ? `${item.kr} (${item.identifier})` : item.identifier"
                                        item-value="identifier"
                                        placeholder="덱 분류"
                                    >
                                        <template v-slot:selection="{ item }">
                                            {{ item.kr ? `${item.kr} (${item.identifier})` : item.identifier }}
                                        </template>
                                        <template v-slot:append>
                                            <v-icon color="#424361">mdi-chevron-down</v-icon>
                                        </template>  
                                    </v-autocomplete>
                                </div>

                                <!-- # DAY 2 -->
                                <div v-if="item.deck_day2 != 0" class="d-flex align-center">
                                    <font class="icu_purple--text font-weight-medium">DAY 2 :</font>
                                    <v-autocomplete
                                        :items="deck_identifier_list"
                                        v-model="item.deck_identifier2"
                                        solo
                                        class="shrink"
                                        style="width:220px;"
                                        flat
                                        dense
                                        hide-details
                                        :item-text="(item) => item.kr ? `${item.kr} (${item.identifier})` : item.identifier"
                                        item-value="identifier"
                                        placeholder="덱 분류"
                                    >
                                        <template v-slot:selection="{ item }">
                                            {{ item.kr ? `${item.kr} (${item.identifier})` : item.identifier }}
                                        </template>
                                        <template v-slot:append>
                                            <v-icon color="#424361">mdi-chevron-down</v-icon>
                                        </template>  
                                    </v-autocomplete>
                                </div>
                            </div>
                        </div>

                        <!-- # 카드샵 (카드샵대항전) -->
                        <div
                            v-if="league_type == '카드샵대항전'"
                            style="width:120px;"
                            class="d-flex align-center"
                        >
                            <v-autocomplete
                                :items="select_list.cardshop"
                                v-model="item.cardshop"
                                solo
                                flat
                                hide-details
                                placeholder="카드샵"
                            ></v-autocomplete>
                        </div>

                        <!-- # DAY 1 -->
                        <div
                            style="width:300px;"
                            class="px-2"
                        >
                            <div
                                class="d-flex align-center justify-space-between"
                                style="margin-top:-8px; margin-bottom:-8px;"
                            >
                                <v-checkbox
                                    v-model="item.is_record_day1"
                                    :label="item.is_record_day1? '전적':'승점'"
                                ></v-checkbox>
                                <v-text-field
                                    class="rounded-10 ml-4"
                                    v-model="item.deck_point1"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    placeholder="승점"
                                ></v-text-field>
                            </div>

                            <div
                                class="d-flex justify-space-between align-center"
                            >
                                <v-text-field
                                    class="rounded-10"
                                    v-model="item.deck_win1"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    placeholder="승"
                                ></v-text-field>
                                <span class="mx-2 font-weight-bold">-</span>
                                <v-text-field
                                    class="rounded-10"
                                    v-model="item.deck_lose1"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    placeholder="패"
                                ></v-text-field>
                                <span class="mx-2 font-weight-bold">-</span>
                                <v-text-field
                                    class="rounded-10"
                                    v-model="item.deck_tie1"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    placeholder="무"
                                ></v-text-field>
                            </div>

                            <v-textarea
                                class="rounded-10 mt-1 mb-2"
                                v-model="item.deck_day1"
                                outlined
                                dense
                                hide-details
                                rows="1"
                                placeholder="PTCGL"
                            ></v-textarea>
                        </div>

                        <!-- # DAY 2 -->
                        <div
                            style="width:300px;"
                            class="px-2"
                        >
                            <div
                                class="d-flex align-center justify-space-between"
                                style="margin-top:-8px; margin-bottom:-8px;"
                            >
                                <v-checkbox
                                    v-model="item.is_record_day2"
                                    :label="item.is_record_day2? '전적':'승점'"
                                ></v-checkbox>
                                <v-text-field
                                    class="rounded-10 ml-4"
                                    v-model="item.deck_point2"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    placeholder="승점"
                                ></v-text-field>
                            </div>

                            <div
                                class="d-flex justify-space-between align-center"
                            >
                                <v-text-field
                                    class="rounded-10"
                                    v-model="item.deck_win2"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    placeholder="승"
                                ></v-text-field>
                                <span class="mx-2 font-weight-bold">-</span>
                                <v-text-field
                                    class="rounded-10"
                                    v-model="item.deck_lose2"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    placeholder="패"
                                ></v-text-field>
                                <span class="mx-2 font-weight-bold">-</span>
                                <v-text-field
                                    class="rounded-10"
                                    v-model="item.deck_tie2"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    placeholder="무"
                                ></v-text-field>
                            </div>

                            <v-textarea
                                class="rounded-10 mt-1 mb-2"
                                v-model="item.deck_day2"
                                outlined
                                dense
                                hide-details
                                rows="1"
                                placeholder="PTCGL"
                            ></v-textarea>
                        </div>

                        <!-- # 행 삭제 -->
                        <div
                            class="d-flex align-center"
                        >
                            <div>
                                <div>
                                    <v-btn
                                        class="px-1"
                                        text
                                        @click="subtractField(index)"
                                    >
                                        <v-icon color="red">mdi-close</v-icon>
                                    </v-btn>
                                </div>

                                <v-btn
                                    v-if="selected_index != index"
                                    :disabled="selected_index && selected_index != index"
                                    class="rounded-5 text-caption mt-1"
                                    height="32"
                                    width="32"
                                    color="icu_purple"
                                    style="padding-right:14px;"
                                    small
                                    depressed
                                    dark
                                    @click="deck_classify(index)"
                                >
                                    덱
                                </v-btn>

                                <v-btn
                                    v-if="selected_index == index"
                                    class="rounded-5 text-caption mt-1"
                                    height="32"
                                    width="32"
                                    color="grey lighten-4"
                                    style="padding-right:14px;"
                                    small
                                    depressed
                                    dark
                                >
                                    <!-- 로딩 -->
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                        size="16"
                                        width="2"
                                    ></v-progress-circular>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- # 버튼 -->
                <div
                    class="d-flex justify-space-between align-center mt-4 px-4"
                >
                    <!-- # Excel 입력 후 채우기 -->
                    <div
                        style="width:200px;"
                        class="d-flex"
                    >
                        <v-btn
                            outlined
                            small
                            style="width:60px;"
                            class="rounded-xl d-flex mx-auto"
                            color="#B3B3C2"
                            @click="check_player_all('kr')"
                        >
                            <span style="color:#424361; font-weight:500; font-size:10px;">KR</span>
                        </v-btn>
                        <v-btn
                            outlined
                            small
                            style="width:60px;"
                            class="rounded-xl d-flex mx-auto"
                            color="#B3B3C2"
                            @click="check_player_all('en')"
                        >
                            <span style="color:#424361; font-weight:500; font-size:10px;">EN</span>
                        </v-btn>
                        <v-btn
                            outlined
                            small
                            style="width:60px;"
                            class="rounded-xl d-flex mx-auto"
                            color="#B3B3C2"
                            @click="check_player_all('local')"
                        >
                            <span style="color:#424361; font-weight:500; font-size:10px;">LOCAL</span>
                        </v-btn>
                    </div>

                    <!-- # 행 추가 -->
                    <v-btn
                        outlined
                        class="rounded-xl d-flex mx-auto px-6"
                        color="#B3B3C2"
                        @click="addField()"
                    >
                        <v-icon color="primary">mdi-plus</v-icon>
                        <span style="color:#424361; font-weight:500;">추가</span>
                    </v-btn>

                    <!-- # 덱 분류하기 -->
                    <div
                        style="width:200px;"
                        class="d-flex justify-end pr-8"
                    >
                        <v-btn
                            outlined
                            small
                            style="width:120px;"
                            class="rounded-xl d-flex"
                            color="#B3B3C2"
                            @click="ptcgl_deck_insert()"
                        >
                            <span style="color:#424361; font-weight:500; font-size:10px;">덱 분류하기</span>
                        </v-btn>
                    </div>
                </div>
            </v-sheet>

            <!-- # 버튼 -->
            <div class="mt-4 pa-2 d-flex justify-center">
                <!-- # 제출 -->
                <v-btn
                    width="110"
                    height="40"
                    dark
                    depressed
                    color="icu_purple"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    입력
                </v-btn>

                <!-- # 취소 -->
                <v-btn
                    width="110"
                    height="40"
                    depressed
                    dark
                    color="#B3B3C2"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>

        <!-- # 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; bottom: 200px; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="140"
        ></v-progress-circular>
    </div>
</template>
<script>
import * as XLSX from 'xlsx'

export default {
    props: [
        "title_kr",
        "season_nation",
        "season_name",
        "league_type"
    ],

    data: () => ({
        select_list: {
            player_kr: [],
            player_en: [],
            player_local: [],
            country: [],

            cardshop: [
                "역삼",
                "수원",
                "서면",
                "원주",
                "인천",
                "광주",
                "평택",
                "울산",
                "대구",
                "용산",
            ],

            renewal: {
                en: [],
                kr: [],
                jp: []
            },

            age_group: [
                "전체연령",
                "마스터",
                "시니어",
                "주니어"
            ],
        },

        player_list: [],

        list: [
            {
                player_kr: "",
                player_en: "",
                player_local: "",
                player_country: "",
                player_age_group: "전체연령",
                uid: 0,
                uid_list: [],
                standing: 0,
                top_cut: "",
                cardshop: "",
                is_record_day1: false,
                deck_day1: null,
                deck_win1: 0,
                deck_lose1: 0,
                deck_tie1: 0,
                deck_point1: 0,
                deck_identifier1: "",
                deck_card_list1: "",
                is_record_day2: false,
                deck_day2: null,
                deck_win2: 0,
                deck_lose2: 0,
                deck_tie2: 0,
                deck_point2: 0,
                deck_identifier2: "",
                deck_card_list2: "",
            }
        ],

        /* PTCGL 덱 생성 */
        deck_import_text: "",
        selected_list: [],

        selected_item: {},
        selected_index: null,

        day_type: "DAY1",
        excelData: [],
        componentKey: 0,
        first_list_length: 0,
        deck_type: 60,
        selected_day: 1,

        loading: false,

        deck_identifier_list: [],
    }),

    computed: {
        computed_nation(){
            if(this.season_nation == "국내판"){
                return "kr"
            }
            else if(this.season_nation == "북미판"){
                return "en"
            }
            else if(this.season_nation == "일본판"){
                return "jp"
            }
        },

        selected_list_amount() {
            return this.selected_list.reduce((sum, item) => sum + (item.amount || 1), 0)
        },

        // 레귤레이션 그룹
        regulationGroup() {
            return this.selected_list.every(item =>
                    this.renewal_list.includes(item.id)
                ||
                    this.standard_list.includes(item.regulationMark)
                ||
                    (
                        item.regulationMark === null
                    &&
                        (
                                item.supertype == "Energy" && item.subtypes != null
                            &&
                                JSON.parse(item.subtypes).length
                            &&
                                (
                                    JSON.parse(item.subtypes).includes("Basic")
                                ||
                                    JSON.parse(item.subtypes).includes(null)
                                )
                        )
                    )
                )? '스탠다드':'익스펜디드'
        },

        selected_list_type() {
            return this.selected_list.reduce((acc, e) => {
                if(JSON.parse(e.types) == null) return acc
                if (!e.types || !e.amount) return acc

                let typesArray = JSON.parse(e.types)
                for (let i = 0; i < e.amount; i++) {
                    acc = acc.concat(typesArray)
                }

                return acc
            }, [])
        },

        // 재수록카드 목록
        renewal_list(){
            switch(this.computed_nation){
                case 'en' : return this.select_list.renewal.en
                case 'kr' : return this.select_list.renewal.kr
                case 'jp' : return this.select_list.renewal.jp
            }
        },
    },

    async mounted(){
        // 국가 목록 불러오기
        this.select_list.country = await this.$load_country_list()
        this.componentKey++

        // 플레이어 전체 목록 불러오기
        this.load_player_list()

        // 공식대회 스탠딩 목록 불러오기
        this.load_standing_list()

        // 국가 목록 불러오기
        // this.load_country_list()

        // 레귤레이션 스탠다드 가져오기
        this.load_standard_list()

        // 재수록카드 목록 불러오기
        this.loadRenewalList()

        // # 덱 분류값 목록 가져오기
        this.load_deck_identifier_list()
    },

    methods: {
        // # 덱 분류값 목록 가져오기
        load_deck_identifier_list(){
            this.$http.post("/api/official_league/select/deck_identifier")
            .then((res) => {
                console.log(res)
                if(res.data.length){
                    this.deck_identifier_list = res.data.map(item => {
                        if (item.kr === null || item.kr === "#N/A") {
                            item.kr = item.identifier
                        }
                        return item
                    })

                    console.log("deck_identifier_list", this.deck_identifier_list)
                }
            })
        },

        triggerFileInput() {
            this.$refs.fileInput.click()
        },

        async readExcelFile(event) {
            const file = event.target.files[0]
            const data = await new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = e => resolve(new Uint8Array(e.target.result))
                reader.onerror = e => reject(e)
                reader.readAsArrayBuffer(file)
            })

            const workbook = XLSX.read(data, {type: 'array'})
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = XLSX.utils.sheet_to_json(worksheet)

            this.list = json

            // 기본값 설정하기
            this.setListDefault()
        },

        // 기본값 설정하기
        setListDefault() {
            const defaultItem = {
                player_kr: "",
                player_en: "",
                player_local: "",
                player_country: "",
                player_age_group: "전체연령",
                uid: 0,
                uid_list: [],
                standing: 0,
                top_cut: "",
                cardshop: "",
                is_record_day1: false,
                deck_day1: null,
                deck_win1: 0,
                deck_lose1: 0,
                deck_tie1: 0,
                deck_point1: 0,
                deck_identifier1: "",
                deck_card_list1: "",
                is_record_day2: false,
                deck_day2: null,
                deck_win2: 0,
                deck_lose2: 0,
                deck_tie2: 0,
                deck_point2: 0,
                deck_identifier2: "",
                deck_card_list2: "",
            }

            this.list = this.list.map(item => {
                Object.keys(defaultItem).forEach(key => {
                    if (item[key] === undefined) {
                        item[key] = defaultItem[key]
                    }
                })
                return item
            })
        },

        // 추가
        addField(){
            this.list.push(
                {
                    player_kr: "",
                    player_en: "",
                    player_local: "",
                    player_country: "",
                    player_age_group: "전체연령",
                    uid: 0,
                    uid_list: [],
                    standing: 0,
                    top_cut: "",
                    cardshop: "",
                    is_record_day1: false,
                    deck_day1: null,
                    deck_win1: 0,
                    deck_lose1: 0,
                    deck_tie1: 0,
                    deck_point1: 0,
                    deck_identifier1: "",
                    deck_card_list1: "",
                    is_record_day2: false,
                    deck_day2: null,
                    deck_win2: 0,
                    deck_lose2: 0,
                    deck_tie2: 0,
                    deck_point2: 0,
                    deck_identifier2: "",
                    deck_card_list2: "",
                }
            )
        },

        // 플레이어 전체 체크하기
        async check_player_all(nation){
            for(let i=0; i<this.list.length; i++){
                await this.check_player(this.list[i], nation)
            }

            this.componentKey+=1
        },

        // 플레이어 체크해서 빈값, uid, uid_list 찾기
        async check_player(item, nation){
            console.log(item, nation)
            let existingPlayer = []


            let index = this.list.findIndex(x => x.id === item.id)
            console.log(index)
            if (index + 1 > this.first_list_length) {
                item.uid = 0
                item.uid_list = []
                // item.player_country = ""
                // item.player_age_group = ""
            }

            // 채워넣기
            if(nation == "kr"){
                existingPlayer = this.player_list.filter(player => 
                    player.player_kr === item.player_kr
                )

                if (existingPlayer.length) {
                    if(!item.player_en && !item.player_local){
                        item.player_en = existingPlayer[0].player_en
                        item.player_local = existingPlayer[0].player_local
                        item.player_country = existingPlayer[0].player_country
                        item.player_age_group = existingPlayer[0].player_age_group

                        let index = this.list.findIndex(x => x.id === item.id)
                        // console.log(index)
                        if (index + 1 > this.first_list_length) {
                            item.uid_list = existingPlayer.map(e => e.id)
                            item.uid = existingPlayer[0].id
                        }
                    }else{
                        let index = this.list.findIndex(x => x.id === item.id)
                        console.log(index)
                        if (index + 1 > this.first_list_length) {
                            // 완전히 일치하는 플레이어 찾기
                            let exactPlayer = this.player_list.filter(player => 
                                player.player_kr === item.player_kr
                                && player.player_en === item.player_en
                                && player.player_local === item.player_local
                            )

                            // 완전 일치하는 플레이어 있다면 uid, uid_list 값 대입
                            if(exactPlayer.length){
                                item.uid_list = exactPlayer.map(e => e.id)
                                item.uid = exactPlayer[0].id
                                item.player_country = existingPlayer[0].player_country
                                item.player_age_group = existingPlayer[0].player_age_group
                            }
                        }
                    }
                }
            }

            else if(nation == "en"){
                existingPlayer = this.player_list.filter(player => 
                    player.player_en === item.player_en
                )

                if (existingPlayer.length) {
                    if(!item.player_kr && !item.player_local){
                        item.player_kr = existingPlayer[0].player_kr
                        item.player_local = existingPlayer[0].player_local
                        item.player_country = existingPlayer[0].player_country
                        item.player_age_group = existingPlayer[0].player_age_group

                        let index = this.list.findIndex(x => x.id === item.id)
                        if (index + 1 > this.first_list_length) {
                            item.uid_list = existingPlayer.map(e => e.id)
                            item.uid = existingPlayer[0].id
                        }
                    }else{
                        let index = this.list.findIndex(x => x.id === item.id)
                        // console.log(index)
                        if (index + 1 > this.first_list_length) {
                            // 완전히 일치하는 플레이어 찾기
                            let exactPlayer = this.player_list.filter(player => 
                                player.player_kr === item.player_kr
                                && player.player_en === item.player_en
                                && player.player_local === item.player_local
                            )

                            // 완전 일치하는 플레이어 있다면 uid, uid_list 값 대입
                            if(exactPlayer.length){
                                item.uid_list = exactPlayer.map(e => e.id)
                                item.uid = exactPlayer[0].id
                                item.player_country = player_country[0].id
                                item.player_age_group = player_age_group[0].id
                            }
                        }
                    }
                }
            }

            else if(nation == "local"){
                existingPlayer = this.player_list.filter(player => 
                    player.player_local === item.player_local
                )

                if (existingPlayer.length) {
                    if(!item.player_kr && !item.player_en){
                        item.player_kr = existingPlayer[0].player_kr
                        item.player_en = existingPlayer[0].player_en
                        item.player_country = existingPlayer[0].player_country
                        item.player_age_group = existingPlayer[0].player_age_group

                        let index = this.list.findIndex(x => x.id === item.id)
                        if (index + 1 > this.first_list_length) {
                            item.uid_list = existingPlayer.map(e => e.id)
                            item.uid = existingPlayer[0].id
                        }
                    }else{
                        let index = this.list.findIndex(x => x.id === item.id)
                        // console.log(index)
                        if (index + 1 > this.first_list_length) {
                            // 완전히 일치하는 플레이어 찾기
                            let exactPlayer = this.player_list.filter(player => 
                                player.player_kr === item.player_kr
                                && player.player_en === item.player_en
                                && player.player_local === item.player_local
                            )

                            // 완전 일치하는 플레이어 있다면 uid, uid_list 값 대입
                            if(exactPlayer.length){
                                item.uid_list = exactPlayer.map(e => e.id)
                                item.uid = exactPlayer[0].id
                                item.player_country = existingPlayer[0].player_country
                                item.player_age_group = existingPlayer[0].player_age_group
                            }
                        }
                    }
                }
            }
        },

        // 행 삭제
        subtractField(index){
            if(confirm("해당 행을 정말 삭제하시겠습니까?")){
                this.list.splice(index,1)
            }
        },

        // UID 선택
        uid_updated(item){
            const selectedPlayer = this.player_list.find(e => e.id === item.uid)
            if (selectedPlayer) {
                item.player_kr = selectedPlayer.player_kr
                item.player_en = selectedPlayer.player_en
                item.player_local = selectedPlayer.player_local
                item.player_country = selectedPlayer.player_country
                item.player_age_group = selectedPlayer.player_age_group
            }
        },

        // 탑컷 계산
        caculateTopCut(standing) {
            if (standing >= 1 && standing <= 3) {
                return `${standing}등`
            } else if (standing === 4) {
                return '4강'
            } else if (standing >= 5 && standing <= 8) {
                return '8강'
            } else if (standing >= 9 && standing <= 16) {
                return '16강'
            } else if (standing >= 17 && standing <= 32) {
                return '32강'
            } else if (standing >= 33 && standing <= 64) {
                return '64강'
            } else if (standing >= 65 && standing <= 128) {
                return '128강'
            } else if (standing >= 129 && standing <= 256) {
                return '256강'
            } else if (standing >= 257) {
                return '예선'
            }
        },

        // 플레이어 전체 목록 불러오기
        async load_player_list(){
            this.$http.post("/api/official_league/player/select")
            .then((res) => {
                console.log(res.data)
                this.player_list = res.data
            })
        },

        // 플레이어 입력
        async player_insert(){
            for(let i=0; i<this.list.length; i++){
                if(this.list[i].uid == 0){
                    await this.$http.post("/api/official_league/player/insert", {
                        params: {
                            player_kr: this.list[i].player_kr,
                            player_en: this.list[i].player_en,
                            player_local: this.list[i].player_local,
                            player_country: this.list[i].player_country,
                            player_age_group: this.list[i].player_age_group
                        }
                    }).then((res) => {
                        // uid 추가
                        this.list[i].uid_list.push(res.data.insertId)
                        this.list[i].uid = res.data.insertId
                    })
                }
            }
        },

        // 플레이어 수정
        async player_update(){
            for(let i=0; i<this.first_list_length; i++){
                await this.$http.post("/api/official_league/player/update", {
                    params: {
                        uid: this.list[i].uid,
                        player_kr: this.list[i].player_kr,
                        player_en: this.list[i].player_en,
                        player_local: this.list[i].player_local,
                        player_country: this.list[i].player_country,
                        player_age_group: this.list[i].player_age_group
                    }
                })
            }
        },

        // 공식대회 스탠딩 목록 불러오기
        load_standing_list(){
            this.$http.post("/api/official_league/select/standing", {
                params: {
                    league_id: this.$route.query.id
                }
            }).then((res) => {
                console.log("load_standing_list", res.data)
                if(res.data.length){
                    this.list = res.data

                    this.first_list_length = res.data.length

                    this.list.forEach(player => {
                        if (!player.uid_list) {
                            player.uid_list = []
                        }
                        if (player.uid) {
                            player.uid_list.push(player.uid)
                        }
                    })
                }
            })
        },

        // 스탠딩 입력
        async standing_insert(){
            // 삭제
            await this.$http.post("/api/official_league/delete/standing", {
                params: {
                    league_id: this.$route.query.id,
                }
            }).then(async(res) => {
                // 재입력
                for(let i=0; i<this.list.length; i++){
                    await this.$http.post("/api/official_league/insert/standing", {
                        params: {
                            league_id: this.$route.query.id,
                            uid: this.list[i].uid,
                            standing: this.list[i].standing,
                            top_cut: this.caculateTopCut(this.list[i].standing),
                            cardshop: this.list[i].cardshop,
                            is_record_day1: this.list[i].is_record_day1,
                            deck_day1: this.list[i].deck_day1,
                            deck_win1: this.list[i].deck_win1,
                            deck_lose1: this.list[i].deck_lose1,
                            deck_tie1: this.list[i].deck_tie1,
                            deck_point1: this.list[i].deck_point1,
                            deck_identifier1: this.list[i].deck_identifier1,
                            deck_card_list1: this.list[i].deck_card_list1,
                            is_record_day2: this.list[i].is_record_day2,
                            deck_day2: this.list[i].deck_day2,
                            deck_win2: this.list[i].deck_win2,
                            deck_lose2: this.list[i].deck_lose2,
                            deck_tie2: this.list[i].deck_tie2,
                            deck_point2: this.list[i].deck_point2,
                            deck_identifier2: this.list[i].deck_identifier2,
                            deck_card_list2: this.list[i].deck_card_list2,
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                }
            })
        },

        // PTCGL 덱 입력
        async ptcgl_deck_insert(){
            this.loading = true
            for(let i=0; i<this.list.length; i++){
                if(this.list[i].deck_day1 && this.list[i].deck_day1.length > 10){
                    this.deck_import_text = this.list[i].deck_day1
                    this.selected_index = i
                    this.selected_day = 1
                    await this.deckImport()
                    this.selected_list = []
                }

                if(this.list[i].deck_day2 && this.list[i].deck_day2.length > 10){
                    this.deck_import_text = this.list[i].deck_day2
                    this.selected_index = i
                    this.selected_day = 2
                    await this.deckImport()
                    this.selected_list = []
                }
            }
            
            alert("덱 분류하기가 완료되었습니다.")
            this.loading = false
        },

        // 제출
        async submit(){
            // 필수입력값 경고문구
            for (let item of this.list) {
                if (!item.player_kr && !item.player_en && !item.player_local) {
                    alert('플레이어 정보가 입력되지 않았습니다.')
                    return
                }

                if (!item.standing) {
                    alert('순위가 입력되지 않았습니다.')
                    return
                }
            }

            this.loading = true

            // 플레이어 수정
            await this.player_update()

            // 플레이어 입력
            await this.player_insert()

            // 스탠딩 입력
            await this.standing_insert()

            this.loading = false
            alert("플레이어 정보가 입력 완료되었습니다.")
            this.$emit("close")
        },

        // 덱 입력
        async deckImport(){
            if(this.deck_import_text.includes('null')){
                alert("해당 덱 코드에는 null 값이 존재하므로 덱 입력을 하실 수 없습니다.")
                this.loading = false
                return
            }

            let deck_import_array = 
            this.deck_import_text
            .split('\n') // 줄바꿈으로 분리
            .filter(e => e != "") // 빈 줄 제거
            .filter(e => /^\d/.test(e)) // 숫자(카드 수량)으로 시작하지 않느 설명줄 제거

            for(let card of deck_import_array){
                // console.log("card", card)
                // console.log("this.convertList(card)", this.convertList(card))
                console.log(this.convertList(card))
                
                // 페어리 에너지
                if(this.convertList(card)[1].includes("{Y}"))
                {
                    await this.$http.post("/api/new/deck/select/card/import/byid", {
                        params: {
                            id: "sm1-172",
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], parseInt(this.convertList(card)[0]))
                        }else{
                            await this.searchInTable(card, parseInt(this.convertList(card)[0]))
                        }
                    })
                }
                // 에너지
                else if(this.convertList(card)[1].includes("Energy"))
                {
                    await this.$http.post("/api/new/deck/select/card/import/basicEnergy", {
                        params: {
                            name_en: this.convertName(this.convertList(card)[1]),
                            ptcgoCode: this.convertList(card)[2],
                            number_en: this.convertList(card)[3]
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], parseInt(this.convertList(card)[0]))
                        }else{
                            await this.searchInTable(card, parseInt(this.convertList(card)[0]))
                        }
                    })
                }
                // 특수기호 있는 콜렉션 넘버
                else if(
                    this.convertList(card)[2] == "PR-SV"
                    || this.convertList(card)[2] == "PR-SW"
                    || this.convertList(card)[2] == "PR-SM"
                    || this.convertList(card)[2] == "PR-XY"
                    || this.convertList(card)[2] == "PR-BLW"
                    || this.convertList(card)[2] == "PR-HS"
                    || this.convertList(card)[2] == "PR-DPP"
                    || this.convertList(card)[2] == "PR-NP"
                    || this.convertList(card)[2] == "PR"
                    || this.convertList(card)[2].includes("-TG")
                    || this.convertList(card)[2].includes("-GG")
                )
                {
                    await this.$http.post("/api/new/deck/select/card/import/numberConvert", {
                        params: {
                            name_en: this.convertName(this.convertList(card)[1]),
                            ptcgoCode: this.convertList(card)[2],
                            number_en: this.convertList(card)[3]
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], parseInt(this.convertList(card)[0]))
                        }else{
                            await this.searchInTable(card, parseInt(this.convertList(card)[0]))
                        }
                    })
                }
                // 위 예외처리 이후 나머지
                else
                {
                    await this.$http.post("/api/new/deck/select/card/import", {
                        params: {
                            name_en: this.convertName(this.convertList(card)[1]),
                            ptcgoCode: this.convertList(card)[2],
                            number_en: this.convertList(card)[3]
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], parseInt(this.convertList(card)[0]))
                        }else{
                            await this.searchInTable(card, parseInt(this.convertList(card)[0]))
                        }
                    })
                }
            }

            await this.save()
        },

        // 에너지 중괄호 변경하기
        convertName(text){
            // 없는 값 들어오면 replace 안하고 return
            if(!text){
                return text
            }

            // 에너지 > 중괄호 변환
            text = text.replace("{G}", "Grass")
            text = text.replace("{R}", "Fire")
            text = text.replace("{W}", "Water")
            text = text.replace("{L}", "Lightning")
            text = text.replace("{P}", "Psychic")
            text = text.replace("{F}", "Fighting")
            text = text.replace("{D}", "Darkness")
            text = text.replace("{M}", "Metal")

            // 에너지 > Basic 값 제거
            text = text.replace("Basic", "")

            // 괄호로 묶인 내용을 제거 (괄호 포함)
            text = text.replace(/\(.*?\)/g, '')

            // 앞뒤 여백 제거
            text = text.trim()

            return text
        },

        convertList(str) {
            // 먼저 공백으로 문자열을 분할합니다.
            let parts = str.split(' ')

            // 뒤에 PH 붙는거 경우
            if(parts[parts.length - 1] == "PH")
            {
                parts.pop()
            }

            // 첫 번째 값
            let firstValue = parts[0]

            // 마지막 두 값
            let thirdValue = parts[parts.length - 2]
            let fourthValue = parts[parts.length - 1]

            // 중간에 남은 부분이 두 번째 값입니다.
            let secondValue = parts.slice(1, parts.length - 2).join(' ')

            // 에너지카드이고 제품명이 없어서
            // 제품명으로 Energy가 이동한 경우 처리 (Energy 다시 붙여줌)
            if(!secondValue.includes("Energy") && thirdValue == "Energy")
            {
                secondValue = secondValue + " Energy"
                thirdValue = "SVE"
            }
            else if(thirdValue == "Energy")
            {
                thirdValue = "SVE"
            }

            return [firstValue, secondValue, thirdValue, fourthValue]
        },

        // 카드 선택
        selectCard(item, amount){
            // 찬란한 포켓몬
            if(this.computed_nation == "en"){
                if(item.rarity_en == "Radiant Rare" && this.selected_list.some(e => e.rarity_en == "Radiant Rare")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    this.deck_import_text = ""
                    return
                }
            }
            else if(this.computed_nation == "kr"){
                if(item.rarity_kr == "K 찬란한" && this.selected_list.some(e => e.rarity_kr == "K 찬란한")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    this.deck_import_text = ""
                    return
                }
            }
            else if(this.computed_nation == "jp"){
                if(item.rarity_jp == "K かがや" && this.selected_list.some(e => e.rarity_jp == "K かがや")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    this.deck_import_text = ""
                    return
                }
            }

            // ACE SPEC
            if(this.computed_nation == "en"){
                if(item.rarity_en == "ACE SPEC Rare" && this.selected_list.some(e => e.rarity_en == "ACE SPEC Rare")){
                    alert("ACE SPEC Rare는 덱에 1장만 넣을 수 있습니다")
                    this.deck_import_text = ""
                    return
                }
            }
            else if(this.computed_nation == "kr"){
                if(item.rarity_kr == "ACE SPEC" && this.selected_list.some(e => e.rarity_kr == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    this.deck_import_text = ""
                    return
                }
            }
            else if(this.computed_nation == "jp"){
                if(item.rarity_jp == "ACE SPEC" && this.selected_list.some(e => e.rarity_jp == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    this.deck_import_text = ""
                    return
                }
            }

            if(this.selected_list_amount == this.deck_type) {
                alert("최대 수량에 도달하였습니다.")
                this.loading = false
                this.deck_import_text = ""
            }else{
                // 이미 존재하는 지
                let findedExistCard = this.selected_list.find(e => e.id === item.id)

                // 존재하면 수량 증가
                if(findedExistCard){
                    // 30장 덱
                    if(this.deck_type == 30)
                    {
                        // 기본 에너지의 경우
                        if(findedExistCard.supertype == "Energy" && (JSON.parse(findedExistCard.subtypes) == "Basic" || JSON.parse(findedExistCard.subtypes) == null)){
                            findedExistCard.amount += amount? amount : 1
                        }

                        // 기본 에너지가 아닌 경우
                        else{
                            const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                                // 국가에 따라 다른 속성 이름을 사용
                                const propName = `name_${this.computed_nation}`;
                                const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                                // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                                if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                    // 일치한다면 amount 합산
                                    if (!acc[nameWithoutParentheses]) {
                                        acc[nameWithoutParentheses] = 0;
                                    }
                                    acc[nameWithoutParentheses] += obj.amount;
                                }

                                // 각 단계마다의 acc 출력 (디버깅 용)
                                console.log(acc);

                                // acc를 반환해야 다음 iteration에서 사용할 수 있음
                                return acc;
                            }, {});

                            // 합산된 amount가 2 이상인지 확인
                            const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 2)

                            console.log(existsAmountOverFour)

                            if(existsAmountOverFour){
                                alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 2장까지 추가하실 수 있습니다")
                                this.loading = false
                                this.deck_import_text = ""
                            }else {
                                if(item.amount >= 2)
                                {
                                    alert("60장 덱에 기본 에너지 이외의 카드는 2장까지 추가하실 수 있습니다")
                                    this.loading = false
                                    this.deck_import_text = ""
                                }
                                else
                                {
                                    item.amount += amount? amount : 1
                                }
                            }
                        }
                    }

                    // 60장 덱
                    if(this.deck_type == 60)
                    {
                        // 기본 에너지의 경우
                        if(findedExistCard.supertype == "Energy" && (JSON.parse(findedExistCard.subtypes) == "Basic" || JSON.parse(findedExistCard.subtypes) == null)){
                            findedExistCard.amount += amount? amount : 1
                        }

                        // 기본 에너지가 아닌 경우
                        else{
                            const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                                // 국가에 따라 다른 속성 이름을 사용
                                const propName = `name_${this.computed_nation}`
                                const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                                // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                                if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                    // 일치한다면 amount 합산
                                    if (!acc[nameWithoutParentheses]) {
                                        acc[nameWithoutParentheses] = 0
                                    }
                                    acc[nameWithoutParentheses] += obj.amount
                                }

                                // 각 단계마다의 acc 출력 (디버깅 용)
                                console.log(acc)

                                // acc를 반환해야 다음 iteration에서 사용할 수 있음
                                return acc
                            }, {})

                            // 합산된 amount가 4 이상인지 확인
                            const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 4)

                            if(existsAmountOverFour){
                                alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 4장까지 추가하실 수 있습니다")
                                this.loading = false
                                this.deck_import_text = ""
                            }else {
                                if(findedExistCard.amount >= 4)
                                {
                                    alert("60장 덱에 기본 에너지 이외의 카드는 4장까지 추가하실 수 있습니다")
                                    this.loading = false
                                    this.deck_import_text = ""
                                }
                                else
                                {
                                    findedExistCard.amount += amount? amount : 1
                                }
                            }
                        }
                    }
                }

                // 존재하지 않으면 카드 추가
                else{
                    const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                        // 국가에 따라 다른 속성 이름을 사용
                        const propName = `name_${this.computed_nation}`
                        const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                        // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                        if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                            // 일치한다면 amount 합산
                            if (!acc[nameWithoutParentheses]) {
                                acc[nameWithoutParentheses] = 0
                            }
                            acc[nameWithoutParentheses] += obj.amount
                        }

                        // 각 단계마다의 acc 출력 (디버깅 용)
                        if (Object.keys(acc).length > 0) {
                            console.log(acc)
                        }

                        // acc를 반환해야 다음 iteration에서 사용할 수 있음
                        return acc
                    }, {})

                    // 합산된 amount가 2, 4 이상인지 확인
                    const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 4)
                    const existsAmountOverTwo = Object.values(amountSumsByName).some(amount => amount >= 2)

                    if(this.deck_type == 30){
                        if(existsAmountOverTwo){
                            alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 2장까지 추가하실 수 있습니다")
                            this.loading = false
                            this.deck_import_text = ""
                        }else {
                            this.selected_list.push(item)

                            if(amount){
                                item.amount += amount-1
                            }
                        }
                    }
                    else if(this.deck_type == 60){
                        if(existsAmountOverFour){
                            alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 4장까지 추가하실 수 있습니다")
                            this.loading = false
                            this.deck_import_text = ""
                        }else {
                            this.selected_list.push(item)

                            if(amount){
                                item.amount += amount-1
                            }
                        }
                    }
                }
            }
        },

        // PTCGL 카드 데이터 검색
        async searchInTable(deck_code, amount){
            await this.$http.post("/api/admin/deck/ptcgl/select/specific", {
                params: {
                    text: deck_code.substring(deck_code.indexOf(' ') + 1)
                }
            }).then(async(res) => {
                console.log(res)
                if(res.data.length){
                    await this.$http.post("/api/new/deck/select/card/import/byid", {
                        params: {
                            id: res.data[0].card_id,
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], amount)
                        }
                    })
                }else {
                    alert(deck_code + "PTCGL 카드 데이터를 찾을 수 없습니다. 운영자에게 해당 코드를 공유 해주시면 처리 해드리겠습니다")
                }
            })
        },

        // 레귤레이션 스탠다드 가져오기
        load_standard_list(){
            this.$http.post('/api/admin/card/regulation_mark/list')
            .then((res) => {
                this.standard_list = res.data
                    .filter(e => e.type === '스탠다드')
                    .map(e => e.regulation_mark)
            })
        },

        // 재수록카드 목록 불러오기
        loadRenewalList(){
            // 한글
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })

            // 영어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.renewal.en = res.data.map(a => a.card_id)
            })

            // 일어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })
        },

        // 저장
        async save(){
            if(this.selected_list_amount != this.deck_type){
                alert("덱 총 장수를 채워야 저장하실 수 있습니다")
            }
            else{
                await this.deckUpdate()
                this.deck_import_text = ""
            }
        },

        // 덱 분류하기
        async deckUpdate(){
            let deck_card_list = this.selected_list
            // console.log("덱 내 카드 목록", deck_card_list)

            
            let mergedDeckCardList = Object.values(deck_card_list.reduce((acc, card) => {
                let key = card.name_en // name_en을 키로 사용
                if (!acc[key]) {
                    acc[key] = { ...card } // 새로운 키면 그대로 추가
                } else {
                    acc[key].amount += card.amount // amount를 더함
                }
                return acc
            }, {}))

            deck_card_list = mergedDeckCardList


            let deck_main_card_list = []

            // 덱 카드 별 메인포켓몬 매칭해보기
            let promises = deck_card_list.map(e => {
                return this.$http.post("/api/league/auto/load/deck_auto_card_list", {
                    params: {
                        name: e.name_en,
                        count: e.amount
                    }
                }).then((res) => {
                    if (res.data.length) {
                        return res.data
                    }
                    return []
                })
            })

            // 메인포켓몬 매칭된 덱들 합치기 (덱분류 후보 생성)
            await Promise.all(promises).then((results) => {
                results.forEach(data => {
                    if (data.length) {
                        deck_main_card_list.push(...data)
                    }
                })
            })

            // 덱분류 후보 (1차 분류)
            console.log("덱분류 후보 (1차 분류)", deck_main_card_list)

            // 덱분류 후보별 메인포켓몬이 더 있는 경우 확인하기
            let promises2 = deck_main_card_list.map(e => {
                return this.$http.post("/api/league/auto/load/deck_auto_card_list/multiple", {
                    params: {
                        deck_auto_id: e.deck_auto_id
                    }
                }).then((res) => {
                    if (res.data.length == 1) {
                        // 메인 포켓몬이 하나인 경우
                        return { type: 'single', data: res.data }
                    } else {
                        // 메인 포켓몬이 두 개 이상인 경우
                        return { type: 'multiple', data: res.data }
                    }
                })
            })
            
            let list1 = [] // 메인 포켓몬이 하나인 경우
            let list2 = [] // 메인 포켓몬이 두 개 이상인 경우

            await Promise.all(promises2).then((results) => {
                results.forEach(item => {
                    if (item.type === 'single') {
                        list1.push(item.data)
                    } else if (item.type === 'multiple') {
                        list2.push(item.data)
                    }
                });
            });

            // 결과 확인
            console.log("메인 포켓몬 1개인 경우 (일단 확정): ", list1)
            console.log("메인 포켓몬 2개 이상인 경우: ", list2)


            // 메인 포켓몬 2개 이상을 모두 일치하는지 덱인지 확인
            let deckCardNames = deck_card_list.map(item => item.name_en)
            list2 = list2.filter(subList => {
                return subList.every(item => deckCardNames.includes(item.main_pokemon_name))
            })

            console.log("조건에 맞는 메인 포켓몬 2개 이상의 리스트: ", list2)

            // 메인 포켓몬 조건을 통과한 덱 (1, 2~ 합산)
            let main_pass_list = [...list1, ...list2]
            console.log("메인 포켓몬 조건을 통과한 덱 (1, 2~ 합산)", main_pass_list)

            // 통과된 덱이 없으면 PASS
            if(!main_pass_list.length){
                // continue
                return
            }

            /* -------------------------------------------------------------------------- */

            // 덱분류 후보덱 별 하위덱분류(variant) 확인
            let promises3 = main_pass_list.map(e => {
                return this.$http.post("/api/league/auto/load/deck_auto_variant_list", {
                    params: {
                        deck_auto_id: e[0].deck_auto_id
                    }
                }).then((res) => {
                    // console.log(res)

                    if (res.data.length) {
                        return res.data
                    } else {
                        return []
                    }
                })
            })

            let deck_auto_variant_list = []

            // 덱분류 후보덱 별 하위덱분류(variant) 목록
            await Promise.all(promises3).then((results) => {
                results.forEach(data => {
                    if (data.length) {
                        deck_auto_variant_list.push(...data)
                    }
                })
            })

            //  덱분류 후보덱 별 하위덱분류(variant) 목록
            console.log("하위덱분류 목록", deck_auto_variant_list)

            // 하위 덱 분류가 있으면
            if(deck_auto_variant_list.length){
                let final_list = []
                for(let i=0; i < deck_auto_variant_list.length; i++){
                    let variant_result_list = []

                    // 덱 카드 별 메인포켓몬 매칭해보기
                    let promise4 = deck_card_list.map(e => {
                        return this.$http.post("/api/league/auto/load/deck_auto_variant_card_list", {
                            params: {
                                identifier: deck_auto_variant_list[i].identifier,
                                name: e.name_en,
                                count: e.amount
                            }
                        }).then((res) => {
                            console.log(res)
                            if (res.data.length) {
                                return res.data
                            }
                            return []
                        })
                    })

                    // 메인포켓몬 매칭된 덱들 합치기 (덱분류 후보 생성)
                    await Promise.all(promise4).then((results) => {
                        results.forEach(data => {
                            if (data.length) {
                                variant_result_list.push(...data)
                            }
                        })
                    })

                    console.log("서브덱 분류 최종?", variant_result_list)

                    if(variant_result_list.length){
                        final_list.push(variant_result_list)
                    }
                }

                // 콘솔
                console.log("final_list(서브)", final_list)

                if(final_list.length){
                    final_list = final_list.map(e => e[0])

                    // generation 높은순 -> priority 높은순 정렬
                    final_list.sort((a, b) => {
                        // 먼저 generation 값으로 비교
                        if (a.generation > b.generation) {
                            return -1 // a가 b보다 큰 경우
                        }
                        if (a.generation < b.generation) {
                            return 1 // b가 a보다 큰 경우
                        }

                        // generation 값이 같으면 priority 값으로 비교
                        return a.priority - b.priority // 내림차순 정렬
                    })

                    // 최종 선정된 덱 분류
                    let final_deck = final_list[0]
                    console.log("최종 선정된 덱 분류", final_deck)
                    console.log("최종 덱 분류", final_deck.deck_name)

                    if(final_deck){
                        if(this.selected_day == 1){
                            this.list[this.selected_index].deck_identifier1 = final_deck.deck_name
                            this.list[this.selected_index].deck_card_list1 = JSON.stringify(this.selected_list.map(e => ({ id: e.id, amount: e.amount })))
                        }
                        if(this.selected_day == 2){
                            this.list[this.selected_index].deck_identifier2 = final_deck.deck_name
                            this.list[this.selected_index].deck_card_list2 = JSON.stringify(this.selected_list.map(e => ({ id: e.id, amount: e.amount })))
                        }
                    }
                }else{
                    // console.log(main_pass_list)
                    // console.log(deck_auto_variant_list[0].deck_auto_id)

                    // main_pass_list를 평탄화
                    let flattenedList = main_pass_list.flat()
                    let final_deck = flattenedList.filter(e => e.deck_auto_id == deck_auto_variant_list[0].deck_auto_id)[0]

                    // console.log(final_deck)

                    if(final_deck){
                        if(this.selected_day == 1){
                            this.list[this.selected_index].deck_identifier1 = final_deck.deck_name
                            this.list[this.selected_index].deck_card_list1 = JSON.stringify(this.selected_list.map(e => ({ id: e.id, amount: e.amount })))
                        }
                        if(this.selected_day == 2){
                            this.list[this.selected_index].deck_identifier2 = final_deck.deck_name
                            this.list[this.selected_index].deck_card_list2 = JSON.stringify(this.selected_list.map(e => ({ id: e.id, amount: e.amount })))
                        }
                    }
                }
            }
            // 없으면
            else {
                // 첫번쨰 배열로 매핑(메인포켓몬으로 인해 배열로 됨)
                let final_list = []
                final_list = main_pass_list.map(e => e[0])

                // generation 높은순 -> priority 높은순 정렬
                final_list.sort((a, b) => {
                    // 먼저 generation 값으로 비교
                    if (a.generation > b.generation) {
                        return -1 // a가 b보다 큰 경우
                    }
                    if (a.generation < b.generation) {
                        return 1 // b가 a보다 큰 경우
                    }

                    // generation 값이 같으면 priority 값으로 비교
                    return a.priority - b.priority // 내림차순 정렬
                })

                // 최종 선정된 덱 분류
                let final_deck = final_list[0]
                console.log("최종 선정된 덱 분류", final_deck)
                console.log("최종 덱 분류", final_deck.deck_name)

                if(final_deck){
                    if(this.selected_day == 1){
                        this.list[this.selected_index].deck_identifier1 = final_deck.deck_name
                        this.list[this.selected_index].deck_card_list1 = JSON.stringify(this.selected_list.map(e => ({ id: e.id, amount: e.amount })))
                    }
                    if(this.selected_day == 2){
                        this.list[this.selected_index].deck_identifier2 = final_deck.deck_name
                        this.list[this.selected_index].deck_card_list2 = JSON.stringify(this.selected_list.map(e => ({ id: e.id, amount: e.amount })))
                    }
                }
            }
        },

        async deck_classify(index){
            if(this.list[index].deck_day1 && this.list[index].deck_day1.length > 10){
                this.deck_import_text = this.list[index].deck_day1
                this.selected_index = index
                this.selected_day = 1
                await this.deckImport()
                this.selected_list = []
            }

            if(this.list[index].deck_day2 && this.list[index].deck_day2.length > 10){
                this.deck_import_text = this.list[index].deck_day2
                this.selected_index = index
                this.selected_day = 2
                await this.deckImport()
                this.selected_list = []
            }

            this.selected_index = null
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
<style scoped>
::v-deep .v-select .v-input__append-inner {
    margin-left: -8px; /* 아이콘을 왼쪽으로 이동 */
    width: 24px; /* 아이콘 컨테이너의 너비를 줄임 */
}

::v-deep .v-select .v-input__append-outer {
    max-width: 24px; /* 외부 컨테이너의 최대 너비를 조정 */
}
</style>