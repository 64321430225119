<template>
    <div>
        <!-- 결과 (카드샵) -->
        <div
            v-if="league_type == '카드샵대항전'"
            class="pt-3 pb-2 d-flex"
        >
            <v-btn
                class="rounded-10 ml-4"
                width="100"
                height="35"
                depressed
                dark
                :color="sub_menu == '플레이어'?'primary':'#adafca'"
                @click="sub_menu = '플레이어'"
            >
                플레이어
            </v-btn>
            <v-btn
                class="rounded-10 ml-2"
                width="100"
                height="35"
                depressed
                dark
                :color="sub_menu == '카드샵'?'primary':'#adafca'"
                @click="sub_menu = '카드샵'"
            >
                카드샵
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                depressed
                color="icu_purple"
                class="rounded-lg px-6 mr-4"
                small
                dark
                @click="dialog.cardshop = true"
            >
                카드샵 수정
            </v-btn>
        </div>

        <!-- 결과 (상단) (플레이어) (PC) -->
        <v-sheet
            v-if="sub_menu == '플레이어' && !$vuetify.breakpoint.mobile"
            height="40"
            color="#F0F3F5"
            :class="league_type == '카드샵대항전'? '':'rounded-t-15'"
            class="pt-1 d-flex"
        >
            <div
                class="text-center mt-1 primary--text"
                style="width:100px; font-size:14px; position:relative;"
            >
                #
                <div
                    class="primary mx-auto"
                    style="height:2px; width:40px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                ></div>
            </div>
            <div
                style="width:300px; font-size:14px;"
                class="text-center mt-1 flex-grow-1"
            >
                플레이어
            </div>

            <div
                style="width:200px; font-size:14px;"
                class="text-center mt-1"
            >
                전적
            </div>
            <div
                style="width:120px; font-size:14px;"
                class="text-center mt-1"
            >
                덱
            </div>
            <div
                style="width:260px; font-size:14px;"
                class="text-center mt-1"
            >
                덱 레시피
            </div>
        </v-sheet>

        <!-- 결과 (상단) (플레이어) (Mobile) -->
        <v-sheet
            v-if="sub_menu == '플레이어' && $vuetify.breakpoint.mobile"
            height="40"
            color="#F0F3F5"
            :class="league_type == '카드샵대항전'? '':'rounded-t-15'"
            class="pt-1 d-flex"
        >
            <div
                class="text-center mt-1 primary--text"
                style="width:48px; font-size:12px; position:relative;"
            >
                #
                <div
                    class="primary mx-auto"
                    style="height:2px; width:44px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                ></div>
            </div>
            <div
                style="width:160px; font-size:12px;"
                class="text-center mt-1 flex-grow-1"
            >
                플레이어
            </div>

            <div
                style="width:50px; font-size:12px;"
                class="text-center mt-1"
            >
                전적
            </div>
            <div
                style="width:54px; font-size:12px;"
                class="text-center mt-1"
            >
                덱
            </div>
            <div
                style="width:58px; font-size:12px;"
                class="text-center mt-1 pr-2"
            >
                덱 보기
            </div>
        </v-sheet>

        <!-- 결과 (목록) (플레이어) (PC) -->
        <v-sheet
            v-if="sub_menu == '플레이어' && !loading && !$vuetify.breakpoint.mobile"
            class="pt-1 rounded-15 pb-6"
        >
            <div
                v-if="standing_list.length"
            >
                <v-sheet
                    v-for="item in standing_list" :key="item.id"
                    height="56"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; cursor:pointer;"
                    @click="$router.push('/official_league/player/detail?id='+item.uid)"
                >
                    <!-- 날짜 -->
                    <div
                        style="width:100px; font-size:14px; height:56px; border-bottom:1px solid #eee;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.standing < 800? item.standing : '예선'}}
                    </div>

                    <!-- 국가 -->
                    <div
                        style="width:300px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="d-flex align-center font-weight-medium primary--text flex-grow-1"
                    >
                        <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                            <template v-slot:activator="{ on, attrs }">
                                <v-sheet
                                    height="52"
                                    width="32"
                                    class="rounded-bl-15"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-img
                                        v-if="item.player_country"
                                        width="36"
                                        height="26"
                                        class="rounded-5 mx-auto mt-3"
                                        style="box-shadow:0px 2px 4px #ddd;"
                                        :src="'/upload/flags/'+item.player_country.toLowerCase()+'.svg'"
                                    ></v-img>
                                </v-sheet>
                            </template>
                            <div class="text-center pa-1">
                                {{$country_name(item.player_country, country_list)}}
                            </div>
                        </v-tooltip>

                        <div
                            v-if="!item.player_local"
                            class="ml-4"
                        >
                            <p
                                style="font-size:14px; font-weight:600; margin-bottom:-2px;"
                            >
                                {{computed_player_kr(item)}}
                            </p>
                            <p
                                style="font-size:12px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                            >
                                {{computed_player_en(item)}}
                            </p>
                        </div>
                        <div
                            v-else
                            class="ml-4"
                        >
                            <p
                                style="font-size:14px; font-weight:600; margin-bottom:0px;"
                            >
                                {{item.player_local}}
                            </p>
                        </div>
                    </div>

                    <!-- 전적 -->
                    <div
                        style="width:200px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="text-start d-flex align-center justify-center font-weight-medium"
                    >
                        <div
                            v-if="item.is_record_day1"
                        >
                            {{item.deck_win1 + item.deck_win2}}
                            <span class="mx-1">-</span>
                            {{item.deck_lose1 + item.deck_lose2}}
                            <span class="mx-1">-</span>
                            {{item.deck_tie1 + item.deck_tie2}}
                        </div>
                        <div
                            v-else
                        >
                            승점 {{item.deck_point1 + item.deck_point2}}
                        </div>
                    </div>

                    <!-- 참가자 -->
                    <div
                        style="width:120px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="text-center d-flex align-center justify-center font-weight-medium"
                        @click.stop=""
                    >
                        <!-- # 덱 아이콘 -->
                        <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    style="font-size:10px;"
                                    class="text-center d-flex align-center"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <div
                                        v-if="!item.sub_icon_day1 && !item.sub_icon_day2"
                                    >
                                        <div
                                            v-if="item.icon_day1 && !item.icon_day2"
                                            class="d-flex align-center mt-1"
                                            @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier1)"
                                        >
                                            <v-img
                                                v-for="icon in item.icon_day1.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 28 : 34"
                                                :height="$vuetify.breakpoint.mobile? 28 : 34"
                                                contain
                                                class="mb-1 mx-1"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>

                                        <div
                                            v-if="item.icon_day2"
                                            class="d-flex align-center mt-1"
                                        >
                                            <v-img
                                                v-for="icon in item.icon_day2.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 28 : 34"
                                                :height="$vuetify.breakpoint.mobile? 28 : 34"
                                                contain
                                                class="mb-1 mx-1"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                    >
                                        <div
                                            v-if="item.sub_icon_day1 && !item.sub_icon_day2"
                                            class="d-flex align-center mt-1"
                                            @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier1)"
                                        >
                                            <v-img
                                                v-for="icon in item.sub_icon_day1.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 28 : 34"
                                                :height="$vuetify.breakpoint.mobile? 28 : 34"
                                                contain
                                                class="mb-1 mx-1"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>

                                        <div
                                            v-if="item.sub_icon_day2"
                                            class="d-flex align-center mt-1"
                                        >
                                            <v-img
                                                v-for="icon in item.sub_icon_day2.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 28 : 34"
                                                :height="$vuetify.breakpoint.mobile? 28 : 34"
                                                contain
                                                class="mb-1 mx-1"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- <div class="text-center pa-1">
                                <span v-if="!item.sub_icon_day1 && !item.sub_icon_day2" class="white--text">{{deck_name_translate(item.deck_identifier1)}}</span>
                                <span v-else class="white--text">{{deck_name_translate(item.deck_identifier2)}}</span>
                            </div> -->
                            <div class="text-center pa-1">
                                <span class="white--text">{{deck_name_translate(item.deck_identifier1)}}</span>
                            </div>
                        </v-tooltip>

                        <!-- {{item.deck_identifier1}} -->
                    </div>

                    <!-- 덱 레시피 -->
                    <div
                        style="width:260px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="text-center d-flex align-center justify-center font-weight-medium primary--text"
                    >
                        <span v-if="item.deck_day1 && item.deck_day1.length > 10" class="icu_purple--text" @click.stop="deckView(item, 'DAY1')">덱 보기</span>
                    </div>
                </v-sheet>
            </div>
            <div
                v-if="!standing_list.length && !loading"
                class="text-center py-16"
            >
                <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                <p style="color:#ccc">결과가 없습니다</p>
            </div>

            <!-- 순위 안내 문구 추가 -->
            <div
                class="rounded-15 mr-2 d-flex align-center justify-center px-4 mt-5"
            >
                <v-icon size="18" color="icu_purple" class="mr-1">mdi-information-outline</v-icon>
                <p
                    class="my-0 icu_purple--text"
                    style="font-size:12px;"
                >
                    1위, 2위를 제외한 나머지 순위는 입력된 순번으로 실제 순위와 상관 없습니다.
                </p>
            </div>
        </v-sheet>

        <!-- 결과 (목록) (플레이어) (Mobile) -->
        <v-sheet
            v-if="sub_menu == '플레이어' && !loading && $vuetify.breakpoint.mobile"
            class="pt-1 rounded-15 pb-6"
        >
            <div
                v-if="standing_list.length"
            >
                <v-sheet
                    v-for="item in standing_list" :key="item.id"
                    height="40"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; cursor:pointer;"
                    @click="$router.push('/official_league/player/detail?id='+item.uid)"
                >
                    <!-- 순위 -->
                    <div
                        style="width:48px; font-size:10px; height:40px; border-bottom:1px solid #eee;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.standing < 800? item.standing : '예선'}}
                    </div>

                    <!-- 국가 -->
                    <div
                        style="width:160px; font-size:10px; height:40px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="d-flex align-center font-weight-medium primary--text flex-grow-1"
                    >
                        <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                            <template v-slot:activator="{ on, attrs }">
                                <v-sheet
                                    height="20"
                                    width="28"
                                    class="rounded-bl-15"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-img
                                        v-if="item.player_country"
                                        height="20"
                                        width="28"
                                        class="rounded-5 mx-auto"
                                        style="box-shadow:0px 2px 4px #ddd;"
                                        :src="'/upload/flags/'+item.player_country.toLowerCase()+'.svg'"
                                    ></v-img>
                                </v-sheet>
                            </template>
                            <div class="text-center pa-1">
                                {{$country_name(item.player_country, country_list)}}
                            </div>
                        </v-tooltip>

                        <div
                            v-if="!item.player_local"
                            class="ml-2"
                        >
                            <p
                                style="font-size:10px; font-weight:600; margin-bottom:-2px;"
                            >
                                {{computed_player_kr(item)}}
                            </p>
                            <p
                                style="font-size:8px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                            >
                                {{computed_player_en(item)}}
                            </p>
                        </div>
                        <div
                            v-else
                            class="ml-4"
                        >
                            <p
                                style="font-size:10px; font-weight:600; margin-bottom:0px;"
                            >
                                {{item.player_local}}
                            </p>
                        </div>
                    </div>

                    <!-- 전적 -->
                    <div
                        style="width:50px; font-size:10px; height:40px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="text-start d-flex align-center justify-center font-weight-medium"
                    >
                        <div
                            v-if="item.is_record_day1"
                        >
                            {{item.deck_win1 + item.deck_win2}}
                            <span class="mx-1">-</span>
                            {{item.deck_lose1 + item.deck_lose2}}
                            <span class="mx-1">-</span>
                            {{item.deck_tie1 + item.deck_tie2}}
                        </div>
                        <div
                            v-else
                        >
                            승점 {{item.deck_point1 + item.deck_point2}}
                        </div>
                    </div>

                    <!-- 참가자 -->
                    <div
                        style="width:54px; font-size:10px; height:40px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="text-center d-flex align-center justify-center font-weight-medium"
                        @click.stop=""
                    >
                        <!-- # 덱 아이콘 -->
                        <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    style="font-size:10px;"
                                    class="text-center d-flex align-center"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <div
                                        v-if="!item.sub_icon_day1 && !item.sub_icon_day2"
                                    >
                                        <div
                                            v-if="item.icon_day1 && !item.icon_day2"
                                            class="d-flex align-center mt-1"
                                            @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier1)"
                                        >
                                            <v-img
                                                v-for="icon in item.icon_day1.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                contain
                                                class="mb-1 mx-2px"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>

                                        <div
                                            v-if="item.icon_day2"
                                            class="d-flex align-center mt-1"
                                        >
                                            <v-img
                                                v-for="icon in item.icon_day2.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                contain
                                                class="mb-1 mx-2px"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                    >
                                        <div
                                            v-if="item.sub_icon_day1 && !item.sub_icon_day2"
                                            class="d-flex align-center mt-1"
                                            @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier1)"
                                        >
                                            <v-img
                                                v-for="icon in item.sub_icon_day1.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                contain
                                                class="mb-1 mx-2px"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>

                                        <div
                                            v-if="item.sub_icon_day2"
                                            class="d-flex align-center mt-1"
                                        >
                                            <v-img
                                                v-for="icon in item.sub_icon_day2.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                contain
                                                class="mb-1 mx-2px"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- <div class="text-center pa-1">
                                <span v-if="!item.sub_icon_day1 && !item.sub_icon_day2" class="white--text">{{deck_name_translate(item.deck_identifier1)}}</span>
                                <span v-else class="white--text">{{deck_name_translate(item.deck_identifier2)}}</span>
                            </div> -->
                            <div class="text-center pa-1">
                                <span class="white--text">{{deck_name_translate(item.deck_identifier1)}}</span>
                            </div>
                        </v-tooltip>

                        <!-- {{item.deck_identifier1}} -->
                    </div>

                    <!-- 덱 레시피 -->
                    <div
                        style="width:58px; font-size:10px; height:40px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="text-center d-flex align-center justify-center font-weight-medium primary--text pr-2"
                    >
                        <span v-if="item.deck_day1 && item.deck_day1.length > 10" class="icu_purple--text" @click.stop="deckView(item, 'DAY1')">덱 보기</span>
                    </div>
                </v-sheet>
            </div>
            <div
                v-if="!standing_list.length && !loading"
                class="text-center py-16"
            >
                <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                <p style="color:#ccc">결과가 없습니다</p>
            </div>

            <!-- 순위 안내 문구 추가 -->
            <div
                class="rounded-15 mr-2 d-flex align-center justify-center px-4 mt-5"
            >
                <v-icon size="18" color="icu_purple" class="mr-1">mdi-information-outline</v-icon>
                <p
                    class="my-0 icu_purple--text"
                    style="font-size:12px;"
                >
                    1위, 2위를 제외한 나머지 순위는 입력된 순번으로 실제 순위와 상관 없습니다.
                </p>
            </div>
        </v-sheet>

        <!-- 결과 (상단) (카드샵) -->
        <v-sheet
            v-if="sub_menu == '카드샵'"
            height="40"
            color="#F0F3F5"
            :class="league_type == '카드샵대항전'? '':'rounded-t-15'"
            class="pt-1 d-flex"
        >
            <div
                class="text-center mt-1 primary--text"
                style="width:100px; font-size:14px; position:relative;"
            >
                #
                <div
                    class="primary mx-auto"
                    style="height:2px; width:40px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                ></div>
            </div>
            <div
                style="width:200px; font-size:14px;"
                class="text-center mt-1 flex-grow-1"
            >
                카드샵
            </div>

            <div
                style="width:300px; font-size:14px;"
                class="text-center mt-1"
            >
                라운드 총 전적
            </div>
            <div
                style="width:300px; font-size:14px;"
                class="text-center mt-1"
            >
                경기 총 승점
            </div>
        </v-sheet>

        <!-- 결과 (목록) (카드샵) -->
        <v-sheet
            v-if="sub_menu == '카드샵' && !loading"
            class="pt-1 rounded-15 pb-4"
        >
            <div
                v-if="card_shop_standing_list.length"
            >
                <v-sheet
                    v-for="item in card_shop_standing_list" :key="item.id"
                    height="56"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; cursor:pointer;"
                >
                    <!-- 날짜 -->
                    <div
                        style="width:100px; font-size:14px; height:56px; border-bottom:1px solid #eee;"
                        class="text-center d-flex align-center justify-center"
                    >
                        {{item.standing < 800? item.standing+"위" : '예선'}}
                    </div>

                    <!-- 국가 -->
                    <div
                        style="width:200px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="font-weight-medium flex-grow-1 d-flex align-center justify-center"
                    >
                        {{item.cardshop}}
                    </div>

                    <!-- 라운드 총 전적 -->
                    <div
                        style="width:300px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="text-start d-flex align-center justify-center font-weight-medium"
                    >
                        {{item.round_win}}
                        <span class="mx-1">-</span>
                        {{item.round_lose}}
                        <span class="mx-1">-</span>
                        {{item.round_tie}}
                    </div>

                    <!-- 경기 총 승점 -->
                    <div
                        style="width:300px; font-size:14px; height:56px; border-bottom:1px solid #eee; cursor:pointer;"
                        class="text-start d-flex align-center justify-center font-weight-medium"
                    >
                        {{caculateTotal(item)}}
                    </div>
                </v-sheet>
            </div>
            <div
                v-if="!card_shop_standing_list.length && sub_menu == '카드샵' && !loading"
                class="text-center py-16"
            >
                <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                <p style="color:#ccc">결과가 없습니다</p>
            </div>
            <div
                v-if="!standing_list.length && sub_menu == '플레이어' && !loading"
                class="text-center py-16"
            >
                <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                <p style="color:#ccc">결과가 없습니다</p>
            </div>
        </v-sheet>

        <!-- 덱 보기 -->
        <v-dialog
            v-model="dialog.deckView"
            width="1068"
            content-class="rounded-15"
        >
            <DeckView
                :player_country="selected_item.player_country"
                :player_kr="selected_item.player_kr"
                :player_en="selected_item.player_en"
                :player_local="selected_item.player_local"
                :player_age_group="selected_item.player_age_group"
                :is_masking="selected_item.is_masking"
                :deck_name_kr="selected_item.deck_name_kr"
                :deck_name_en="selected_item.deck_name_en"
                :sub_deck_name_kr="selected_item.sub_deck_name_kr"
                :sub_deck_name_en="selected_item.sub_deck_name_en"
                :deck_card_list="selected_item.deck_card_list"
                :title_kr="title_kr"
                :title_en="title_en"
                :top_cut="selected_item.top_cut"
                @close="dialog.deckView = false; load_card_shop_standing_list()"
                :key="componentKey"
            />
        </v-dialog>

        <!-- 로딩 -->
        <v-sheet
            v-if="loading"
            class="py-16 rounded-15 d-flex justify-center"
        >
            <v-progress-circular
                indeterminate
                color="primary"
                size="120"
            ></v-progress-circular>
        </v-sheet>
    </div>
</template>
<script>
import DeckView from './DeckView'

export default {
    components: {
        DeckView,
    },
    
    data: () => ({
        title_kr: "",
        title_en: "",
        title_local: "",
        regulation: "",
        league_type: "",
        image: "",
        organizer: "",
        reference_link: "",
        start_date: "",
        end_date: "",
        total: 0,
        total2: 0,
        content: "",
        menu: "결과",
        sub_menu: "플레이어",
        list: [],
        season_nation: "",
        season_name: "",
        age_group: "",
        league_nation: "",

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        standing_list: [],
        card_shop_standing_list: [],

        dialog: {
            player: false,
            provide: false,
            cardshop: false,
            deckView: false
        },

        selected_item: {},
        componentKey: 0,

        deck_auto_translate_list: [],
        country_list: [],
        loading: false,
    }),

    async mounted(){
        await this.fetchData()
    },

    async beforeRouteUpdate(to, from, next) {
        await this.fetchData()
        next()
    },

    methods: {
        async fetchData() {
            // 국가 목록 불러오기
            this.country_list = await this.$load_country_list()

            // 공식대회 정보 불러오기
            await this.load()

            // 공식대회 스탠딩 목록 불러오기
            this.load_standing_list()

            if(this.league_type == '카드샵대항전'){
                // 공식대회 카드샵 스탠딩 목록 불러오기
                this.load_card_shop_standing_list()

                this.sub_menu = "카드샵"
            }

            // # 덱분류값 번역 목록 불러오기
            this.load_deck_auto_translate_list()
        },

        // # 덱분류값 번역 목록 불러오기
        load_deck_auto_translate_list(){
            this.$http.get('/api/admin/league/deck_auto_translate_list/list')
            .then((res) => {
                this.deck_auto_translate_list = res.data
            })
        },

        // # 덱분류값 한글로 번역
        deck_name_translate(en) {
            if(this.deck_auto_translate_list.length == 0){
                return ''
            }
            

            if (en === null) {
                return '덱분류값 없음'
            }
            let translation = this.deck_auto_translate_list.find(item => item.en === en)
            return translation ? (translation.kr || en) : en
        },

        // 카드샵대항전 승점 계산
        caculateTotal(item){
            return item.round_win * 2 + item.round_lose * -1
        },

        // 공식대회 스탠딩 목록 불러오기
        load_standing_list(){
            this.loading = true
            this.$http.post("/api/official_league/select/standing", {
                params: {
                    league_id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length){
                    console.log("load_standing_list", res.data)
                    this.standing_list = res.data
                }
                this.loading = false
            })
        },

        // 공식대회 카드샵 스탠딩 목록 불러오기
        load_card_shop_standing_list(){
            this.$http.post("/api/official_league/cardshop/select", {
                params: {
                    league_id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length){
                    this.card_shop_standing_list = res.data
                    // console.log(res.data)
                }
            })
        },

        // 공식대회 정보 불러오기
        async load(){
            await this.$http.post("/api/official_league/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then(async(res) => {
                if(res.data.length){
                    // console.log(res)
                    this.title_kr = res.data[0].title_kr
                    this.title_en = res.data[0].title_en
                    this.title_local = res.data[0].title_local
                    this.regulation = res.data[0].regulation
                    this.league_type = res.data[0].league_type
                    this.image = res.data[0].image
                    this.organizer = res.data[0].organizer
                    this.reference_link = res.data[0].reference_link
                    this.start_date = res.data[0].start_date
                    this.end_date = res.data[0].end_date
                    this.age_group = res.data[0].age_group
                    this.options.content = res.data[0].content
                    this.total = res.data[0].total
                    this.total2 = res.data[0].total2
                    this.league_nation = res.data[0].league_nation

                    this.season_nation = res.data[0].season_nation
                    this.season_name = res.data[0].season_name
                }
            })
        },
        
        // 덱 보기
        deckView(item, day){
            console.log(item)
            if(day == "DAY1" && !item.deck_identifier1){
                alert("입력되지 않은 덱 레시피입니다")
                return
            }
            else if(day == "DAY2" && !item.deck_identifier2){
                alert("입력되지 않은 덱 레시피입니다")
                return
            }

            this.selected_item = item

            if(day == 'DAY1'){
                this.selected_item.deck_name_en = item.deck_identifier1
                this.selected_item.deck_name_kr = this.deck_name_translate(item.deck_identifier1)
                this.selected_item.sub_deck_name_en = item.deck_identifier1
                this.selected_item.sub_deck_name_kr = this.deck_name_translate(item.deck_identifier1)
                this.selected_item.deck_card_list = this.selected_item.deck_card_list1
            }

            this.dialog.deckView = true
            this.componentKey++
        },

        computed_player_kr(item) {
            if(item.player_local){
                return item.player_local
            }
            else{
                if(item.player_country != "KR"){
                    return item.player_kr
                }else{
                    if(item.is_masking != null){
                        if(item.is_masking){
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }else{
                            return item.player_kr
                        }
                    }
                    else{
                        if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                            return item.player_kr
                        }else{
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(item.player_country != "KR"){
                return item.player_en
            }else{
                if(item.is_masking != null){
                    if(item.is_masking){
                        return item.player_en.split(' ')[0] + ' *'
                    }else{
                        return item.player_en
                    }
                }
                else{
                    if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                        return item.player_en
                    }else{
                         return item.player_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>
