<template>
    <v-sheet
        class="ma-2 px-4 py-2 pb-3 rounded-10"
        outlined
    >
        <!-- 상단 -->
        <div
            class="d-flex align-center justify-space-between mb-1"
            :class="$vuetify.breakpoint.mobile? '':'mt-2'"
        >
            <!-- 제목 -->
            <div
                class="d-flex align-center"
            >
                <!-- PC -->
                <p
                    v-if="!$vuetify.breakpoint.mobile"
                    style="font-size:18px; font-weight:bold;"
                    class="my-0"
                >
                    성적 요약 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                </p>

                <!-- Mobile -->
                <p
                    v-if="$vuetify.breakpoint.mobile"
                    style="font-size:10px; font-weight:bold;"
                    class="mb-2 mt-2"
                >
                    성적 요약 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                </p>
            </div>

            <!-- 선택 상단 -->
            <SelectHeader
                :global_term="term"
                @term_changed="term_changed_handler"
                :key="componentKey + 'tierHeader'"
            />
        </div>

        <!-- 차트 -->
        <!-- 목록 상단 -->
        <v-sheet
            :height="$vuetify.breakpoint.mobile? 32 : 40"
            color="#F0F3F5"
            class="pt-2 d-flex rounded-t-15"
        >
            <div
                style="width:25%;"
                :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                class="text-center"
            >
                대회 유형
            </div>
            
            <div
                style="width:15%;"
                :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                class="text-center"
            >
                1등
            </div>
            <div
                style="width:15%;"
                :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                class="text-center"
            >
                2등
            </div>
            <div
                style="width:15%;"
                :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                class="text-center"
            >
                4강
            </div>
            <div
                style="width:15%;"
                :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                class="text-center"
            >
                8강
            </div>
            <div
                style="width:15%;"
                :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                class="text-center"
            >
                총합
            </div>
        </v-sheet>

        <!-- 목록 -->
        <v-sheet
            v-for="item in list" :key="item.id"
            :height="$vuetify.breakpoint.mobile? 40 : 56"
            class="d-flex align-center ma-0 pa-0"
            style="position:relative; cursor:pointer;"
        >
            <!-- 대회 유형 -->
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px; padding-bottom:2px; height:40px;':'font-size:14px; height:56px;'"
                style="width:25%; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{item.league_type}}
            </div>

            <!-- 대회 유형 -->
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px; padding-bottom:2px; height:40px;':'font-size:14px; height:56px;'"
                style="width:15%; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{item.count_1st}}
            </div>

            <!-- 대회 유형 -->
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px; padding-bottom:2px; height:40px;':'font-size:14px; height:56px;'"
                style="width:15%; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{item.count_2nd}}
            </div>

            <!-- 대회 유형 -->
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px; padding-bottom:2px; height:40px;':'font-size:14px; height:56px;'"
                style="width:15%; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{item.count_t4}}
            </div>

            <!-- 대회 유형 -->
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px; padding-bottom:2px; height:40px;':'font-size:14px; height:56px;'"
                style="width:15%; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{item.count_t8}}
            </div>

            <!-- 대회 유형 -->
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px; padding-bottom:2px; height:40px;':'font-size:14px; height:56px;'"
                style="width:15%; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{item.count_1st + item.count_2nd + item.count_t4 + item.count_t8}}
            </div>
        </v-sheet>

        <!-- 목록 상단 -->
        <v-sheet
            :height="$vuetify.breakpoint.mobile? 32 : 40"
            color="#F0F3F5"
            class="pt-2 mt-2 d-flex rounded-t-15"
        >
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                style="width:50%;"
                class="text-center"
            >
                총점
            </div>
            
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                style="width:50%;"
                class="text-center"
            >
                월드챔피언쉽 탑컷
            </div>
        </v-sheet>

        <!-- 목록 -->
        <v-sheet
            :height="$vuetify.breakpoint.mobile? 40 : 56"
            class="d-flex align-center ma-0 pa-0"
            style="position:relative; cursor:pointer;"
        >
            <!-- 대회 유형 -->
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px; padding-bottom:2px; height:40px;':'font-size:14px; height:56px;'"
                style="width:50%; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{total_deck_point1}}
            </div>

            <!-- 대회 유형 -->
            <div
                :style="$vuetify.breakpoint.mobile? 'font-size:12px; padding-bottom:2px; height:40px;':'font-size:14px; height:56px;'"
                style="width:50%; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{count_wcs}}
            </div>
        </v-sheet>

        
    </v-sheet>
</template>
<script>
import SelectHeader from './components/SelectHeader'

export default {
    props: ["global_term"],

    components: {
        SelectHeader,
    },

    data: () => ({
        list: [],

        summary: [],

        term: "최근 3개월",

        componentKey: 0,

        total_deck_point1: 0,
        count_wcs: 0,
    }),

    async mounted(){
        this.term = this.global_term
        this.componentKey++

        // 목록 불러오기
        await this.search()
    },

    watch: {
        global_term(newVal){
            this.term = newVal
            this.search()
        }
    },

    methods: {
        term_changed_handler(term){
            this.term = term
            this.search()
        },

        // 검색
        search(){
            this.$http.post("/api/official_league/player/select/career_summary", {
                params: {
                    uid: this.$route.query.id,
                    term: this.term
                }
            }).then((res) => {
                // console.log("성적 요약(CareerSummary)", res)

                if(res.data.length){
                    this.list = res.data.filter(e => e.count_1st + e.count_2nd + e.count_t4 + e.count_t8 > 0)
                }
            })

            this.$http.post("/api/official_league/player/select/career_summary/total_point", {
                params: {
                    uid: this.$route.query.id,
                    term: this.term
                }
            }).then((res) => {
                // console.log("성적 요약(CareerSummary) / total_point", res)

                if(res.data.length){
                    this.total_deck_point1 = res.data[0].total_deck_point1
                }
            })

            this.$http.post("/api/official_league/player/select/career_summary/wcs", {
                params: {
                    uid: this.$route.query.id,
                    term: this.term
                }
            }).then((res) => {
                // console.log("성적 요약(CareerSummary)", res)

                if(res.data.length){
                    this.count_wcs = res.data[0].count_wcs
                }
            })
        }
    }
}
</script>
<style scoped>
/* 테두리 색 변경 (성공) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:8px;
    height:33px !important;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
}

/* 날라가는 거 막는거 성공 (선택지 전체 높이) */
::v-deep .v-select__slot {
    height: 28px;
}

/* 날라가는 거 막는거 성공 */
::v-deep .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 9px;
}
</style>