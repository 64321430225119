<template>
    <v-sheet
        class="ma-1 px-4 py-2 pb-3 rounded-10"
    >
        <!-- 상단 -->
        <div
            class="d-flex align-center justify-space-between mb-3"
            :class="$vuetify.breakpoint.mobile? '':'mt-2'"
        >
            <!-- 제목 -->
            <div
                class="d-flex align-center"
            >
                <!-- PC -->
                <p
                    v-if="!$vuetify.breakpoint.mobile"
                    style="font-size:18px; font-weight:bold;"
                    class="my-0"
                >
                    덱 추이 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                </p>

                <!-- Mobile -->
                <p
                    v-if="$vuetify.breakpoint.mobile"
                    style="font-size:10px; font-weight:bold;"
                    class="mb-2 mt-2"
                >
                    덱 추이 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                </p>
            </div>

            <!-- 선택 상단 -->
            <div>
                <!-- Mobile -->
                <div
                    v-if="$vuetify.breakpoint.mobile"
                    class="d-flex mobile"
                >
                    <!-- 시즌 -->
                    <v-select
                        height="28"
                        style="width:90px;"
                        class="rounded-10 font-weight-bold shrink ml-1"
                        dense
                        outlined
                        hide-details
                        :items="select_list.interval"
                        v-model="interval"
                        @change="search()"
                    >
                        <template v-slot:selection="{ item }">
                            <p class="text-truncate pt-2px" style="height:28px;">{{item}}</p>
                        </template>
                        <template v-slot:append>
                            <v-icon small class="pt-1px" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>

                <!-- PC -->
                <div
                    v-if="!$vuetify.breakpoint.mobile"
                    class="d-flex pc"
                >
                    <v-select
                        style="width:160px; font-size:30px !important;"
                        class="rounded-10 font-weight-bold shrink mr-2"
                        dense
                        outlined
                        hide-details
                        :items="select_list.interval"
                        v-model="interval"
                        @change="search()"
                    ></v-select>
                </div>
            </div>
        </div>

        <!-- 그래프 -->
        <ChartForTier
            v-if="!loading"
            :height="$vuetify.breakpoint.mobile? 140 : 210"
            :list="tier"
            :interval="interval"
        />
    </v-sheet>
</template>
<script>
import ChartForTier from "./components/ChartForTier"

export default {
    components: {
        ChartForTier
    },

    data: () => ({
        // 목록
        list: [],
        total_count: 0,
        tier: [],

        select_list: {
            interval: [
                "월간 추이",
                "주간 추이",
                "일간 추이"
            ]
        },

        loading: true,

        interval: "월간 추이"
    }),

    mounted(){
        this.search()
    },

    methods: {
        // 검색
        async search(){
            this.loading = true
            this.list = []
            this.tier = []
            this.total_count = 0
            const numbers = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
            for (const number of numbers) {
                const response = await this.$http.post("/api/official_league/deck_rank/select/tier_graph", {
                    params: {
                        number: number,
                        interval: this.interval,
                    }
                })

                const list = response.data
                if (list.length === 0) {
                    this.tier.push(5)
                    continue
                }

                const totalCount = list.reduce((sum, item) => sum + item.deck_count, 0)

                const updatedList = list.map(item => ({
                    ...item,
                    tier: this.calculateTier(item, list, totalCount)
                })).sort((a, b) => a.tier - b.tier)

                // console.log(updatedList)

                const targetItem = updatedList.find(item => item.deck_name === this.$route.query.id)
                if (targetItem) {
                    this.tier.push(targetItem.tier)
                } else {
                    this.tier.push(5)
                }

                // console.log(this.tier)
            }

            this.loading = false
        },

        // 티어 계산
        calculateTier(item, list, totalCount){
            let tier_TO
            let point_tier = 5
            let usage_tier = 5


            // 백분위 계산
            const percentile = this.calculatePercentile(list, item)

            // 백분위에 따른 티어 계산
            if (percentile <= 6) {
                tier_TO = 1
            } else if (percentile <= 22) { 
                tier_TO = 2
            } else if (percentile <= 42) {
                tier_TO = 3
            } else if (percentile <= 69) {
                tier_TO = 4
            } else {
                tier_TO = 5
            }

            // 승점 티어
            if(this.interval == "월간 추이"){
                if(item.total_point >= 160){
                    point_tier = 1
                }
                else if(item.total_point >= 80 && item.total_point < 160){
                    point_tier = 2
                }
                else if(item.total_point >= 40 && item.total_point < 80){
                    point_tier = 3
                }
                else if(item.total_point >= 24 && item.total_point < 40){
                    point_tier = 4
                }
                else if(item.total_point < 24){
                    point_tier = 5
                }
            }
            else if(this.interval == "주간 추이"){
                if(item.total_point >= 40){
                    point_tier = 1
                }
                else if(item.total_point >= 20 && item.total_point < 40){
                    point_tier = 2
                }
                else if(item.total_point >= 10 && item.total_point < 20){
                    point_tier = 3
                }
                else if(item.total_point >= 6 && item.total_point < 10){
                    point_tier = 4
                }
                else if(item.total_point < 6){
                    point_tier = 5
                }
            }
            else if(this.interval == "일간 추이"){
                if(item.total_point >= 6){
                    point_tier = 1
                }
                else if(item.total_point >= 4 && item.total_point < 6){
                    point_tier = 2
                }
                else if(item.total_point >= 2 && item.total_point < 4){
                    point_tier = 3
                }
                else if(item.total_point >= 1 && item.total_point < 2){
                    point_tier = 4
                }
                else if(item.total_point < 1){
                    point_tier = 5
                }
            }

            // 점유율 티어
            if(this.calculateProbability(item.deck_count, totalCount) >= 5){
                usage_tier = 1
            }
            else if(
                this.calculateProbability(item.deck_count, totalCount) >= 1
                && this.calculateProbability(item.deck_count, totalCount) < 5
            ){
                usage_tier = 2
            }
            else if(
                this.calculateProbability(item.deck_count, totalCount) >= 0.1
                && this.calculateProbability(item.deck_count, totalCount) < 1
            ){
                usage_tier = 3
            }
            else if(
                this.calculateProbability(item.deck_count, totalCount) >= 0.05 &&
                this.calculateProbability(item.deck_count, totalCount) < 0.1
            ){
                usage_tier = 4
            }
            else if(this.calculateProbability(item.deck_count, totalCount) < 0.05){
                usage_tier = 5
            }

            // console.log(tier_TO)
            // console.log(point_tier)
            // console.log(usage_tier)


            if(Math.max(point_tier, usage_tier) == 1){
                if(tier_TO != 1){
                    return tier_TO
                }
                else{
                    return 1
                }
            }
            else if(Math.max(point_tier, usage_tier) == 2){
                if(tier_TO != 2){
                    return tier_TO
                }
                else{
                    return 2
                }
            }
            else if(Math.max(point_tier, usage_tier) == 3){
                if(tier_TO != 3){
                    return tier_TO
                }
                else{
                    return 3
                }
            }
            else if(Math.max(point_tier, usage_tier) == 4){
                if(tier_TO != 4){
                    return tier_TO
                }
                else{
                    return 4
                }
            }
            else if(Math.max(point_tier, usage_tier) == 5){
                if(tier_TO != 5){
                    return tier_TO
                }
                else{
                    return 5
                }
            }
            else{
                return 5
            }
        },

        // 점유율 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        },

        // 티어 TO 백분위 계산
        calculatePercentile(list, item) {
            const sortedList = [...list].sort((a, b) => b.total_point - a.total_point)
            const index = sortedList.findIndex(i => i.deck_name === item.deck_name)
            return (index / sortedList.length) * 100
        },
    }
}
</script>
<style scoped>
/* 테두리 색 변경 (성공) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:8px;
    height:33px !important;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
}

/* 날라가는 거 막는거 성공 (선택지 전체 높이) */
::v-deep .v-select__slot {
    height: 28px;
}

/* 날라가는 거 막는거 성공 */
::v-deep .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 9px;
}

/* 날라가는 거 막는거 성공 (PC) */
::v-deep .pc .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 14px;
    margin-left:4px;
}
</style>