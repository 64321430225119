<template>
    <div>
        <!-- 상단 -->
        <Header />

        <!-- 검색 -->
        <v-sheet
            class="pa-6 rounded-15"
        >
            <p
                class="font-weight-bold"
            >
                기본 정보
            </p>

            <div
                class="pa-6"
            >
                <!-- 대회제목 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#F8468D;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            대회제목
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회에 제목을 입력하세요</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center mb-2"
                        >
                            <p
                                class="mb-0 text-body-2 mr-4"
                                style="color:#ADAFCA;"
                            >
                                한글
                            </p>
                            <v-text-field
                                style="width:400px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                v-model="title_kr"
                            ></v-text-field>
                        </div>
                        
                        <div
                            class="d-flex align-center mb-2"
                        >
                            <p
                                class="mb-0 text-body-2 mr-4"
                                style="color:#ADAFCA;"
                            >
                                영어
                            </p>
                            <v-text-field
                                style="width:400px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                v-model="title_en"
                            ></v-text-field>
                        </div>

                        <div
                            class="d-flex align-center mb-2"
                        >
                            <p
                                class="mb-0 text-body-2 mr-4"
                                style="color:#ADAFCA;"
                            >
                                현지
                            </p>
                            <v-text-field
                                style="width:400px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                v-model="title_local"
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 대회날짜 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#F8468D;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            대회날짜
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회가 진행되는 날짜를 설정합니다</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center mb-2"
                        >
                            <v-text-field
                                style="width:200px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                type="date"
                                v-model="start_date"
                            ></v-text-field>
                            <p
                                class="mb-0 text-body-2 mx-4"
                                style="color:#ADAFCA;"
                            >
                                ~
                            </p>
                            <v-text-field
                                style="width:200px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                type="date"
                                v-model="end_date"
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 대회 정보 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#B3B3C2;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            대회 정보
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회링크, 대회영상, 예선전적, 대회정보 관련 링크</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center mb-2"
                        >
                            <p
                                class="mb-0 text-body-2 mx-4 text-end"
                                style="color:#ADAFCA;"
                            >
                                아이콘 사진<br/>
                                200X200
                            </p>
                            <v-file-input
                                style="width:200px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                v-model="uploadFile"
                                prepend-icon=""
                                prepend-inner-icon="mdi-image-outline"
                                placeholder="파일 업로드"
                            ></v-file-input>
                        </div>
                    </div>
                </div>

                <!-- TIPTAP -->
                <TipTapWriter 
                    class="tiptap_style mt-4 ml-4"
                    :options="options"
                />

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 대회 주최기관 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#B3B3C2;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            대회 주최기관
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">주최자 입력</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center"
                        >
                            <v-text-field
                                style="width:400px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                v-model="organizer"
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 관련 링크 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#B3B3C2;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            관련 링크
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회 설명 페이지나 참가신청 페이지 등</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center"
                        >
                            <v-text-field
                                style="width:400px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                v-model="reference_link"
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 레귤레이션 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#F8468D;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            레귤레이션
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회 설명 페이지나 참가신청 페이지 등</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center"
                        >
                            <v-sheet
                                style="cursor:pointer;"
                                class="pr-2 py-2 rounded-10 d-flex align-center"
                                :style="regulation == '스탠다드_대한민국'? 'border:2px solid #23D2E2;':'border:2px solid #eaeaea'"
                                @click="regulation = '스탠다드_대한민국'"
                            >
                                <v-img
                                    style="margin-right:-4px;"
                                    width="60"
                                    src="@/assets/official_league/스탠다드_대한민국.png"
                                ></v-img>
                                <p
                                    class="mb-0"
                                    style="line-height:18px;"
                                >
                                    <span style="font-size:14px; font-weight:bold;">스탠다드</span><br/>
                                    <span style="font-size:12px;">대한민국</span>
                                </p>
                            </v-sheet>

                            <v-sheet
                                style="cursor:pointer;"
                                class="ml-2 pr-2 py-2 rounded-10 d-flex align-center"
                                :style="regulation == '스탠다드_일본'? 'border:2px solid #23D2E2;':'border:2px solid #eaeaea'"
                                @click="regulation = '스탠다드_일본'"
                            >
                                <v-img
                                    style="margin-right:-4px;"
                                    width="60"
                                    src="@/assets/official_league/스탠다드_일본.png"
                                ></v-img>
                                <p
                                    class="mb-0"
                                    style="line-height:18px;"
                                >
                                    <span style="font-size:14px; font-weight:bold;">스탠다드</span><br/>
                                    <span style="font-size:12px;">일본</span>
                                </p>
                            </v-sheet>

                            <v-sheet
                                style="cursor:pointer;"
                                class="ml-2 pr-2 py-2 rounded-10 d-flex align-center"
                                :style="regulation == '스탠다드_인터내셔널'? 'border:2px solid #23D2E2;':'border:2px solid #eaeaea'"
                                @click="regulation = '스탠다드_인터내셔널'"
                            >
                                <v-img
                                    style="margin-right:-4px;"
                                    width="60"
                                    src="@/assets/official_league/스탠다드_인터내셔널.png"
                                ></v-img>
                                <p
                                    class="mb-0"
                                    style="line-height:18px;"
                                >
                                    <span style="font-size:14px; font-weight:bold;">스탠다드</span><br/>
                                    <span style="font-size:12px;">인터내셔널</span>
                                </p>
                            </v-sheet>

                            <v-sheet
                                style="cursor:pointer;"
                                class="ml-2 pr-2 py-2 rounded-10 d-flex align-center"
                                :style="regulation == '스탠다드_아시아'? 'border:2px solid #23D2E2;':'border:2px solid #eaeaea'"
                                @click="regulation = '스탠다드_아시아'"
                            >
                                <v-img
                                    style="margin-right:-4px;"
                                    width="60"
                                    src="@/assets/official_league/스탠다드_아시아.png"
                                ></v-img>
                                <p
                                    class="mb-0"
                                    style="line-height:18px;"
                                >
                                    <span style="font-size:14px; font-weight:bold;">스탠다드</span><br/>
                                    <span style="font-size:12px;">아시아</span>
                                </p>
                            </v-sheet>

                            <v-sheet
                                style="cursor:pointer;"
                                class="ml-2 pr-2 py-2 rounded-10 d-flex align-center"
                                :style="regulation == '익스팬디드'? 'border:2px solid #23D2E2;':'border:2px solid #eaeaea'"
                                @click="regulation = '익스팬디드'"
                            >
                                <v-img
                                    style="margin-right:-4px;"
                                    width="60"
                                    src="@/assets/official_league/익스팬디드.png"
                                ></v-img>
                                <p
                                    class="mb-0"
                                    style="line-height:18px;"
                                >
                                    <span style="font-size:14px; font-weight:bold;">익스팬디드</span>
                                </p>
                            </v-sheet>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 시즌 / 유형 -->
                <div
                    class="d-flex align-start"
                >
                    <div>
                        <div
                            class="d-flex align-center"
                        >
                            <div
                                style="height:32px; width:2px; background:#F8468D;"
                                class="mr-4"
                            ></div>

                            <p
                                style="font-size:16px; font-weight:600;"
                                class="mb-0"
                            >
                                시즌 / 유형
                                <br/>
                                <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회가 진행되는 시즌을 선택합니다.</span>
                            </p>
                        </div>
                    
                        <v-sheet
                            class="py-1 px-4 rounded-5 mt-2 ml-4"
                            color="#eaeaff"
                            height="44"
                        >
                            <p
                                style="font-size:12px;"
                                class="icu_purple--text"
                            >
                                플레이어 성적, 덱 레시피 성적등이 시즌별로<br/>
                                분류 되어 데이터가 합산됩니다.
                            </p>
                        </v-sheet>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center justify-end mb-2"
                        >
                            <p
                                class="mb-0 text-body-2 mr-4"
                                style="color:#ADAFCA;"
                            >
                                시즌
                            </p>
                            <v-select
                                style="width:120px;"
                                class="rounded-10 mr-2"
                                dense
                                hide-details
                                outlined
                                placeholder="국가"
                                :items="select_list.season_nation"
                                v-model="season_nation"
                                @change="update_season_list()"
                            ></v-select>
                            <v-select
                                style="width:400px;"
                                class="rounded-10 shrink"
                                dense
                                hide-details
                                outlined
                                :items="select_list.season_list"
                                v-model="season_name"
                            ></v-select>
                        </div>
                        
                        <div
                            class="d-flex align-center justify-end mb-2"
                        >
                            <p
                                class="mb-0 text-body-2 mr-4"
                                style="color:#ADAFCA;"
                            >
                                유형
                            </p>
                            <v-select
                                style="width:400px;"
                                class="rounded-10 shrink"
                                dense
                                hide-details
                                outlined
                                placeholder="리그 유형"
                                :items="select_list.league_type"
                                v-model="league_type"
                            ></v-select>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 참가인원 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#F8468D;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            참가인원
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회에 참가하는 인원수를 설정합니다.</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center"
                        >
                            <p
                                class="mb-0 text-body-2 mr-4"
                                style="color:#ADAFCA;"
                            >
                                1일차
                            </p>
                            <v-text-field
                                style="width:120px;"
                                class="rounded-10 mr-8"
                                dense
                                hide-details
                                outlined
                                reverse
                                prefix="명"
                                v-model="total"
                            ></v-text-field>

                            <p
                                class="mb-0 text-body-2 mr-4"
                                style="color:#ADAFCA;"
                            >
                                2일차
                            </p>
                            <v-text-field
                                style="width:120px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                reverse
                                prefix="명"
                                v-model="total2"
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 연령구분 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#F8468D;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            연령구분
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회에 참가 가능한 연령을 설정합니다.</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center"
                        >
                            <v-select
                                style="width:400px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                :items="select_list.age_group"
                                v-model="age_group"
                            ></v-select>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-2 mb-4"></v-divider>

                <!-- 대회 국가 -->
                <div
                    class="d-flex align-start"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <div
                            style="height:32px; width:2px; background:#F8468D;"
                            class="mr-4"
                        ></div>

                        <p
                            style="font-size:16px; font-weight:600;"
                            class="mb-0"
                        >
                            대회 국가
                            <br/>
                            <span style="font-size:12px; font-weight:500; color:#ADAFCA;">대회가 열린 국가르 선택합니다.</span>
                        </p>
                    </div>

                    <v-spacer></v-spacer>

                    <div>
                        <div
                            class="d-flex align-center"
                        >
                            <v-autocomplete
                                style="width:400px;"
                                class="rounded-10"
                                dense
                                hide-details
                                outlined
                                :items="select_list.league_nation"
                                v-model="league_nation"
                                item-text="country_kr"
                                item-value="code"
                            ></v-autocomplete>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 버튼 -->
            <div class="mt-2 pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="110"
                    height="42"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    생성
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="110"
                    height="42"
                    depressed
                    color="#E4E4E4"
                    dark
                    class="font-weight-bold rounded-10"
                    @click="cancel()"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>
    </div>
</template>
<script>
import Header from './components/Header'
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        Header,
        TipTapWriter
    },

    data: () => ({
        select_list: {
            season_nation: [
                "국내판",
                "북미판",
                "일본판"
            ],

            season_list: [],

            season: {
                en: [],
                kr: [],
                jp: []
            },

            league_type: [],

            age_group: [
                "전체연령",
                "마스터",
                "시니어",
                "주니어"
            ],

            league_nation: []
        },

        uploadFile: {},

        title_kr: "",
        title_en: "",
        title_local: "",

        start_date: "",
        end_date: "",
        image: "",

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        organizer: "",
        reference_link: "",
        regulation: "",
        season_nation: "",
        season_name: "",
        league_type: "",

        total: 0,
        total2: 0,
        age_group: "",
        league_nation: "",

        componentKey:0,

        country_list: [],
    }),

    async mounted(){
        if(!(this.$store.state.is_logined && (this.$store.state.type== '관리자' || this.$store.state.type == '운영진'))){
            alert("해당 페이지에대한 접근 권한이 없습니다.")
            this.$router.push('/')
        }
        
        // 국가 목록 불러오기
        this.select_list.league_nation = await this.$load_country_list()

        this.country_list = await this.$load_country_list()

        console.log(this.country_list)

        // 제품명 시즌 목록 불러오기
        this.loadSeasonList()


        // 공식대회 리그유형 목록 불러오기
        this.official_league_type_list_load()
    },

    methods: {
        // region 구하기
        getContinent(league_nation) {
            const country = this.country_list.find(c => c.code === league_nation)
            return country ? country.continent : ''
        },

        // 공식대회 리그유형 목록 불러오기
        official_league_type_list_load(){
            this.$http.get('/api/admin/league/official_league_type_list/list')
            .then((res) => {
                // console.log(res)
                this.select_list.league_type = res.data.map(e => e.league_type)
            })
        },

        update_season_list(){
            if(this.season_nation === '국내판'){
                this.select_list.season_list = this.select_list.season.kr
            } else if(this.season_nation === '북미판'){
                this.select_list.season_list = this.select_list.season.en
            } else if(this.season_nation === '일본판'){
                this.select_list.season_list = this.select_list.season.jp
            }
        },

        // 제품명 시즌 목록 불러오기
        loadSeasonList(){
            // 영어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "en"
                }
            }).then(res => {
                // console.log(res)
                this.select_list.season.en = res.data.map(a => a.name)
            })

            // 한글
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                // console.log(res)
                this.select_list.season.kr = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "jp"
                }
            }).then(res => {
                // console.log(res)
                this.select_list.season.jp = res.data.map(a => a.name)
            })
        },
        
        // 제출
        submit(){
            if(!this.title_kr){
                alert("대회제목:한글이 없습니다")
                return
            }
            if(!this.title_en){
                alert("대회제목:영어가 없습니다")
                return
            }
            if(!this.start_date){
                alert("start_date가 없습니다")
                return
            }
            if(!this.end_date){
                alert("end_date가 없습니다")
                return
            }
            if(!this.regulation){
                alert("regulation이 없습니다")
                return
            }
            if(!this.season_nation){
                alert("season_nation이 없습니다")
                return
            }
            if(!this.season_name){
                alert("season_name이 없습니다")
                return
            }
            if(!this.league_type){
                alert("league_type이 없습니다")
                return
            }
            if(!this.total){
                alert("total가 없습니다")
                return
            }
            if(!this.total2){
                alert("total2가 없습니다")
                return
            }
            if(!this.age_group){
                alert("age_group이 없습니다")
                return
            }
            if(!this.league_nation){
                alert("league_nation이 없습니다")
                return
            }

            if(this.uploadFile.name)
            {
                var file = this.uploadFile
                var formData = new FormData()
                formData.append("image", file)
                // console.log(formData)
                this.$http.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "official_league"
                    }
                }).then((res_image) => {
                    this.$http.post("/api/official_league/insert/league", {
                        params: {
                            title_kr: this.title_kr,
                            title_en: this.title_en,
                            title_local: this.title_local,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            image: res_image.data,
                            content: this.options.content,
                            organizer: this.organizer,
                            reference_link: this.reference_link,
                            regulation: this.regulation,
                            season_nation: this.season_nation,
                            season_name: this.season_name,
                            league_type: this.league_type,
                            total: this.total,
                            total2: this.total2,
                            age_group: this.age_group,
                            league_nation: this.league_nation,
                            league_region: this.getContinent(this.league_nation)
                        }
                    }).then((res) => {
                        // console.log(res)
                        if(res.data.affectedRows){
                            alert("공식대회가 정상적으로 입력되었습니다.")
                            this.$router.push("/official_league/list")
                        }
                    })
                })
            }
            else{
                this.$http.post("/api/official_league/insert/league", {
                    params: {
                        title_kr: this.title_kr,
                        title_en: this.title_en,
                        title_local: this.title_local,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        image: "default.png",
                        content: this.options.content,
                        organizer: this.organizer,
                        reference_link: this.reference_link,
                        regulation: this.regulation,
                        season_nation: this.season_nation,
                        season_name: this.season_name,
                        league_type: this.league_type,
                        total: this.total,
                        total2: this.total2,
                        age_group: this.age_group,
                        league_nation: this.league_nation,
                        league_region: this.getContinent(this.league_nation)
                    }
                }).then((res) => {
                    // console.log(res)
                    if(res.data.affectedRows){
                        alert("공식대회가 정상적으로 입력되었습니다.")
                        this.$router.push("/official_league/list")
                    }
                })
            }
        },

        // 취소
        cancel(){
            if(confirm("지금 작성하고 있는 내용은 저장되지 않으며\n이전 페이지로 이동됩니다. 이동하시겠습니까?"))
            {
                this.$router.go(-1)
            }
        }
    }
}
</script>
<style>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>