<template>
    <v-sheet
        class="ma-2 px-4 py-2 pb-3 rounded-10"
        outlined
    >
        <!-- # 상단 -->
        <div
            class="d-flex align-center justify-space-between mb-1"
            :class="$vuetify.breakpoint.mobile? '':'mt-2'"
        >
            <!-- # 제목 -->
            <div
                class="d-flex align-center"
            >
                <!-- # PC -->
                <p
                    v-if="!$vuetify.breakpoint.mobile"
                    style="font-size:18px; font-weight:bold;"
                    class="my-0"
                >
                    덱 레시피 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                </p>

                <!-- # Mobile -->
                <p
                    v-if="$vuetify.breakpoint.mobile"
                    style="font-size:10px; font-weight:bold;"
                    class="mb-2 mt-2"
                >
                    덱 레시피 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                </p>
            </div>

            <!-- # 선택 상단 -->
            <SelectHeader
                :global_term="term"
                @term_changed="term_changed_handler"
                :key="componentKey + 'deckHeader'"
            />
        </div>

        <!-- # 목록 -->
        <div>
            <!-- # 목록 (Mobile) -->
            <div
                v-if="$vuetify.breakpoint.mobile"
            >
                <!-- # 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    height="32"
                    color="#F0F3F5"
                >
                    <div
                        style="width:60%; font-size:12px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        덱
                    </div>
                    <div
                        class="d-flex align-center"
                        style="width:40%; position:relative;"
                    >
                        <div
                            style="width:50%; font-size:12px; height:38px;"
                            class="text-center d-flex align-center justify-center"
                        >
                            덱레시피
                        </div>
                        <div
                            style="width:50%; font-size:12px; height:38px;"
                            class="text-center d-flex align-center justify-center"
                        >
                            승점
                        </div>
                    </div>
                </v-sheet>

                <!-- # 목록 값 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index+'list'"
                    height="38"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                    @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier1)"
                >
                    <!-- # 덱 (아이콘 & 덱분류값 & 대회이름) -->
                    <div>
                        <!-- # 덱 분류값이 있는 경우 (아이콘 & 덱분류값 & 대회이름) -->
                        <div
                            v-if="item.deck_icons"
                            style="width:60%"
                            class="d-flex align-center pl-2"
                        >
                            <!-- # 덱 아이콘 -->
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        style="font-size:10px;"
                                        class="d-flex align-center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <div
                                            class="d-flex align-center mt-1"
                                        >
                                            <v-img
                                                v-for="icon in item.deck_icons.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                contain
                                                class="mb-1 mx-1"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>
                                    </div>
                                </template>
                                <!-- # 아이콘 툴팁 (덱분류값) -->
                                <div class="text-center white--text pa-1">
                                    {{deck_name_translate(item.deck_identifier1)}}
                                </div>
                            </v-tooltip>

                            <!-- # 덱분류값 -->
                            <div
                                class="pr-0 pl-2px text-truncate"
                                style="min-width:140px; font-size:10px;"
                            >
                                <!-- # 덱분류값 -->
                                <p class="mb-0">
                                    {{deck_name_translate(item.deck_identifier1)}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <!-- # 덱 레시피 & 결과 -->
                    <div
                        class="d-flex align-center"
                        style="width:40%; position:relative;"
                    >
                        <p
                            style="font-size:10px; width:50%; margin-top:2px;"
                            class="mb-0 text-center blue--text text-truncate font-weight-bold"
                            @click.stop="deckView(item, 'DAY1')"
                        >
                            덱 보기
                        </p>
                        <div
                            style="width:50%; font-size:12px; height:38px; font-weight:bold; line-height:16px;"
                            class="text-center mt-10px"
                        >
                            <p class="mb-0">{{item.top_cut}}</p>
                            <p class="grey--text font-weight-regular mb-0" style="font-size:10px; margin-top:-2px;">승점 {{item.deck_point1}}</p>
                        </div>
                    </div>
                </v-sheet>
            </div>

            <!-- # 목록 (PC) -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
            >
                <!-- # 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    height="40"
                    color="#F0F3F5"
                    width="100%"
                >
                    <div
                        style="width:50%; font-size:14px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        덱
                    </div>
                    <div
                        class="d-flex align-center"
                        style="width:50%; position:relative;"
                    >
                        <div
                            style="width:50%; font-size:14px; height:38px;"
                            class="text-center d-flex align-center justify-center"
                        >
                            덱레시피
                        </div>
                        <div
                            style="width:50%; font-size:14px; height:38px;"
                            class="text-center d-flex align-center justify-center pl-1"
                        >
                            승점
                        </div>
                    </div>
                </v-sheet>
                
                <!-- # 목록 값 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index+'list'"
                    height="56"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                    @click="$router.push('/official_league/deck_rank/detail?id='+item.deck_identifier1)"
                >
                    <!-- # 덱 (아이콘 & 덱분류값 & 대회이름) -->
                    <div>
                        <!-- # 덱 분류값이 있는 경우 (아이콘 & 덱분류값 & 대회이름) -->
                        <div
                            v-if="item.deck_icons"
                            class="pl-2 d-flex align-center justify-center"
                        >
                            <!-- # 덱 아이콘 -->
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        style="font-size:10px;"
                                        class="d-flex align-center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <!-- # 메인 덱 분류 아이콘 (DAY1 & DAY2) -->
                                        <div
                                            class="d-flex align-center mt-1"
                                        >
                                            <v-img
                                                v-for="icon in item.deck_icons.split(',')" :key="icon"
                                                :width="$vuetify.breakpoint.mobile? 20 : 34"
                                                :height="$vuetify.breakpoint.mobile? 20 : 34"
                                                contain
                                                class="mb-1 mx-1"
                                                style="opacity:1"
                                                :src="'/upload/pokesprite/'+icon+'.png'"
                                            ></v-img>
                                        </div>
                                    </div>
                                </template>
                                <!-- # 아이콘 툴팁 (덱분류값) -->
                                <div class="text-center white--text pa-1">
                                    {{deck_name_translate(item.deck_identifier1)}}
                                </div>
                            </v-tooltip>

                            <!-- # 덱분류값 -->
                            <div
                                class="pl-2"
                            >
                                <!-- # 덱분류값 -->
                                <div style="font-size:14px;">
                                    {{deck_name_translate(item.deck_identifier1)}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <v-spacer></v-spacer>
                    <div
                        class="d-flex align-center pl-1"
                        style="width:50%; position:relative;"
                    >
                        <p
                            v-if="item.deck_day1 && item.deck_day1.length > 10"
                            style="font-size:14px; width:50%;"
                            class="mb-0 text-center blue--text mr-2 text-truncate font-weight-bold"
                            @click.stop="deckView(item, 'DAY1')"
                        >
                            덱 보기
                        </p>
                        <div
                            style="width:50%; font-size:14px; height:38px; font-weight:bold; line-height:16px;"
                            class="text-center mt-2"
                        >
                            <span>{{item.top_cut}}</span>
                            <br/>
                            <span class="grey--text font-weight-regular" style="font-size:10px;">승점 {{item.deck_point1}}</span>
                        </div>
                    </div>
                </v-sheet>
            </div>
        </div>

        <!-- # 페이징 -->
        <v-pagination
            class="mt-2"
            :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
            color="primary"
            v-model="page"
            :length="pageLength"
            :total-visible="7"
        ></v-pagination>

        <!-- # 덱 보기 (dialog) -->
        <v-dialog
            v-model="dialog.deckView"
            :width="$vuetify.breakpoint.mobile? '':1068"
            content-class="rounded-15"
        >
            <DeckView
                :player_country="selected_item.player_country"
                :player_kr="selected_item.player_kr"
                :player_en="selected_item.player_en"
                :player_local="selected_item.player_local"
                :player_age_group="selected_item.player_age_group"
                :is_masking="selected_item.is_masking"
                :deck_name_kr="selected_item.deck_name_kr"
                :deck_name_en="selected_item.deck_name_en"
                :sub_deck_name_kr="selected_item.sub_deck_name_kr"
                :sub_deck_name_en="selected_item.sub_deck_name_en"
                :deck_card_list="selected_item.deck_card_list"
                :title_kr="selected_item.league_title"
                :title_en="selected_item.league_title_en"
                :top_cut="selected_item.top_cut"
                @close="dialog.deckView = false; load_card_shop_standing_list()"
                :key="componentKey"
            />
        </v-dialog>
    </v-sheet>
</template>
<script>
import DeckView from '@/views/official_league/components/read/DeckView'
import SelectHeader from './components/SelectHeader'

export default {
    props: ["global_term"],

    components: {
        DeckView,
        SelectHeader
    },

    data: () => ({
        term: "최근 3개월",

        list: [],
        currentPageList: [],
        page: 1,
        pageLength: 1,

        selected_item: {},
        deck_auto_translate_list: [],
        componentKey: 0,

        dialog: {
            deckView: false
        },
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*6,(this.page)*6)
        },

        global_term(newVal){
            this.term = newVal
        }
    },

    mounted(){
        this.term = this.global_term

        // # 덱분류값 번역 목록 불러오기
        this.load_deck_auto_translate_list()

        // 덱 레시피 목록 불러오기
        this.load()
    },

    methods: {
        term_changed_handler(term){
            this.term = term
            this.load()
        },

        // # 덱 레시피 목록 불러오기
        load(){
            this.$http.post("/api/official_league/player/select/deck_recipe", {
                params: {
                    uid: this.$route.query.id,
                    term: this.term
                }
            }).then((res) => {
                console.log("deck_recipe", res)
                // 덱 정보없음 미리 필터
                this.list = res.data.filter(item => 
                    item.deck_icons
                )

                this.currentPageList = this.list.slice(0,6)
                this.pageLength = Math.ceil(this.list.length/6)
                if(this.pageLength > 99) this.pageLength = 99
                this.page = 1
            })
        },

        // # 덱분류값 번역 목록 불러오기
        load_deck_auto_translate_list(){
            this.$http.get('/api/admin/league/deck_auto_translate_list/list')
            .then((res) => {
                this.deck_auto_translate_list = res.data
            })
        },

        // # 덱분류값 한글로 번역
        deck_name_translate(en) {
            if(!en) return 
            let translation = this.deck_auto_translate_list.find(item => item.en === en)
            return translation ? (translation.kr || en) : en
        },

        // 덱 보기
        deckView(item, day){
            if(day == "DAY1" && !item.deck_identifier1){
                alert("입력되지 않은 덱 레시피입니다")
                return
            }
            else if(day == "DAY2" && !item.deck_identifier2){
                alert("입력되지 않은 덱 레시피입니다")
                return
            }

            this.selected_item = item

            if(day == 'DAY1'){
                this.selected_item.deck_name_en = item.deck_identifier1
                this.selected_item.deck_name_kr = this.deck_name_translate(item.deck_identifier1)
                this.selected_item.sub_deck_name_en = item.deck_identifier1
                this.selected_item.sub_deck_name_kr = this.deck_name_translate(item.deck_identifier1)
                this.selected_item.deck_card_list = this.selected_item.deck_card_list1
            }

            this.dialog.deckView = true
            this.componentKey++
        },
    }
}
</script>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>