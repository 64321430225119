<template>
    <div
        style="position:relative;"
    >
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="rounded-15 px-10 pt-6 pb-4"
            color="#F8F7FB"
            style="border:2px solid #1DA1F2; position:relative;"
        >
            <!-- 제목 -->
            <p class="mb-2 font-weight-bold" style="font-size:20px;">
                카드샵 입력
            </p>

            <!-- 공식대회 제목 -->
            <p class="mb-2" style="font-size:15px; font-weight:500; color:#23D2E2;">
                {{title_kr}}
            </p>

            <!-- 내용 -->
            <v-sheet class="py-4 px-2 mt-3 rounded-10">
                <!-- 상단 -->
                <div
                    class="d-flex"
                >
                    <div
                        style="width:180px;"
                        class="pl-4"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        카드샵
                    </div>

                    <div
                        style="width:130px;"
                        class="pl-4"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        순위
                    </div>

                    <div
                        style="width:300px;"
                    >
                        <v-icon size="8" color="#b7b7c5" class="pb-2px mr-1">mdi-circle</v-icon>
                        라운드 총 전적
                    </div>

                    <div
                        style="width:300px;"
                    >
                        <v-icon size="8" color="#b7b7c5" class="pb-2px mr-1">mdi-circle</v-icon>
                        경기 총 전적
                    </div>
                </div>

                <v-divider class="mt-3 mb-1"></v-divider>

                <!-- 입력란 -->
                <div
                    v-if="list != undefined"
                    :key="componentKey"
                >
                    <div
                        class="d-flex"
                        v-for="(item, index) in list" :key="index"
                        style="border-bottom:1px solid #eee;"
                    >
                        <div
                            style="width:180px;"
                            class="d-flex align-center pl-5 pr-4"
                        >
                            <v-autocomplete
                                :items="select_list.cardshop"
                                v-model="item.cardshop"
                                solo
                                flat
                                hide-details
                                placeholder="카드샵"
                            ></v-autocomplete>
                        </div>

                        <!-- 순위 -->
                        <div
                            style="width:130px;"
                            class="d-flex align-center pl-4"
                        >
                            <v-text-field
                                v-model="item.standing"
                                solo
                                flat
                                hide-details
                                type="number"
                                placeholder="순위"
                            ></v-text-field>
                        </div>

                        <!-- 라운드 총 전적 -->
                        <div
                            style="width:300px;"
                            class="d-flex justify-space-between align-center px-2"
                        >
                            <v-text-field
                                class="rounded-10"
                                v-model="item.round_win"
                                outlined
                                dense
                                hide-details
                                type="number"
                                placeholder="승"
                            ></v-text-field>
                            <span class="mx-2 font-weight-bold">-</span>
                            <v-text-field
                                class="rounded-10"
                                v-model="item.round_lose"
                                outlined
                                dense
                                hide-details
                                type="number"
                                placeholder="패"
                            ></v-text-field>
                            <span class="mx-2 font-weight-bold">-</span>
                            <v-text-field
                                class="rounded-10"
                                v-model="item.round_tie"
                                outlined
                                dense
                                hide-details
                                type="number"
                                placeholder="무"
                            ></v-text-field>
                        </div>

                        <!-- 경기 총 전적 -->
                        <div
                            style="width:300px;"
                            class="d-flex justify-space-between align-center px-2"
                        >
                            <v-text-field
                                class="rounded-10"
                                v-model="item.total_win"
                                outlined
                                dense
                                hide-details
                                type="number"
                                placeholder="승"
                            ></v-text-field>
                            <span class="mx-2 font-weight-bold">-</span>
                            <v-text-field
                                class="rounded-10"
                                v-model="item.total_lose"
                                outlined
                                dense
                                hide-details
                                type="number"
                                placeholder="패"
                            ></v-text-field>
                            <span class="mx-2 font-weight-bold">-</span>
                            <v-text-field
                                class="rounded-10"
                                v-model="item.total_tie"
                                outlined
                                dense
                                hide-details
                                type="number"
                                placeholder="무"
                            ></v-text-field>
                        </div>

                        <div
                            class="d-flex align-center"
                        >
                            <v-btn
                                class="px-2"
                                text
                                @click="subtractField(index)"
                            >
                                <v-icon color="red">mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>

                <v-btn
                    outlined
                    class="rounded-xl d-flex mx-auto px-6 mt-4"
                    color="#B3B3C2"
                    @click="addField()"
                >
                    <v-icon color="primary">mdi-plus</v-icon>
                    <span style="color:#424361; font-weight:500;">추가</span>
                </v-btn>
            </v-sheet>

            <!-- 버튼 -->
            <div class="mt-4 pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="110"
                    height="40"
                    dark
                    depressed
                    color="icu_purple"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    입력
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="110"
                    height="40"
                    depressed
                    dark
                    color="#B3B3C2"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; bottom: 140px; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="120"
        ></v-progress-circular>
    </div>
</template>
<script>
export default {
    props: [
        "title_kr",
    ],

    data: () => ({
        select_list: {
            cardshop: [
                "역삼",
                "수원",
                "서면",
                "원주",
                "인천",
                "광주",
                "평택",
                "울산",
                "대구",
                "용산",
            ]
        },

        list: [
            {
                cardshop: "",
                standing: 0,
                round_win: 0,
                round_lose: 0,
                round_tie: 0,
                total_win: 0,
                total_lose: 0,
                total_tie: 0
            }
        ],

        componentKey: 0,
        first_list_length: 0,
        loading: false,
    }),

    mounted(){
        // 카드샵 스탠딩 목록 불러오기
        this.load_cardshop_standing_list()
    },

    methods: {
        // 행 추가
        addField(){
            this.list.push(
                {
                    cardshop: "",
                    standing: 0,
                    round_win: 0,
                    round_lose: 0,
                    round_tie: 0,
                    total_win: 0,
                    total_lose: 0,
                    total_tie: 0
                }
            )
        },

        // 행 삭제
        subtractField(index){
            this.list.splice(index,1)
        },

        // 카드샵 스탠딩 목록 불러오기
        load_cardshop_standing_list(){
            this.$http.post("/api/official_league/cardshop/select", {
                params: {
                    league_id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length){
                    this.list = res.data
                    this.first_list_length = res.data.length
                }
            })
        },

        // 카드샵 스탠딩 입력
        async cardshop_standing_delete_and_insert(){
            // 삭제
            await this.$http.post("/api/official_league/cardshop/delete", {
                params: {
                    league_id: this.$route.query.id,
                }
            }).then(async(res) => {
                // 재입력
                for(let i=0; i<this.list.length; i++){
                    await this.$http.post("/api/official_league/cardshop/insert", {
                        params: {
                            league_id: this.$route.query.id,
                            cardshop: this.list[i].cardshop,
                            standing: this.list[i].standing,
                            round_win: this.list[i].round_win,
                            round_lose: this.list[i].round_lose,
                            round_tie: this.list[i].round_tie,
                            total_win: this.list[i].total_win,
                            total_lose: this.list[i].total_lose,
                            total_tie: this.list[i].total_tie,
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                }
            })
        },

        // 제출
        async submit(){
            // 필수입력값 경고문구
            for (let item of this.list) {
                if (!item.cardshop) {
                    alert('카드샵이 선택되지 않았습니다.')
                    return
                }
                else if (!item.standing) {
                    alert('순위가 입력되지 않았습니다.')
                    return
                }
            }

            this.loading = true

            // 스탠딩 삭제 & 입력
            await this.cardshop_standing_delete_and_insert()

            this.loading = false

            alert("카드샵 입력이 완료되었습니다.")
            this.$emit("close")
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
<style scoped>
::v-deep .v-select .v-input__append-inner {
    margin-left: -8px; /* 아이콘을 왼쪽으로 이동 */
    width: 24px; /* 아이콘 컨테이너의 너비를 줄임 */
}

::v-deep .v-select .v-input__append-outer {
    max-width: 24px; /* 외부 컨테이너의 최대 너비를 조정 */
}
</style>