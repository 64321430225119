<template>
    <div
        class="d-flex align-center rounded-10 py-2"
        style="position:relative;"
    >
        <!-- 제목 -->
        <div
            class="ml-4"
        >
            <p
                class="mt-2 mb-1 font-weight-bold"
                style="font-size:26px; cursor:pointer;"
            >
                공식대회
            </p>
            <p
                style="font-size:11px;"
            >
                전 세계 포켓몬카드게임 공식대회의 결과를 한눈에
            </p>
        </div>

        <v-spacer></v-spacer>


        <!-- 목록 -->
        <v-sheet v-if="$route.path == '/official_league/read'"
            class="mt-1 pt-1 mr-2 pl-4 pr-6 rounded-15 d-flex justify-space-between"
        >
            <v-btn
                
                height="56"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                @click="from && from.path == '/official_league/list'? $router.go(-1) : $router.push('/official_league/list')"
            >
                <v-icon>mdi-chevron-left</v-icon>
                뒤로
            </v-btn>
        </v-sheet>

        <!-- 메뉴 -->
        <v-sheet class="mt-1 pt-1 px-7 rounded-15 d-flex justify-space-between">
            <v-btn
                height="56"
                class="px-0 mr-8"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="$route.path.startsWith('/official_league/list')? 'primary':''"
                :style="$route.path.startsWith('/official_league/list')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                :dark="$route.path.startsWith('/official_league/list')"
                @click="$router.push('/official_league/list')"
            >
                대회목록
            </v-btn>
            <v-btn
                height="56"
                class="px-0 mr-8"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="$route.path.startsWith('/deck_rank')? 'primary':''"
                :style="$route.path.startsWith('/deck_rank')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                :dark="$route.path.startsWith('/deck_rank')"
                @click="$router.push('/official_league/deck_rank/list')"
            >
                덱 순위
            </v-btn>
            <v-btn
                height="56"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="$route.path.startsWith('/official_league/player')? 'primary':''"
                :style="$route.path.startsWith('/official_league/player')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                :dark="$route.path.startsWith('/official_league/player')"
                @click="$router.push('/official_league/player/list')"
            >
                플레이어
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: [
        "from"
    ],
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>