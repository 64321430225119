<template>
    <div>
        <v-btn
            class="rounded-10 ml-4 mt-2"
            width="60"
            height="35"
            depressed
            dark
            :color="nation == 'kr'?'primary':'#adafca'"
            @click="nation = 'kr'"
        >
            한글
        </v-btn>
        <v-btn
            class="rounded-10 ml-2 mt-2"
            width="60"
            height="35"
            depressed
            dark
            :color="nation == 'en'?'primary':'#adafca'"
            @click="nation = 'en'"
        >
            EN
        </v-btn>
        <v-btn
            class="rounded-10 ml-2 mt-2 font-weight-bold"
            width="60"
            height="35"
            depressed
            dark
            :color="nation == 'jp'?'primary':'#adafca'"
            @click="nation = 'jp'"
        >
            日語
        </v-btn>

        <!-- 목록 -->
        <div
            v-if="!loading"
            class="py-8 px-12"
        >
            <!-- 검색결과 있을때 -->
            <div
                v-if="list.length"
                class="mt-2"
                style="margin:0 -4px;"
            >
                <v-row
                    v-if="currentPageList.length"
                    no-gutters 
                >
                    <v-col
                        class="mb-6"
                        :class="$vuetify.breakpoint.mobile? 'py-2 px-0':'pa-1'"
                        :cols="$vuetify.breakpoint.mobile? 12:4"
                        v-for="(item, index) in currentPageList" :key="index"
                    >
                        <v-card
                            style="cursor:pointer; box-shadow:0px 2px 10px #eee !important; border:1px solid #eee; max-width:271px;"
                            class="customHover text-center rounded-xl pb-2 mx-auto elevation-0"
                            @click="deckView(item, 'DAY1')"
                        >
                            <v-sheet
                                height="136"
                                style="position:relative;"
                            >
                                <!-- 덱 이미지 -->
                                <v-img
                                    height="100"
                                    class="rounded-t-xl"
                                    position="center 30%"
                                    :src="item.image"
                                ></v-img>

                                <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                                <v-sheet
                                    width="120"
                                    class="rounded-20 mx-auto"
                                    style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                                >
                                    <p
                                        class="mb-0 py-2 icu_purple--text"
                                        style="font-size:13px; font-weight:bold;"
                                    >
                                        {{computed_standing(item)}}
                                    </p>
                                </v-sheet>
                            </v-sheet>

                            <div
                                style="position:relative; margin-top:-9px; z-index:2;"
                            >
                                <!-- 덱 제목 -->
                                <p
                                    style="font-size:13px; font-weight:bold; line-height:18px;"
                                    class="px-8 text-truncate mb-0"
                                >
                                    {{computed_deck_name(item)}}
                                </p>

                                <!-- 대회날짜 -->
                                <p
                                    class="mb-0 pb-1px pt-1px"
                                    style="font-size:9px; color:#B2B2B2; line-height:11px;"
                                >
                                    {{new Date(item.end_date).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                                </p>

                                <!-- 대회명 -->
                                <p
                                    style="font-size:10px; height:20px;"
                                    class="mb-1"
                                >
                                    <font
                                        class="primary--text font-weight-medium"
                                    >
                                        {{ nation == "kr"? item.title_kr : item.title_en }}
                                    </font>
                                </p>

                                <div
                                    class="d-flex align-center justify-center"
                                >
                                    <div>
                                        <v-img
                                            width="18"
                                            height="13"
                                            class="rounded-4"
                                            :src="'/upload/flags/'+item.player_country.toLowerCase()+'.svg'"
                                        ></v-img>
                                    </div>
                                    <!-- 플레이어 -->
                                    <p
                                        style="font-size:13px; font-weight:bold; line-height:18px;"
                                        class="ml-1 text-truncate mb-0"
                                    >
                                        {{nation == 'kr'? computed_player_kr(item) : (item.player_local? item.player_local : computed_player_en(item))}}
                                    </p>
                                </div>
                            </div>

                            <v-sheet
                                height="1"
                                class="mb-2"
                                style="background:#f8f7fb;"
                            ></v-sheet>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <!-- 검색결과 없을떄 -->
            <div
                v-if="list.length == 0"
                class="mt-5 text-center py-10"
            >
                <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                <p style="color:#ccc">검색 결과가 없습니다</p>
            </div>
        </div>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="d-flex mx-auto mt-6 mb-16"
            size="140"
        ></v-progress-circular>

        <!-- 페이지네이션 -->
        <v-pagination
            class="mt-5 pb-4"
            :class="!$vuetify.breakpoint.mobile? 'mb-2':'mb-2 mobile'"
            color="primary"
            v-model="page"
            :length="pageLength"
            :total-visible="10"
        ></v-pagination>

        <!-- 덱 보기 -->
        <v-dialog
            v-model="dialog.deckView"
            width="1068"
            content-class="rounded-15"
        >
            <DeckView
                :player_country="selected_item.player_country"
                :player_kr="selected_item.player_kr"
                :player_en="selected_item.player_en"
                :player_local="selected_item.player_local"
                :player_age_group="selected_item.player_age_group"
                :is_masking="selected_item.is_masking"
                :deck_name_kr="selected_item.deck_name_kr"
                :deck_name_en="selected_item.deck_name_en"
                :sub_deck_name_kr="selected_item.sub_deck_name_kr"
                :sub_deck_name_en="selected_item.sub_deck_name_en"
                :deck_card_list="selected_item.deck_card_list"
                :title_kr="title_kr"
                :title_en="title_en"
                :top_cut="selected_item.top_cut"
                @close="dialog.deckView = false; load_card_shop_standing_list()"
                :key="componentKey"
            />
        </v-dialog>
    </div>
</template>
<script>
import DeckView from './DeckView'

export default {
    components: {
        DeckView,
    },

    props: [
        "title_kr",
    ],

    data: () => ({
        // 목록 & 페이징
        loading: false,
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,

        nation: "kr",

        selected_item: {},
        componentKey: 0,

        dialog: {
            deckView: false
        },

        // title_kr: "",
        title_en: "",
        title_local: "",
        regulation: "",
        league_type: "",
        image: "",
        organizer: "",
        reference_link: "",
        start_date: "",
        end_date: "",
        total: 0,
        total2: 0,
        content: "",
        menu: "결과",
        sub_menu: "플레이어",
        list: [],
        season_nation: "",
        season_name: "",
        age_group: "",
        league_nation: "",
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*9,(this.page)*9)
            window.scrollTo(0, 0)
        },
    },

    mounted(){
        // 덱 레시피 목록
        this.load()

        // 공식대회 정보 불러오기
        this.load_league()
    },

    methods: {
        // 덱 레시피 목록
        load(){
            this.loading = true
            this.$http.post("/api/official_league/select/deck_recipe_list", {
                params: {
                    league_id: this.$route.query.id
                }
            }).then((res) => {
                console.log("deck_recipe_list", res.data)
                this.list = res.data

                this.currentPageList = this.list.slice(0,9)
                this.pageLength = Math.ceil(this.list.length/9)
                this.list_ready = true
                this.page = 1
                this.loading = false

                // 이미지 로드
                this.loadImages()
            })
        },

        // 이미지 로드
        async loadImages() {
            for (let item of this.list) {
                item.image = await this.computed_image(item)
            }
        },

        // 덱 이미지 표시
        async computed_image(item) {
            // console.log(item)
            let card_list = item.ptcgo_list.split('\n').filter(e => e != "").filter(e => /^\d/.test(e))

            // console.log(card_list)

            try {
                let res = await this.$http.post("/api/official_league/select/ptcgo/first_card/image", {
                    params: {
                        name_en: this.convertName(this.convertList(card_list[0])[1])
                    }
                })

                // console.log(res.data)

                if (res.data.length) {
                    const firstNonDefaultImage = res.data.find(image => image.image_en !== "default.png")
                    return firstNonDefaultImage ? "/upload/card_en_image/" + firstNonDefaultImage.image_en : "/upload/card_en_image/default.png"
                } else {
                    return "/upload/card_en_image/default.png"
                }
            } catch (error) {
                console.error(error)
                return "/upload/card_en_image/default.png"
            }
        },

        // 공식대회 정보 불러오기
        async load_league(){
            await this.$http.post("/api/official_league/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then(async(res) => {
                if(res.data.length){
                    // console.log(res)
                    // this.title_kr = res.data[0].title_kr
                    this.title_en = res.data[0].title_en
                    this.title_local = res.data[0].title_local
                    this.regulation = res.data[0].regulation
                    this.league_type = res.data[0].league_type
                    this.image = res.data[0].image
                    this.organizer = res.data[0].organizer
                    this.reference_link = res.data[0].reference_link
                    this.start_date = res.data[0].start_date
                    this.end_date = res.data[0].end_date
                    this.age_group = res.data[0].age_group
                    // this.options.content = res.data[0].content
                    this.total = res.data[0].total
                    this.total2 = res.data[0].total2
                    this.league_nation = res.data[0].league_nation

                    this.season_nation = res.data[0].season_nation
                    this.season_name = res.data[0].season_name
                }
            })
        },

        // 덱 이미지 표시
        // computed_image(item){
        //     console.log(item)
        //     let card_list = item.ptcgo_list.split('\n').filter(e => e != "").filter(e => /^\d/.test(e))

        //     return this.$http.post("/api/official_league/select/ptcgo/first_card/image", {
        //         params: {
        //             name_en: this.convertName(this.convertList(card_list[0])[1])
        //         }
        //     }).then((res) => {
        //         if(res.data.length){
        //             return "/upload/card_en_image/" + res.data[0].image_en
        //         }else{
        //             return "/upload/card_en_image/default.png"
        //         }
        //     })
        // },

        // async computed_image(item) {
        //     console.log(item)
        //     let card_list = item.ptcgo_list.split('\n').filter(e => e != "").filter(e => /^\d/.test(e))

        //     try {
        //         let res = await this.$http.post("/api/official_league/select/ptcgo/first_card/image", {
        //             params: {
        //                 name_en: this.convertName(this.convertList(card_list[0])[1])
        //             }
        //         })

        //         if (res.data.length) {
        //             return "/upload/card_en_image/" + res.data[0].image_en
        //         } else {
        //             return "/upload/card_en_image/default.png"
        //         }
        //     } catch (error) {
        //         console.error(error)
        //         return "/upload/card_en_image/default.png"
        //     }
        // },

        // 에너지 중괄호 변경하기
        convertName(text){
            // 없는 값 들어오면 replace 안하고 return
            if(!text){
                return text
            }

            // 에너지 > 중괄호 변환
            text = text.replace("{G}", "Grass")
            text = text.replace("{R}", "Fire")
            text = text.replace("{W}", "Water")
            text = text.replace("{L}", "Lightning")
            text = text.replace("{P}", "Psychic")
            text = text.replace("{F}", "Fighting")
            text = text.replace("{D}", "Darkness")
            text = text.replace("{M}", "Metal")

            // 에너지 > Basic 값 제거
            text = text.replace("Basic", "")

            // 괄호로 묶인 내용을 제거 (괄호 포함)
            text = text.replace(/\(.*?\)/g, '')

            // 앞뒤 여백 제거
            text = text.trim()

            return text
        },

        convertList(str) {
            // 먼저 공백으로 문자열을 분할합니다.
            let parts = str.split(' ')

            // 뒤에 PH 붙는거 경우
            if(parts[parts.length - 1] == "PH")
            {
                parts.pop()
            }

            // 첫 번째 값
            let firstValue = parts[0]

            // 마지막 두 값
            let thirdValue = parts[parts.length - 2]
            let fourthValue = parts[parts.length - 1]

            // 중간에 남은 부분이 두 번째 값입니다.
            let secondValue = parts.slice(1, parts.length - 2).join(' ')

            // 에너지카드이고 제품명이 없어서
            // 제품명으로 Energy가 이동한 경우 처리 (Energy 다시 붙여줌)
            if(!secondValue.includes("Energy") && thirdValue == "Energy")
            {
                secondValue = secondValue + " Energy"
                thirdValue = "SVE"
            }
            else if(thirdValue == "Energy")
            {
                thirdValue = "SVE"
            }

            return [firstValue, secondValue, thirdValue, fourthValue]
        },

        // 언어별 덱 이름 표시
        computed_deck_name(item){
            if(this.nation == "kr"){
                if(item.deck_kr){
                    return item.deck_kr
                }
                else{
                    return item.deck_en
                }
            }
            else if(this.nation == "jp"){
                if(item.deck_jp){
                    return item.deck_jp
                }
                else{
                    return item.deck_en
                }
            }
            else if(this.nation == "en"){
                return item.deck_en
            }
        },

        // 언어별 순위 표시
        computed_standing(item){
            if(item.standing < 800){
                if(this.nation == "kr"){
                    return item.standing + "위"
                }
                else if(this.nation == "en"){
                    if(item.standing == 1) return "1st"
                    else if(item.standing == 2) return "2nd"
                    else if(item.standing == 3) return "3rd"
                    else if(item.standing > 3) return item.standing + "th"
                }
                else if(this.nation == "jp"){
                    return item.standing + "位"
                }
            }
            else{
                if(this.nation == "kr"){
                    return "예선"
                }
                else if(this.nation == "en"){
                    return "Quals"
                }
                else if(this.nation == "jp"){
                    return "予選"
                }
            }
        },

        // 덱 보기
        deckView(item, day){
            console.log(item)
            if(day == "DAY1" && !item.deck_identifier){
                alert("입력되지 않은 덱 레시피입니다")
                return
            }
            // else if(day == "DAY2" && !item.deck_identifier2){
            //     alert("입력되지 않은 덱 레시피입니다")
            //     return
            // }

            this.selected_item = item

            if(day == 'DAY1'){
                this.selected_item.deck_name_en = this.selected_item.deck_name_en1
                this.selected_item.deck_name_kr = this.selected_item.deck_name_kr1
                this.selected_item.sub_deck_name_en = this.selected_item.sub_deck_name_en1
                this.selected_item.sub_deck_name_kr = this.selected_item.sub_deck_name_kr1
                this.selected_item.deck_card_list = this.selected_item.deck_card_list1
            }
            else if(day == 'DAY2'){
                this.selected_item.deck_name_en = this.selected_item.deck_name_en2
                this.selected_item.deck_name_kr = this.selected_item.deck_name_kr2
                this.selected_item.sub_deck_name_en = this.selected_item.sub_deck_name_en2
                this.selected_item.sub_deck_name_kr = this.selected_item.sub_deck_name_kr2
                this.selected_item.deck_card_list = this.selected_item.deck_card_list2
            }

            this.dialog.deckView = true
            this.componentKey++
        },

        computed_player_kr(item) {
            if(item.player_local){
                return item.player_local
            }
            else{
                if(item.player_country != "KR"){
                    return item.player_kr
                }else{
                    if(item.is_masking != null){
                        if(item.is_masking){
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }else{
                            return item.player_kr
                        }
                    }
                    else{
                        if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                            return item.player_kr
                        }else{
                            return item.player_kr[0] + '✱' + item.player_kr.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(item.player_country != "KR"){
                return item.player_en
            }else{
                if(item.is_masking != null){
                    if(item.is_masking){
                        return item.player_en.split(' ')[0] + ' *'
                    }else{
                        return item.player_en
                    }
                }
                else{
                    if(item.player_age_group != '시니어' && item.player_age_group != '주니어'){
                        return item.player_en
                    }else{
                        return item.player_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}


/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}
</style>