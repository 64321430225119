<template>
    <div>
        <v-btn
            class="rounded-10 ml-4 mt-2"
            width="100"
            height="35"
            depressed
            dark
            :color="menu == '덱 레시피'?'primary':'#adafca'"
            @click="menu = '덱 레시피'"
        >
            덱 레시피
        </v-btn>
        <v-btn
            class="rounded-10 ml-2 mt-2"
            width="100"
            height="35"
            depressed
            dark
            :color="menu == '카드'?'primary':'#adafca'"
            @click="menu = '카드'"
        >
            카드
        </v-btn>
        <div
            v-if="menu == '덱 레시피'"
        >
            <Decks
                :image="image"
            />
        </div>
        <div
            v-if="menu == '카드'"
        >
            <Cards />
        </div>
    </div>
</template>
<script>
import Decks from './Statistics/Decks'
import Cards from './Statistics/Cards'

export default {
    components: {
        Decks,
        Cards 
    },

    props: [
        "image"
    ],

    data: () => ({
        menu: "덱 레시피",
    })
}
</script>