<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            width="480"
            class="rounded-15 px-10 pt-6 pb-4"
            color="#F8F7FB"
            style="border:2px solid #1DA1F2"
        >
            <!-- 제목 -->
            <p class="mb-2 font-weight-bold" style="font-size:20px;">
                정보/오류제보
            </p>

            <!-- 공식대회 제목 -->
            <p class="mb-2" style="font-size:15px; font-weight:500; color:#23D2E2;">
                {{title_kr}}
            </p>

            <!-- 내용 -->
            <v-sheet class="py-4 px-6 mt-3 rounded-10">
                <v-icon size="8" color="#ADAFCA" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">내용</span>
                <v-textarea
                    class="no_under"
                    rows="4"
                    hide-details
                    v-model="content"
                    placeholder="내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 버튼 -->
            <div class="mt-2 pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="110"
                    height="40"
                    dark
                    depressed
                    color="#1DA1F2"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    제출
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="110"
                    height="40"
                    depressed
                    dark
                    color="#B3B3C2"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>

        <!-- Mobile-->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="rounded-15 px-4 pt-6 pb-4"
            color="#F8F7FB"
            style="border:2px solid #1DA1F2"
        >
            <!-- 제목 -->
            <p class="mb-1 font-weight-bold" style="font-size:16px;">
                정보/오류제보
            </p>

            <!-- 공식대회 제목 -->
            <p class="mb-1" style="font-size:12px; font-weight:500; color:#23D2E2;">
                {{title_kr}}
            </p>

            <!-- 내용 -->
            <v-sheet class="py-3 px-3 mt-2 rounded-10">
                <v-icon size="8" color="#ADAFCA" class="pb-2px mr-2">mdi-circle</v-icon>
                <span style="font-size:12px; font-weight:bold;">내용</span>
                <v-textarea
                    class="no_under"
                    style="width:300px;"
                    rows="4"
                    hide-details
                    v-model="content"
                    placeholder="내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 버튼 -->
            <div class="mt-2 pa-2 pb-1 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="90"
                    height="36"
                    dark
                    depressed
                    color="#1DA1F2"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    제출
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="90"
                    height="36"
                    depressed
                    dark
                    color="#B3B3C2"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: [
        "title_kr"
    ],

    data: () => ({
        content: "",
    }),

    methods: {
        // 제출
        submit(){
            if(this.content == ""){
                alert("제보 내용을 입력해주세요")
                return
            }

            this.$http.post("/api/admin/provide/official_league/insert", {
                params: {
                    user_id: this.$store.state.user_id,
                    league_id: this.$route.query.id,
                    content: this.content
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("정보/오류가 제보되었습니다.")
                    this.content = ""
                    this.$emit("close")
                }
            })
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>